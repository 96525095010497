<template>
  <section class="bt card-style" id="entity-users-list" v-loading="loading" :element-loading-text="loadingText">

    <entity-gallery-view v-if="originalCurrentEntity" :search_string="search_string"
      :showFieldsParent="showFieldsParent" :isApplicationUserSide="isApplicationUserSide" :currentMenu="currentMenu"
      :getSelfEntityFilters="getSelfEntityFilters" :applyFiltersData="entityFiltersData"
      :keyLabelData="prepareKeyLabelsObject()" :data="data" :entityAllFilters="entityAllFilters"
      :currentEntity="originalCurrentEntity" :paginationData="getPaginationData()" :customization="customization"
      v-on:refreshPageData="refreshEntityData" :paginationObject="{ total: total }">
    </entity-gallery-view>
    <add-entity-reationship-data-widget v-if="showAddRelationshipDataDialog"
      :relationshipDetails="selectedRelationshipDetails" :existedData="selectedRelationshipExistedIds" :autoPopup="true"
      v-on:entityDataClosed="showAddRelationshipDataDialog = false"></add-entity-reationship-data-widget>

    <ActionButtonExecute v-if="quickActionModal && quickActionDummyField" :data="quickActionDummyField" :isView="false"
      :entityId="currentEntity._id" :entityDataId="quickActionDummyField.parent_entityDataId" :fromQuickAction="true"
      :entityData="rowEntityData" :selfTemplateId="quickActionDummyField.template_id" :allEntitiesData="allEntitiesData"
      :allEntityFields="allEntityFields" :form="quickActionDummyForm" @closeModal="quickActionModal = false">
    </ActionButtonExecute>

  </section>
</template>

<script>
import "leaflet/dist/leaflet.css";
import axios from "axios";

import { mapGetters } from "vuex";
import config from "@/config/app";
import nodeAxios from "@/config/axios";
import { ckEditorConfig } from "@/assets/data/ckeditor_config.ts";
import NavigationHelper from "@/mixins/navigationHelper";
import entityRelationshipMixin from "@/mixins/entityRelationshipMixin";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import TemplateBuilderhelper from "@/mixins/templateBuilderHelper.js";
import LayoutDataMixin from "@/mixins/DataFetchingHelper.js";
import EntityHelper from "@/mixins/EntitiesHelper";
import moment from "moment";
import * as XLSX from "xlsx";
import AutomationHelper from "@/mixins/AutomationHelper";
// import QRCode from "qrcode";
import { isEqual } from "@/helpers/lodashHelper";
import { postAPICall } from "@/helpers/httpHelper";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import JSZip from "jszip";
import { fetchEntitiesByPagination } from "@/repo/entityRepo";
import { getAllCompanyTemplatesByPagination } from "@/repo/templatesRepo";
import { fetchGlobalVariableById } from "@/repo/globalVariables";
import Swal from "sweetalert2";

import {
  fetchAllEntityFiltersByEntity,
  addSingleFilterToLocal,
  updateFilterData,
  deleteFilter,
} from "@/repo/filtersRepo";
import {
  fetchAllEntityCustomizationsById,
  fetchActiveEntityCustomizationsByEntity,
} from "@/repo/entityCustomizationRepo";
import { fetchEntityById } from "@/repo/entityRepo";
//Vue.use(VueLodash, {
//  name: "custom",
//  lodash: lodash,
//});
import FieldStylesHelper from "../../mixins/FieldStylesHelper";

import { fetchFilterById } from "@/repo/filtersRepo";
import fieldsFormatHelper from "../../mixins/FieldsFormatHelper";
import { makeSlug } from "@/helpers/appHelper";
import { fetchEntityRelationships } from "../../repo/entityRelationshipsRepo";
import { fetchEntityNotificationTemplates } from "../../repo/notificationTemplatesRepo";
import { fetchGlobalVariables } from "@/repo/globalVariables"
export default {
  name: "EntityGalleryDataList",
  components: {

    AddEntityReationshipDataWidget: () =>
      import("./../widgets/AddEntityReationshipDataWidget.vue"),
    ActionButtonExecute: () =>
      import(
        "@/components/templates/formComponentsExecute/ActionButtonExecute"
      ),
  },
  mixins: [
    NavigationHelper,
    LayoutDataMixin,
    entityRelationshipMixin,
    CustomDashboardConfig,
    TemplateBuilderhelper,
    EntityHelper,
    AutomationHelper,
    FieldStylesHelper,
    fieldsFormatHelper,
  ],
  data() {
    return {
      allEntitiesData: [],
      isEmailUpdate: false,
      showGallery: false,
      currentImages: [],
      number_fields: [],
      currentField: null,
      originalCurrentEntity: null,
      bulkUploadDocsVisible: false,
      bulkUploadDocsForm: {
        selectedField: "",
        separator: "-",
        hasSeparator: true,
      },
      zipFilesData: {},
      showContentView: true,
      user_prompt: "",
      allCurrentEntityFields: [],
      allFileFields: [],
      currentEntityFields: [],
      currentEntityFieldsObject: {},
      fieldsLabelObject: {},
      selectedLabels: "",
      selectedFiles: "",
      fieldsObject: {},
      files: [],
      companyAllTemplateFields: [],
      hasMore: false,
      childLoading: false,
      zipFileUrl: null,
      ImportZip: false,
      zipFiles: [],
      creationType: "Upload Zip",
      LoadingDialogVisible: false,
      ExtractDialogVisible: false,
      ImportProcessInitiatedVisible: false,
      isExecuteCopy: false,
      savingData: {},
      isDialogVisible: false,
      allFieldKeyLabels: null,
      selectedRoww: null,
      currentData: null,
      allEntityFields: [],
      currentTemplateFields: [],
      filteredDocuments: [],
      markerIcon: "",
      savedCoordinates: "",
      allEmailTemplates: [],
      isShowOnMap: false,
      center: [17.4065, 78.4772],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      coordinates: [17.4065, 78.4772],
      zoom: 10,
      isRichText: null,
      editorConfig: ckEditorConfig,
      multiLineTextData: "",
      multiLineTextLabel: "",
      openMultiLineTextDialog: false,
      colorFields: {},
      pdfExport: false,
      treeCheckedNodes: null,
      count: 0,
      quickUpdateIndex: -1,
      quickUpdateRowData: null,
      currentkey: "",
      refreshPage: false,
      limit: 1,
      downloadDocuments: false,
      documentsData: [],
      activeNameForExcelImport: "success",
      currentActiveRowIndex: -1,
      excelFileData: [],
      successedExcelImportData: [],
      errorExcelImportData: [],
      currentEntityAllFields: [],
      foundArray: [],
      pageSizeForIEDS: 5,
      currentPageForIEDS: 1,
      totalForIEDS: 0,
      pageSizeForIE: 5,
      currentPageForIE: 1,
      totalForIE: 0,
      currentActiveSuccessRowIndex: -1,
      currentActiveErrorRowIndex: -1,
      entitySignleRecordVisible: false,
      entitybulkDeleteVisible: false,
      totalFields: [],
      isExistedName: false,
      mappingLoadingDialogVisible: false,
      mappingStatusDialogVisible: false,
      unmapped_dialog: false,
      is_excelUploaded: false,
      mappedFileName: "",
      selectedFile: "",
      mapped_fields: [],
      unmapped_fields: [],
      current_entity_tempates: [],
      activeName: "first",
      ExportscreenLoading: false,
      matchDialogVisible: false,
      is_active: 0,
      uploadedfileDataNames: [],
      existedField: "",
      uploadedFileObject: {},
      selectedRowsIds: [],
      selectedIds: [],
      filterText: "",
      loading: false,
      loadingText: "Loading...",
      total: 0,
      currentPage: 1,
      entityDataId: null,
      pageSize: 0,
      data: [],
      documentsLoading: false,
      firstTemplateId: null,
      primaryField: "",
      primaryKey: "",
      dialogTableVisible: false,
      entity_id: "",
      props: {
        children: "children",
        label: "label",
        disabled: "disabled",
      },
      fieldsListData: [],
      defaultKeys: [],
      selectedTableFields: [],
      filterColumList: [],
      checkedFields: [],
      selectedFieldKeys: [],
      modalLoading: false,
      currentEntity: {},
      entityFiltersData: {
        query_type: "AND",
        filters: [],
        filter_name: "",
        entity_id: "",
        isSaveFilter: false,
        columns_settings: [],
        quick_filters: [],
        displayType: "",
        data_limit: 10,
        email_template_id: "",
        sortBy: "",
        sortOrder: "",
      },
      entityUserFiltersData: {
        parentFilterId: "",
        query_type: "AND",
        filters: [],
        filter_name: "",
        isSaveFilter: false,
        data_limit: 10,
        columns_settings: [],
      },
      selectedEntityFields: [],
      entityAllFilters: [],
      currentFilter: "",
      UsercurrentFilter: "",
      dialogEdit: false,
      entityFieldSettings: [],
      refresh: false,
      relationshipsActions: [],
      selectedRelationshipDetails: [],
      selectedRelationshipExistedIds: [],
      showAddRelationshipDataDialog: false,
      showChildEntityDataDialog: false,
      selectedChildEntityDataId: "",
      selectedChildEntityId: "",
      selectedChildEntityRefName: "",
      selectedChildEntityPrimaryDataValue: "",
      selectedChildEntityDataIsMultple: false,
      selectedFileds: {},
      relationshipsData: [],
      globalVariablesData: null,
      order_type: null,
      order_by: null,
      totalData: [],
      search_string: "",
      dialogLoading: false,
      sortBy: "_id",
      sortOrder: -1,
      applied_quick_filters: [],
      applied_filters: [],
      activeEntityView: "TABLE",
      entityViewModal: false,
      importedData: [],
      mappedData: [],
      tableLoading: false,
      showDetailedCount: false,
      selectedComponent: null,
      appliedFilters: [],
      countDetailsEntityId: "",
      showDetailsFieldLabel: "",
      countDetailsParentEntityDataId: "",
      countDetailsParentEntityId: "",
      display_views: [{
        label: "Table",
        key: "TABLE",
      },
      {
        label: "Board",
        key: "BOARD",
      },
      // {
      //   label: "Calendar",
      //   key: "CALENDAR",
      // },
      // {
      //   label: "Timeline",
      //   key: "TIMELINE",
      // },
      {
        label: "Charts",
        key: "CHARTS",
      },
      {
        label: "Cards",
        key: "CARDS",
      },
      {
        label: "Content",
        key: "HTML_CONTENT",
      },
      ],
      countComponent: null,
      countComponentFilters: [],
      custom_columns: [],
      groupedData: [],
      showCountDialog: false,
      sub_group: "",
      search_field: "",
      activeTemplate: "1",
      showFieldsParent: {
        profile: "",
        title: "",
        description: "",
        multiselectDisplay: [],
        fields: [],
        chart_field: "",
        chart_type: "PIE",
        board_field: "",
        action_button: "",
        groupBy_chart_field: "",
        action_group_button: [],
        selectedFilter: [],
        selected_email_template: "",
        columnsPerRow: 1,
        emailView: false,
        BoardViewFields: [],
        take_value_from: "",
        Board_action_group_button: [],
        enableOtherOptions: true,
        field1: "",
        field2: "",
        field3: "",
      },
      contentFields: [
        "IMAGE",
        "HEADING",
        "PARAGRAPH",
        "VIDEO",
        "SINGLE_LINE_CONTENT",
        "HORIZONTAL_LINE",
        "DIVISION",
        "HTML_CONTENT",
        "ACTION_BUTTON",
        "HTML_CONTENT",
      ],
      chartTypes: [{
        label: "Pie",
        value: "PIE",
      },
      {
        label: "Bar",
        value: "BAR",
      },
      {
        label: "Doughnut",
        value: "DOUGHNUT",
      },
      ],
      isApplicationUserSide: false,
      activeWorkspace: "",
      showErrorMessage: "",
      //Entity notification variables
      openEntityNotification: false,
      entityNotificationLoading: false,
      selectedNotifyEntity: null,
      entityNotificationBody: {
        subject: "",
        body: "",
        filters: [],
      },
      selectedEntityRelationships: [],
      jsonDataOfExcel: [],
      dateFormat: "dd-MM-yyyy",
      entityTempsWithException: [],
      excelFileHeaders: [],
      filterColumns: [],
      getTotalFields: [],
      preparedData: [],
      exportFilters: [],
      exportDataIds: [],
      bulkUpdateDialgVisible: false,
      bulkUpdateFields: [],
      selectAndEntityFields: [],
      optionsLoading: false,
      documentSearchData: [],
      searchDocument: "",
      dataTableSelected: false,
      allOtherEntityFields: [],
      filterVariableColumns: [],
      pageSizes: [10, 20, 50],
      // isFilterOpen: true,
      // advancedSearch: false,
      current_entity_id: "",
      forbiddenAccess: false,
      isShowError: false,
      sendNotificationDialog: false,
      sendWANotificationDialog: false,
      bulkSendNotificationDialog: false,
      bulkSendNotificationWADialog: false,
      paramsData: {},
      emailConfigLoading: false,
      nestedRelationalEntityDatas: [],
      settingsOrder: [],
      menuTitleLoading: false,
      quickActionDummyField: null,
      quickActionModal: false,
      quickActionDummyForm: {},
      relationQuickFilters: [],
      quickFilterVisible: false,
      customization_id: "",
      customization: null,
      entityUserInfoData: {},
      action_button: [],
      viewEditOptions: [
        { label: "View", value: "View" },
        { label: "Edit", value: "Edit" },
      ],
      selectedActions: [],
      copyModal: false,
      url_key: "",
      templateData: [],
      checkRoutekey: "",
      type: "",
      defaultSettingFields: [],
      hasAttachment: false,
      openAttachmentsModal: false,
      selectedAttachmentData: null,
      rowEntityData: {},
      hasQrcode: false,
      qrCodeField: null,
      showQrReader: false,
      bulkUpdateData: [{
        field: "",
        value: "",
      },],
      entityDataMapping: {},
      entityMapping: {},
      bulkUpdateFieldValuesMapping: {},
      qrLoading: false,
      qrLoadingText: "Checking data...",
      dataAdded: false,
      addedDataIds: [],
      getSingleFilterData: null,
      openExportCSVDialogModal: false,
      selectedTableExcelType: "ADD_INNER_TABLE",
    };
  },
  computed: {
    ...mapGetters("entities", [
      // "currentEntity",
      "getPageSize",
      "getEntityDataByEntityId",
      "getEntityPrimaryData",
      "getEntityDataUpdateStatus",
      "getEntityDocumentsDataStatus",
      "getEntityDocumentsData",
      "getEntityRecordsForTable",
      "getUploadCSVfileStatus",
      "getupdateMappedFieldStatus",
      "getmappedFieldsData",
      "getuploadedFilesData",
      "getstoreUploadedFileStatus",
      "getimportMappedDataStatusUpdatedVersion",
      "getentitydataBulkDeleteStatus",
      "getEntitydataBulkDeleteErrors",
      "getEntityShowFields",
      "getSentEntityDataNotificationStatus",
      "getEntityById",
      "getBulkUpdateStatus",
      "getSentEntityNotificationStatus",
      "getSentEntityNotificationStatusErrors",
      "getEntityDataByIdError",
      "getEntityVariableFieldsInputTypes",
      "getEntityDataExportPDFStatus",
      "getEntityDataByQuery",
      "getQuickEntityFiltersObject",
      "getAllEntitiesInTemplate",
    ]),
    ...mapGetters("companyTemplates", ["getTemplateDataTempVariable"]),
    // ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    ...mapGetters("templatesData", [
      "getTemplateDeleteStatus",
      "getTemplateDeleteError",
      "getDownloadUrl",
      "getDownloadError",
      "getDuplicateEntityData",
      "getDuplicateDataCheck",
    ]),
    ...mapGetters("filters", [
      "getAllEntityFilters",
      "getCreateNewFilter",
      "getUpdateFilterStatus",
      "getUpdateFilterData",
      "getDeleteFilterStatus",
      "getCreateNewFilterError",
      "getUpdateFilterError",
      "getDeleteFilterErros",
      // "getSingleFilterData",
    ]),
    ...mapGetters("entityRelationships", [
      "getEntityRelationships",
      "getCurrentEntityRelationships",
      "getParentEntityRelatiponshipData",
      "getParentEntityNestedRelationshipData",
      "getEntityRelationshipDataAssignStatus",
    ]),
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getDefaultDateFormat",
      "getActiveContactType",
      "getUserTypeEmailsErrors",
      "getUserTypeEmails",
      "getUserTypeList",
      "getActiveWorkspace",
      "getGlobalFiltervalue",
      "getUserType",
    ]),
    ...mapGetters("menuManagementV2", [
      "getMenu",
      "getMenuManagementAddStatus",
      "getMenuManagementUpdateStatus",
      "getEntityMenu",
    ]),
    ...mapGetters("applicationUsersPermission", [
      "getCreatePermissionStatus",
      "getEntityPermission",
      "getUpdatePermissionStatus",
    ]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("contacts", ["getAllContacts"]),
    ...mapGetters("entityEmailTemplate", [
      "getEntityEmailTemplatesData",
      "getEmailTemplateError",
    ]),
    ...mapGetters("s3FileUpload", [
      "getFileUploadData",
      "getCompanyLogoUpdateData",
      "getFileUploadStatus",
    ]),
    ...mapGetters("sso", ["getAuthenticatedSSOToken", "getIsUserLoggedIn"]),
    ...mapGetters("iFrame", ["getIframeUrlInfo"]),
    ...mapGetters("entityCustomization", [
      "getCustomizationById",
      "getEntityActiveCustomization",
    ]),
    ...mapGetters("company", ["getCompanyDetails", "getContactLoginSlug"]),
    ...mapGetters("entitiesData", ["getCreateDocumentForEntityData"]),
    getMaxValue() {
      return (template_id, key, entity) => {
        let field = entity.templates?.find((e) => e?.templateInfo._id == template_id)
          .templateInfo.sections[0]?.fields?.find(
            (e) => e.key == key || e.key?.split("#")[1] == key
          );
        return field?.max_selection || undefined;
      };
    },

    getDisabledOptions() {
      return (template_id, key, entity) => {
        const selectedOptions = this.quickUpdateRowData[template_id]?.[key] || [];
        const maxSelections = this.getMaxValue(template_id, key, entity);

        if (maxSelections !== undefined && selectedOptions.length >= maxSelections) {
          return this.getCurrentFieldOptions(template_id, key, entity).filter(
            (option) => !selectedOptions.includes(option)
          );
        }
        return [];
      };
    },
    getNumberFieldsData() {
      const simplifiedArray = this.number_fields.map((item) => ({
        key: item.key,
        label: item.label,
      }));
      return simplifiedArray;
    },
    showQuickFilters() {
      return (
        this.isFilterOpen &&
        ((this.entityFiltersData &&
          this.entityFiltersData.quick_filters &&
          this.entityFiltersData.quick_filters.length) ||
          this.relationQuickFilters.length)
      );
    },
    isApprovalFilterSelected() {
      return this.data.find((e) => e.isCreatedFromFormbuilder) ? true : false;
    },
    primaryActions() {
      if (this.customization) {
        return this.customization.actions.filter(
          (action) => action.QAPrimary && action.active
        );
      }
      return [];
    },
    isFilterDisabled() {
      if (this.$route.query.routeType) {
        return this.checkFilterDisabled(this.entityUserFiltersData);
      }
      return this.checkFilterDisabled(this.entityFiltersData);
    },
    nonPrimaryActions() {
      if (this.customization) {
        return this.customization.actions.filter(
          (action) => !action.QAPrimary && action.active
        );
      }
      return [];
    },
    paginationProps() {
      return [5, 10, 20, 50, 100];
    },
    paginationPropsForTable() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
    getFilterColumList() {
      if (this.treeCheckedNodes && this.treeCheckedNodes.length) {
        let data = this.treeCheckedNodes
          .filter((e) => e.id && e.template_id)
          .map((e) => {
            if (e?.id && e.id.includes("#")) {
              e.id = e.id.split("#")[1];
            }
            return e;
          });
        return data;
      }
      return [];
    },
    getEntityRoute() {
      return "/entity-execute/" + this.$route.params.entity_id;
    },
    logoUploadUrl() {
      return config.S3_BUCKET_URL;
    },
    // getCurrentFilters() {
    //   if (this.$route.query.routeType) {
    //     if (this.entityFiltersData?.filters?.length) {
    //       return this.entityFiltersData.filters.filter(
    //         (filter) => filter.isUserFilter === true
    //       );
    //     } else {
    //       return [];
    //     }
    //   }
    //   return this.entityFiltersData &&
    //     this.entityFiltersData.filters &&
    //     this.entityFiltersData.filters.length ?
    //     this.entityFiltersData.filters :
    //     [];
    // },
    getFilterButtonLabel() {
      let name = "Apply";
      const filterData = this.$route.query.routeType ?
        this.entityUserFiltersData :
        this.entityFiltersData;
      if (filterData) {
        if (filterData.isSaveFilter) {
          name = "Save & Apply";
        } else if (filterData.updateFilter) {
          name = "Update & Apply";
        }
      }

      return name;
    },
    // getUserFilter() {
    //   if (this.$route.query.routeType) {
    //     return (this.entityAllFilters || []).filter(
    //       (e) =>
    //         e.role_id === this.getAuthenticatedUser.activeRole.role_id &&
    //         e.created_by === this.getAuthenticatedUser._id &&
    //         e.parent_filter_id === this.$route.query.filter
    //     );
    //   } else {
    //     return (this.entityAllFilters || []).filter((e) => !e.parent_filter_id);
    //   }
    // },
    filteredData() {
      // if (this.search_string) {
      //   return this.data.filter((data) => {
      //     if (data?.entityData) {
      //       let found = false;
      //       Object.keys(data.entityData).forEach((temp) => {
      //         Object.values(data.entityData[temp]).forEach((value) => {
      //           if (
      //             typeof value == "string" &&
      //             value.toLowerCase().includes(this.search_string.toLowerCase())
      //           ) {
      //             found = true;
      //           } else if (
      //             typeof value == "number" &&
      //             value == this.search_string
      //           ) {
      //             found = true;
      //           } else if (
      //             typeof value == "object" &&
      //             value != null &&
      //             value != undefined
      //           ) {
      //             if (value.length) {
      //               let valueFound = value.find((item) => {
      //                 if (typeof item == "number") {
      //                   return item == this.search_string;
      //                 } else if (typeof item == "string") {
      //                   return item
      //                     .toLowerCase()
      //                     .includes(this.search_string.toLowerCase());
      //                 }
      //               });
      //               if (valueFound) {
      //                 found = true;
      //               }
      //             } else {
      //               if (
      //                 value["name"] &&
      //                 typeof value.name == "string" &&
      //                 value.name
      //                   .toLowerCase()
      //                   .includes(this.search_string.toLowerCase())
      //               ) {
      //                 found = true;
      //               }
      //             }
      //           }
      //         });
      //       });
      //       return found;
      //     }
      //   });
      // }
      return this.data;
    },
    successedExcelImportDataPagination() {
      const from = (this.currentPageForIEDS - 1) * this.pageSizeForIEDS;
      let to = from + this.pageSizeForIEDS;
      // if (to > this.totalForIEDS) {
      //   to = this.totalForIEDS;
      // }
      return this.successedExcelImportData.slice(from, to);
    },
    errorExcelImportDataPagination() {
      const from = (this.currentPageForIE - 1) * this.pageSizeForIE;
      let to = from + this.pageSizeForIE;
      // if (to > this.totalForIEDS) {
      //   to = this.totalForIEDS;
      // }
      return this.errorExcelImportData.slice(from, to);
    },
    getSearchData() {
      let result = [];
      if (this.search_field) {
        this.currentEntity.templates.forEach((td) => {
          let template = JSON.parse(JSON.stringify(td));
          if (template?.templateInfo?.sections && !template.is_repeatable) {
            let filterFields =
              template?.templateInfo?.sections[0]?.fields.filter(
                (field) =>
                  field?.label &&
                  field.label
                    .toLowerCase()
                    .includes(this.search_field.toLocaleLowerCase())
              );
            if (filterFields && filterFields.length > 0) {
              template.templateInfo.sections[0].fields = [];
              template.templateInfo.sections[0].fields = [...filterFields];
              result.push(template);
            }
          }
        });
      }
      return result;
    },
    currentMenu() {
      if (
        this.isApplicationUserSide &&
        this.$route?.params?.menuId &&
        this.getEntityMenu?.menu_list
      ) {
        let menu;
        let id = this.$route.params.menuId || this.$route.query.menu_id;
        this.getEntityMenu.menu_list.forEach((e) => {
          if (e._id == id) {
            menu = e;
          } else if (e.children) {
            (e.children || []).forEach((el) => {
              if (el._id == id) {
                menu = el;
              } else if (el.children) {
                (el.children || []).forEach((ele) => {
                  if (ele._id == id) {
                    menu = ele;
                  }
                });
              }
            });
          }
        });
        return menu;
      }
      return null;
    },
    getSelfEntityFilters() {
      if (
        this.isApplicationUserSide &&
        this.getAuthenticatedUser?.contact_types
      ) {
        let currentActiveWorkspace =
          this.getAuthenticatedUser.contact_types.find((e) => {
            let id =
              e.contact_type && e.contact_type._id ?
                e.contact_type._id :
                e.contact_type;
            if (id && id == this.activeWorkspace) {
              return true;
            }
          });
        let value =
          currentActiveWorkspace && currentActiveWorkspace.account_data_id ?
            currentActiveWorkspace.account_data_id :
            "",
          relationalEntityId = this.activeWorkspace;
        if (
          this.currentMenu?.self_related_filter != "login_user" &&
          this.getEntityPrimaryData
        ) {
          let [entityId, templateId, fieldKey] =
            this.currentMenu.self_related_filter?.split("#");
          let tempData = this.getEntityPrimaryData.templates_data.find(
            (tempData) => tempData.template_id == templateId
          );
          if (tempData?.template_data_id?.template_data?.[fieldKey]) {
            value = tempData.template_data_id.template_data[fieldKey];
          } else {
            value = "";
          }
          relationalEntityId = entityId;
        }
        return [{
          field: "self#related_to/1",
          operator: "=",
          value: value,
          value_type: "",
          value_source: "",
          query_type: "OR",
          value_field: "",
          validations: {
            data_type: "",
          },
          nested_related_id: "",
          data_source: "self_field",
          relation_entity_id: relationalEntityId,
          data_type: "RELATION",
        },];
      }
      return [];
    },
    // showError() {
    //   if ((this.isApplicationUserSide && !this.currentMenu) || this.forbiddenAccess) {
    //     return true;
    //   }
    //   return false;
    // },
    getCurrentFilter() {
      return this.entityAllFilters.find(
        (e) => e && e._id == this.currentFilter
      );
    },
    getMainLabel() {
      if (this.entityFiltersData && this.entityFiltersData.tableFieldGroupBy) {
        return this.getLabel(this.entityFiltersData.tableFieldGroupBy);
      }
      return "";
    },
    getSettingsLabel(key) {
      return this.fieldsListData.find((fd) => fd.key == key).label;
    },
    actionButtons() {
      return [{
        label: "Action Buttons",
        options: this.getEntityFieldsByType(["ACTION_BUTTON"]).map(
          (field) => ({
            label: field.label,
            value: field.key,
          })
        ),
      },];
    },
    groupedOptions() {
      return [{
        label: "options",
        options: this.viewEditOptions,
      },
      {
        label: "Action Buttons",
        options: this.getEntityFieldsByType(["ACTION_BUTTON"]).map(
          (field) => ({
            label: field.label,
            value: field.key,
          })
        ),
      },

      {
        label: "Customization",
        options: this.customization?.actions
          .filter((e) => e.active)
          .map((action) => ({
            label: action.label,
            value: action.label,
          })),
      },
      ];
    },
    // displayBackButton() {
    //   if (this.getIframeUrlInfo) {
    //     let modifiedKey = this.checkRoutekey;
    //     if (this.checkRoutekey.startsWith("if#")) {
    //       const parts = this.checkRoutekey.split("#");
    //       if (parts.length > 2) {
    //         modifiedKey = parts.slice(2).join("#");
    //       }
    //     }
    //     if (modifiedKey === this.getIframeUrlInfo.url_key) {
    //       return false;
    //     }
    //   }
    //   return true;
    // },
    getType() {
      if (this.activeEntityView == "CARDS") {
        return "EntityGalleryView";
      } else {
        return "ViewEntityData";
      }
    },
    getDefaultCountry() {
      return (phoneNumber) => {
        try {
          console.log("phoneNumber", phoneNumber);
          const phoneUtil = PhoneNumberUtil.getInstance();
          const number = phoneUtil.parseAndKeepRawInput(phoneNumber);
          const isValid = phoneUtil.isValidNumber(number);
          const countryCode = number.getCountryCode();
          const nationalNumber = number.getNationalNumber();
          //const type = phoneUtil.getNumberType(number);
          const formattedNumber = phoneUtil.format(
            number,
            PhoneNumberFormat.E164
          );
          const formatInternational = phoneUtil.format(
            number,
            PhoneNumberFormat.INTERNATIONAL
          );
          const formatNational = phoneUtil.format(
            number,
            PhoneNumberFormat.NATIONAL
          );
          const countryCallingCode = phoneUtil.getRegionCodeForNumber(number);

          if (!isValid) {
            return null;
          }
          let code = {
            countryCode: countryCallingCode.toString(),
            countryCallingCode: countryCode.toString(),
            phoneNumber: nationalNumber.toString(),
            isValid,
            formattedNumber: formattedNumber.toString(),
            nationalNumber: nationalNumber.toString(),
            //type:'',
            formatInternational: formatInternational.toString(),
            formatNational: formatNational.toString(),
          };
          return code;
        } catch (e) {
          console.log(e.message);
          if (e?.message?.includes("Invalid country calling code")) {
            try {
              const phoneUtil = PhoneNumberUtil.getInstance();
              const number = phoneUtil.parseAndKeepRawInput(phoneNumber, "US");
              const isValid = phoneUtil.isValidNumber(number);
              const countryCode = number.getCountryCode();
              const nationalNumber = number.getNationalNumber();
              //const type = phoneUtil.getNumberType(number);
              const formattedNumber = phoneUtil.format(
                number,
                PhoneNumberFormat.E164
              );
              const formatInternational = phoneUtil.format(
                number,
                PhoneNumberFormat.INTERNATIONAL
              );
              const formatNational = phoneUtil.format(
                number,
                PhoneNumberFormat.NATIONAL
              );
              const countryCallingCode =
                phoneUtil.getRegionCodeForNumber(number);
              if (!isValid) {
                return null;
              }

              let code = {
                countryCode: countryCallingCode.toString(),
                countryCallingCode: countryCode.toString(),
                phoneNumber: nationalNumber.toString(),
                isValid,
                formattedNumber: formattedNumber.toString(),
                nationalNumber: nationalNumber.toString(),
                //type:'',
                formatInternational: formatInternational.toString(),
                formatNational: formatNational.toString(),
              };
              return code;
            } catch (e) {
              console.log(e.message);
              return null;
            }
          }
        }
      };
    },
    checkIsAnyPopupsOpen() {
      return (
        this.showAddRelationshipDataDialog ||
        this.dialogEdit ||
        this.matchDialogVisible ||
        this.ImportZip ||
        this.unmapped_dialog ||
        this.entitySignleRecordVisible ||
        this.mappingLoadingDialogVisible ||
        this.LoadingDialogVisible ||
        this.ExtractDialogVisible ||
        this.mappingStatusDialogVisible ||
        this.entitybulkDeleteVisible ||
        this.showChildEntityDataDialog ||
        this.entityViewModal
      );
    },
    isDeletedItemSelected() {
      return this.selectedRowsIds.every((e) => e.status == "ARCHIVED");
    },
  },
  async mounted() {
    require('../../assets/css/view-data.scss');
    this.menuTitleLoading = true;
    this.searchDocument = "";
    this.currentkey = this.$route.query.key;
    let limit =
      this.pageSizes && this.pageSizes[0] ?
        this.pageSizes[0] :
        this.getPageSize;
    this.pageSize = limit;
    this.refreshPage = true;
    this.currentPage = parseInt(this.$route.query.page) || 1;
    this.pageSize = parseInt(this.$route.query.pageSize) || 10;

    if (this.$route.query.order_by) {
      this.sortBy = this.$route.query.order_by;
    }
    if (this.$route.query.order_type) {
      this.sortOrder = this.$route.query.order_type;
    }

    if (this.$route.query.customization) {
      this.customization_id = this.$route.query.customization;
    }
    setTimeout(() => {
      this.refreshPage = false;
    });
    this.entity_id = this.$route.params.entity_id;
    this.search_string = "";
    this.getNecessaryInfo();

    await this.isIframe();
    // await this.checkUrlType()
    // this.fetchAllEmployee();
    this.checkRoutekey = this.generateOutputFromUrl(this.$route.fullPath, [
      "key",
      "page",
      "pageSize",
    ]);
    this.$nextTick(() => {
      this.getEntitiesAndTemplates();
    });
  },
  created() {
    this.$socket1.emit(
      "join-workspace",
      this.getCompanyDetails._id || this.getContactLoginSlug._id
    );
    this.$socket1.on("entity-data-added", (data) => {
      this.checkAndUpdateTableData(data);
    });
    this.$socket2.on("entity-data-updated", () => {
      if (this.currentPage == 1) {
        this.fetchEntitiesDataForTable(this.applied_quick_filters, true);
      }
    });
    this.$socket2.on("connectionValidation", async (data) => {
      await postAPICall(
        "PUT",
        "/wa/update-WA-single-integration-data-by-instance", { instanceId: data.instanceId }
      );
      this.$notify({
        title: "Warning",
        message: "Connection is not active. Please change the connection.",
        type: "warning",
      });
    });
  },
  methods: {
    actionType(data) {
      switch (data) {
        case 'BACK_ACTION':
          this.backToEntities();
          return;
        case 'IMPORT_EXCEL':
          this.displayimportdialog();
          return;
        case 'IMPORT_ZIP':
          this.zipImportDialog();
          return;
        case 'IMPORT_DOCS':
          this.bulkUploadDocsVisible = true;
          return;
        case 'EXPORT_EXCEL':
          this.openExportCSVDialog();
          return;
        case 'EXPORT_PDF':
          this.exportPDF();
          return;
        case 'SETTINGS':
          this.openSettingsModal();
          return;
        case 'QR':
          this.showQrReader = true;
          return;
        case 'FILTERS':
          this.openCompanyFilterModel();
          return;
        case 'FILTERS_IFRAME':
          this.openFilterModel();
          return;
        case 'QUICK_FILTERS_OPEN':
          return { title: 'About Page', content: 'Learn more about us here.' };
        case 'QUICK_FILTERS_CLOSE':
          return { title: 'Contact Page', content: 'Get in touch with us!' };
        case 'IFRAME':
          this.ViewConfigPage()
          return;
        case 'LAYOUT':
          this.entityViewModal = true;
          return;
        default:
          return;
      }
    },


    isDropdownVisible() {
      return (
        (this.checkPerimission("DUPLICATE") &&
          this.currentEntity &&
          this.currentEntity.entity_type !== "INDIVIDUAL") ||
        (this.checkPerimission("EDIT") &&
          this.checkPermission("UPDATE") &&
          this.IframePermissions("EDIT", "ViewEntityData") &&
          this.checkButtonVisible("Edit") &&
          !(
            this.customizationButtons &&
            this.customizationButtons.some((button) => button === "Edit")
          ) &&
          this.checkRolePermission("editEntitiesData")) ||
        (this.checkPerimission("VIEW") &&
          this.customization != null &&
          this.checkButtonVisible("View") &&
          !(
            this.customizationButtons &&
            this.customizationButtons.some((button) => button === "View")
          ) &&
          this.checkRolePermission("viewEntitiesData")) ||
        (this.hasAttachment &&
          this.checkPerimission("Attachments") &&
          this.checkButtonVisible("Attachments") &&
          this.IframePermissions("ATTACHMENT", "ViewEntityData") &&
          !(
            this.customizationButtons &&
            this.customizationButtons.some((button) => button === "Attachments")
          )) ||
        (this.checkPerimission("SEND NOTIFICATION") &&
          this.checkButtonVisible("Send Notification") &&
          this.IframePermissions("SEND NOTIFICATION", "ViewEntityData") &&
          !(
            this.customizationButtons &&
            this.customizationButtons.some(
              (button) => button === "Send Notification"
            )
          )) ||
        (this.checkPerimission("DELETE") &&
          this.IframePermissions("DELETE", "ViewEntityData") &&
          this.checkButtonVisible("Delete") &&
          !(
            this.customizationButtons &&
            this.customizationButtons.some((button) => button === "Delete")
          ) &&
          this.checkRolePermission("deleteEntitiesData")) ||
        (this.nonPrimaryActions && this.nonPrimaryActions.length > 0)
      );
    },
    handleRadioChange(step) {
      const templateKey = `${step.template_id}#${step.id}`;
      this.$set(
        this.quickUpdateRowData[step.template_id],
        step.id,
        this.quickUpdateRowData[step.template_id][step.id]
      );
      this.$emit("radio-selection-changed", {
        key: templateKey,
        value: this.quickUpdateRowData[step.template_id][step.id],
      });
    },
    checkFilterDisabled(filterData) {
      if (filterData) {
        if (filterData.filters && filterData.filters.length) {
          const lastmonthFilter = filterData.filters.find(
            (fl) =>
              (fl.operator === "lastmonth" && fl.field === "updated_at") ||
              (fl.operator === "thismonth" && fl.field === "updated_at") ||
              (fl.operator === "lastmonth" && fl.field === "created_at") ||
              (fl.operator === "thismonth" && fl.field === "created_at")
          );

          if (lastmonthFilter) {
            return false;
          }
          const last7DaysFilter = filterData.filters.find(
            (fl) => fl.selected_date_picker === "last_7_days"
          );

          if (last7DaysFilter) {
            return false;
          }
        }

        if (filterData.filters && filterData.filters.length) {
          return filterData.filters.some(
            (fl) =>
              !fl ||
              !fl.field ||
              (!fl.operator && fl.field !== "isCreatedFromFormbuilder") ||
              (!fl.data_source &&
                fl.operator !== "exists" &&
                fl.operator !== "notexists" &&
                fl.operator !== "between" &&
                fl.operator !== "notbetween" &&
                fl.operator !== "dateinclude" &&
                fl.operator !== "notdateinclude" &&
                fl.operator !== "real_time" &&
                fl.operator !== "weekdays" &&
                fl.operator !== "filter" &&
                fl.field !== "created_by" &&
                fl.field !== "updated_by") ||
              (fl.data_source === "GLOBAL_VARIABLE" &&
                !fl.global_variable_id &&
                fl.operator !== "exists" &&
                fl.operator !== "notexists") ||
              (fl.data_source === "self_field" &&
                !fl.value_field &&
                fl.operator !== "exists" &&
                fl.operator !== "notexists") ||
              (fl.data_source === "TEMPLATE" &&
                !fl.value_field &&
                fl.operator !== "exists" &&
                fl.operator !== "notexists") ||
              (!fl.value &&
                typeof fl.value !== "number" &&
                typeof fl.value !== "boolean" &&
                fl.data_source !== "self_field" &&
                fl.operator !== "exists" &&
                fl.operator !== "between" &&
                fl.operator !== "notexists" &&
                fl.data_source !== "GLOBAL_VARIABLE" &&
                fl.data_source !== "LOGIN_USER" &&
                fl.operator !== "weekdays" &&
                fl.data_source !== "TEMPLATE")
          ) ||
            (!filterData.filter_name &&
              (filterData.isSaveFilter || filterData.updateFilter)) ||
            (filterData?.displayType === "HTML_CONTENT" &&
              !filterData.email_template_id) ?
            true :
            false;
        } else if (
          filterData.quick_filters &&
          filterData.quick_filters.length
        ) {
          return !filterData.filter_name &&
            (filterData.isSaveFilter || filterData.updateFilter) ?
            true :
            false;
        }
        return filterData.isSaveFilter || filterData.updateFilter ?
          true :
          false;
      }
      return false;
    },
    normalizeFieldKeys() {
      const fieldsToSanitize = ["field1", "field2", "field3"];
      fieldsToSanitize.forEach((field) => {
        if (this.showFieldsParent[field]) {
          this.showFieldsParent[field] = this.normalizeFieldKey(
            this.showFieldsParent[field]
          );
        }
      });
    },
    normalizeFieldKey(value) {
      if (value && value.includes("#")) {
        const parts = value.split("#");
        return [...new Set(parts)].join("#");
      }
      return value;
    },

    checkRolePermission(permission) {
      if (this.getUserType?.permissionSet && !this.isApplicationUserSide) {
        return this.getUserType.permissionSet.includes(permission);
      }
      return true;
    },
    async getEntitiesAndTemplates() {
      let [entities, templates] = await Promise.all([
        fetchEntitiesByPagination({ get_all: true }, true),
        getAllCompanyTemplatesByPagination({
          get_all: true,
          include_standard: true,
        },
          true
        ),
      ]);
      if (entities?.data) {
        this.allEntitiesData = [...entities.data];
      }
      if (templates?.data) {
        this.companyAllTemplateFields = this.getAllTemplateFieldsObject(
          templates.data
        );
      }
    },
    selectAllData() {
      if (this.selectedRowsIds.length === this.data.length) {
        this.selectedRowsIds = [];
      } else {
        // Otherwise, select all records
        this.selectedRowsIds = [...this.data];
      }
    },
    getCurrentFieldOptions(template_id, key, entity) {
      let field = entity.templates?.find((e) => e?.templateInfo._id == template_id)
        .templateInfo.sections[0]?.fields?.find(
          (e) => e.key == key || e.key?.split("#")[1] == key
        );
      let options = field?.options;
      if (field?.is_global_variable && field?.global_variable_id) {
        let field = (this.globalVariablesData || [])?.find(
          (e) => e._id == field.global_variable_id
        );
        return field?.options || [];
      }
      return options || [];
    },
    async setGlobalVariableData(global_variable_id) {
      let params = {
        id: global_variable_id,
      };
      // await this.$store.dispatch(
      //   "globalVariables/fetchGlobalVariableById",
      //   params
      // );
      let globalVariable = await fetchGlobalVariableById(params);
      if (globalVariable) {
        return globalVariable;
      }
    },
    refreshEntityData(type, page) {
      if (page) {
        if (page <= Math.ceil(this.total / 12)) {
          this.currentPage = page;
        } else {
          this.$notify.warning({
            title: "Warning",
            message: "Please Enter valid Page",
          });
        }
      } else {
        switch (type) {
          case "next":
            this.currentPage += 1;
            break;
          case "previous":
            this.currentPage -= 1;
            break;
          default:
            break;
        }
      }
      console.log("page", this.currentPage);
      if (this.currentPage) {
        this.fetchEntitiesDataForTable(
          this.applied_quick_filters || [],
          true,
          false,
          "child"
        );
      }
    },
    getPaginationData() {
      return {
        hasMore: this.hasMore,
        page: parseInt(this.getCurrentPage) ?
          parseInt(this.getCurrentPage) :
          this.currentPage,
        total: this.total,
      };
    },
    selectAllFields() {
      this.selectedLabels = this.allCurrentEntityFields.map(
        (field) => field.label
      );
    },
    selectFileFields() {
      this.selectedFiles = this.allFileFields.map(
        (field) => field.template_key
      );
    },
    openExportCSVDialog() {
      let isTablePresent = this.allEntityFields.some(
        (field) =>
          field.input_type === "ENTITY_TABLE" ||
          field.input_type === "DATA_TABLE"
      );
      if (isTablePresent) {
        this.openExportCSVDialogModal = true;
      } else {
        this.exportCSV();
      }
    },
    closeExportCSVDialog() {
      this.openExportCSVDialogModal = false;
    },
    openGallery(images) {
      this.currentImages = images;
      this.showGallery = true;
    },

    async fetchEntityFields() {
      try {
        if (this.entity_id) {
          let [allCurrentEntityFields, currentEntityFields] = await Promise.all(
            [
              this.fetchEntityDetails(
                this.entity_id,
                true,
                false,
                true,
                false,
                true,
                false,
                JSON.parse(JSON.stringify(this.currentEntity))
              ),
              this.fetchEntityDetails(
                this.entity_id,
                true,
                false,
                false,
                false,
                true,
                false,
                JSON.parse(JSON.stringify(this.currentEntity))
              ),
            ]
          );
          this.allCurrentEntityFields = allCurrentEntityFields;
          this.currentEntityFields = currentEntityFields;
          this.allCurrentEntityFields = allCurrentEntityFields.filter(
            (field) => field.key && field.input_type !== "FILE"
          );
          this.allFileFields = allCurrentEntityFields.filter(
            (field) => field.key && field.input_type === "FILE"
          );
          this.fieldsObject = {};
          this.allCurrentEntityFields.map((e) => {
            this.fieldsObject[e.template_key] = e;
            this.fieldsLabelObject[e.label.toString()] = e;
          });
          this.currentEntityFields.map((e) => {
            this.currentEntityFieldsObject[e.template_key] = e;
          });
        } else {
          console.warn("No entity_id found for the current entity.");
        }
      } catch (error) {
        console.error("Error fetching entity fields:", error);
        this.loading = false;
      }
    },
    groupFieldsByTemplate() {
      let groupedFields = {};
      let groupedOutputTypes = {};
      this.selectedFields = this.selectedLabels;
      let fields = this.selectedLabels?.length ?
        this.selectedLabels :
        Object.keys(this.fieldsLabelObject);
      let newArray = {};
      fields.forEach((label) => {
        const field = this.fieldsLabelObject[label];
        if (field) {
          const templateName = field.template_name.includes("-") ?
            field.template_name.split("-")[0] :
            field.template_name;
          if (!groupedFields[templateName]) {
            groupedFields[templateName] = [];
            groupedOutputTypes[templateName] = {};
          }
          if (field.isDataTableField) {
            let key = `${field.template_id}#${field.data_table_key}`;
            let dataTableField = this.currentEntityFieldsObject[key];
            if (dataTableField?.label) {
              let fieldLabelParts = field.label.split("-");
              let actualFieldLabel = fieldLabelParts.pop().trim();
              if (!newArray[dataTableField.label]) {
                newArray[dataTableField.label] = [];
              }
              newArray[dataTableField.label].push(actualFieldLabel);
            }
          } else {
            let FieldLabel = field.label.includes(" - ") ?
              field.label.split(" - ").pop() :
              field.label;
            groupedFields[templateName].push(FieldLabel);
            groupedOutputTypes[templateName][FieldLabel] = this.getOutputType(
              field.inputType
            );
          }
        }
      });
      Object.keys(newArray).forEach((dataTableKey) => {
        let dataTableKeyParts = dataTableKey.split(" - ");
        let templateName = dataTableKeyParts[0];
        let tableName = dataTableKeyParts[1];
        if (groupedFields[templateName]) {
          groupedFields[templateName].push({
            [tableName]: newArray[dataTableKey],
          });
        } else {
          groupedFields[templateName] = [{
            [tableName]: newArray[dataTableKey],
          },];
        }
      });
      return { groupedFields, groupedOutputTypes };
    },

    deleteZipFile(index) {
      this.zipFiles.splice(index, 1);
    },
    async uploadZipFile(zipFile) {
      if (this.zipFiles.length >= 1) {
        this.$message.warning("Only one Zip file can be uploaded at a time");
        return;
      }

      if (
        zipFile.raw.type === "application/zip" ||
        zipFile.raw.name.endsWith(".zip")
      ) {
        this.zipFiles.push(zipFile);
        const zip = new JSZip();
        await zip.loadAsync(zipFile.raw);
        const fileData = {};

        for (const [fileName, zipEntry] of Object.entries(zip.files)) {
          if (!zipEntry.dir) {
            const fileType = this.getMimeType(fileName);
            const fileSize = zipEntry._data.uncompressedSize;
            fileData[fileName] = { type: fileType, size: fileSize };
          }
        }

        this.zipFilesData = fileData;
      } else {
        this.$message.error("Only ZIP files are supported");
      }
    },
    getMimeType(fileName) {
      const extension = fileName.split(".").pop().toLowerCase();
      const mimeTypes = {
        pdf: "application/pdf",
        doc: "application/msword",
        docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        jpg: "image/jpeg",
        jpeg: "image/jpeg",
        png: "image/png",
      };
      return mimeTypes[extension] || null;
    },
    async uploadFilesInZip() {
      const zipFile = this.zipFiles[0];
      const formData = new FormData();
      try {
        const zip = new JSZip();
        const zipContent = await zip.loadAsync(zipFile.raw || zipFile);
        for (const [fileName, zipEntry] of Object.entries(zipContent.files)) {
          if (!zipEntry.dir) {
            const fileData = await zipEntry.async("blob");
            const file = new File([fileData], fileName);
            formData.append("files", file);
          }
        }
        formData.append("path", "template-data-documents");

        const filesData = await nodeAxios.post(
          `/presignMultiUpload`,
          formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
        );

        return filesData;
      } catch (error) {
        this.$message.error("Failed to upload files in ZIP");
        console.error("Error uploading files:", error);
        return null;
      }
    },
    closeFieldsDropdown() {
      this.$refs.labelsDropdown?.blur();
      this.$refs.filesDropdown?.blur();
    },
    async AnalyzingData() {
      this.closeFieldsDropdown();
      // this.uploadFilesInZip();
      if (this.zipFiles.length === 0) {
        this.$message.error("Please select a Zip file");
        return;
      }
      this.LoadingDialogVisible = true;
      try {
        let url = await this.getZipFileUrl(this.zipFiles[0]);
        if (!url) {
          this.$message.error("Error Uploading File");
          return;
        }
        let { groupedFields, groupedOutputTypes } =
          this.groupFieldsByTemplate();
        let payload = {
          url: url,
          user_prompt: this.user_prompt,
          fields: groupedFields,
          fileFields: this.selectedFiles,
          fileObj: this.creationType == "Upload Zip" ?
            this.zipFilesData :
            this.getFieldsObjData(),
          fieldOutputTypes: groupedOutputTypes,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          entity_id: this.entity_id,
        };
        const apiResponse = await postAPICall(
          "POST",
          "/process-zip-data",
          payload
        );
        if (!apiResponse.success) {
          this.$message.error("Error processing ZIP data. Please try again.");
          return;
        }

        this.LoadingDialogVisible = false;
        this.ImportProcessInitiatedVisible = true;
      } catch (err) {
        if (err.response) {
          console.error(
            "Server responded with an error:",
            err.response.status,
            err.response.data
          );
          if (err.response.status === 404) {
            this.$message.error(
              "API endpoint not found. Please contact support."
            );
          } else if (err.response.status === 500) {
            this.$message.error(
              "Internal server error. Please try again later."
            );
          } else {
            this.$message.warning(
              "An issue occurred while processing ZIP files."
            );
          }
        } else if (err.request) {
          console.error("No response received from the server:", err.request);
          this.$message.error(
            "No response from server. Please check your network connection."
          );
        } else {
          console.error("Error setting up the request:", err.message);
          this.$message.warning("An unexpected error occurred.");
        }
      } finally {
        this.LoadingDialogVisible = false;
      }
    },
    getFieldsObjData() {
      let obj = {};
      this.files.map((e) => {
        obj[e.name] = { type: this.getMimeType(e.name), size: e.size };
      });
      return obj;
    },

    async uploadZipToCloud() {
      if (!this.bulkUploadDocsForm.hasSeparator &&
        !this.bulkUploadDocsForm.selectedField
      ) {
        return this.$message.warning("Please select a field");
      }
      let zipUrl;
      this.LoadingDialogVisible = true;

      if (this.creationType == "Upload Zip") {
        zipUrl = await this.getZipFileUrl(this.zipFiles[0]);
      } else {
        let zipFile = await this.prepareZipFromFiles(this.files);
        zipUrl = await this.getZipFileUrl(zipFile);
      }
      if (!zipUrl) {
        this.LoadingDialogVisible = false;
        return this.$notify.error({
          title: "Error",
          message: "Error Uploading File(s)",
        });
      }
      let payload = {
        url: zipUrl,
        fileObj: this.creationType == "Upload Zip" ?
          this.zipFilesData :
          this.getFieldsObjData(),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        entity_id: this.entity_id,
        form: this.bulkUploadDocsForm,
      };
      const apiResponse = await postAPICall(
        "POST",
        "/bulk-upload-documents",
        payload
      );
      if (!apiResponse.success) {
        this.LoadingDialogVisible = false;
        this.$message.error("Error processing ZIP data. Please try again.");
        return;
      } else {
        Swal.fire({
          title: "Document Upload Process Started",
          text: "Please check back in a few minutes.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.LoadingDialogVisible = false;
            this.bulkUploadDocsVisible = false;
          }
        });
      }

      this.LoadingDialogVisible = false;
      this.bulkUploadDocsVisible = false;
    },
    async getZipFileUrl(zipFile) {
      var formData = new FormData();
      formData.append("files", zipFile.raw ? zipFile.raw : zipFile);
      formData.append("path", "temporary-files");
      formData.append("include_presigned_urls", true);
      let filesData = await nodeAxios.post(`/presignMultiUpload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      let url;
      if (Object.keys(filesData.data.urlsObject).length) {
        url =
          filesData.data.urlsObject[Object.keys(filesData.data.urlsObject)[0]]?.downloadURL;
      }
      return url || "";
    },
    async ExportData() {
      this.closeFieldsDropdown();
      if (this.files.length === 0) {
        this.$message.error("Please select files");
        return;
      }
      const zip = new JSZip();
      this.files.forEach((file) => {
        zip.file(file.name, file.raw);
      });
      try {
        const content = await zip.generateAsync({ type: "blob" });
        const zipFileUrl = URL.createObjectURL(content);
        this.ExtractDialogVisible = true;
        await this.processZipFromUrl(zipFileUrl);
        this.ImportProcessInitiatedVisible = true;
      } catch (error) {
        this.$message.error("Failed to convert files to zip");
      }
    },
    refreshCurrentPage() {
      this.fetchEntitiesDataForTable();
    },
    async processZipFromUrl(zipFileUrl) {
      try {
        const response = await fetch(zipFileUrl);
        const blob = await response.blob();
        const zipFile = new File([blob], "exported_files.zip", {
          type: "application/zip",
        });
        this.zipFiles = [zipFile];
        await this.AnalyzingData();
      } catch (error) {
        this.$message.error("Failed to process zip file");
      }
    },
    async prepareZipFromFiles(files) {
      const zip = new JSZip();
      files.forEach((file) => {
        zip.file(file.name, file.raw);
      });
      try {
        const content = await zip.generateAsync({ type: "blob" });
        const zipFileUrl = URL.createObjectURL(content);
        const response = await fetch(zipFileUrl);
        const blob = await response.blob();
        const zipFile = new File([blob], "exported_files.zip", {
          type: "application/zip",
        });
        return zipFile;
      } catch (error) {
        this.$message.error("Failed to convert files to zip");
      }
    },
    uploadFile(file) {
      if (
        file.raw.type === "application/pdf" ||
        file.raw.type === "application/msword" ||
        file.raw.type === "image/jpeg" ||
        file.raw.type === "image/png" ||
        file.raw.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        this.files.push(file);
      } else {
        this.$message.error("File format is not supported");
      }
    },
    moveDown(index) {
      if (index == this.files.length - 1) {
        return;
      }
      let sortedUsers = this.swapSingatureUsers(index, index + 1, this.files);
      this.files = [];
      this.files = sortedUsers;
    },
    moveUp(index) {
      if (index == 0) {
        return;
      }
      let sortedUsers = this.swapSingatureUsers(index, index - 1, this.files);
      this.files = [];
      this.files = sortedUsers;
    },
    swapSingatureUsers(fromIndex, toIndex, signaturedUsers) {
      let fromUser = signaturedUsers[fromIndex];
      let toUser = signaturedUsers[toIndex];
      signaturedUsers[fromIndex] = toUser;
      signaturedUsers[toIndex] = fromUser;
      return signaturedUsers;
    },
    async deleteItem(index) {
      this.files.splice(index, 1);
    },
    closeZipDialog() {
      this.ImportZip = false;
    },
    addKeyListener() {
      document.addEventListener("keydown", this.handleKeyDown);
    },
    removeKeyListener() {
      document.removeEventListener("keydown", this.handleKeyDown);
    },
    handleQuickUpdateData(data) {
      this.isEmailUpdate = true;
      this.quickUpdateRowData = {
        ...data,
      };
    },
    handleKeyDown(event) {
      if (event.key === "Enter") {
        this.deleteCheckedEntityData();
      }
    },
    adjustEditorSize() {
      const dialogElement = this.$refs.dialogComponent?.$el;
      if (dialogElement) {
        const dialogWidth = dialogElement.clientWidth;
        const dialogHeight = dialogElement.clientHeight;
        this.editorConfig.width = dialogWidth * 0.73;
        this.editorConfig.height = dialogHeight * 0.6;
      }
    },
    confirmPermanentDelete(ids) {
      this.$confirm("Are you sure to delete the data permanently?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.permanenteDeleteEntityData(ids);
      });
    },
    async permanenteDeleteEntityData(entityDataIds) {
      if (entityDataIds?.length) {
        if (this.currentEntity?.entity_type == "INDIVIDUAL") {
          this.$message.info("Can't restore Individual data");
          return;
        }
        try {
          this.loading = true;
          this.loadingText = "Deleting data permanently...";
          await postAPICall(
            "POST",
            "/entities-data/archived/delete-permanently", {
            entityDataIds: entityDataIds,
            entityId: this.currentEntity?._id,
          }
          );
          this.loading = false;
          this.loadingText = "Loading...";
          this.fetchEntitiesDataForTable();
        } catch (e) {
          console.log("restoreEntityData", e);
          this.$message.error(
            e?.response?.message || "Oops, something went wrong. Try again."
          );
        }
      }
    },
    confirmRestore(ids) {
      this.$confirm("Are you sure to restore the data?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.restoreEntityData(ids);
      });
    },
    isFieldDisabled(field, index) {
      return this.bulkUpdateData.filter(
        (dt, i) => i !== index && dt.field.split("#")[0] == field._id
      ).length ?
        true :
        false;
    },
    async fetchEmailTemplates() {
      // let params = {
      //   page: 1,
      //   limit: 40,
      //   entity_id: this.$route.params.entity_id,
      //   getAll: true,
      // };
      // let response = await postAPICall("GET", "email-template/list", params);
      // this.allEmailTemplates = response.data;
      this.allEmailTemplates = await fetchEntityNotificationTemplates({ entity_id: this.$route.params.entity_id });
    },
    closeQrCodeModal() {
      this.showQrReader = false;
      setTimeout(() => {
        navigator.mediaDevices.getUserMedia({ video: true });

        navigator.mediaDevices
          .getUserMedia({ video: true, audio: false })
          .then((mediaStream) => {
            const stream = mediaStream;
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
          });
        if (this.dataAdded) {
          this.fetchEntitiesDataForTable(this.applied_quick_filters, true);
          this.dataAdded = false;
        }
      }, 1000);
    },
    async saveQRCodeData(dataId) {
      this.loading = true;
      this.loadingText = "Verifying... please wait....";
      this.showQrReader = false;
      this.qrLoading = false;
      let duplicateRules =
        (this.currentEntity.templates || [])?.[0]?.templateInfo?.all_duplicate_rules || [];
      if (duplicateRules.length) {
        //need to change this logic but present it'll work for now
        if (this.addedDataIds.includes(dataId)) {
          this.$notify.warning({
            title: "Warning",
            message: "Data is already scanned",
          });
          return;
        }
        this.qrLoading = true;
        let checkData = await this.checkDuplicateData(
          this.currentEntity.templates[0].templateInfo,
          dataId,
          true
        );
        if (checkData?.success) {
          this.qrLoadingText = "Verifying .... please wait ....";
          this.addNewEntityDataByQr(dataId);
          this.qrLoading = false;
        } else {
          // this.$notify.error({
          //   title: "Error",
          //   message: checkData.message,
          // });
          this.loading = false;
          this.qrLoading = false;
          Swal.fire({
            title: "Already Existed",
            text: checkData.message,
            icon: "error",
            showCancelButton: false,
            confirmButtonColor: "#FF0000",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.loading = false;
              this.loadingText = "";
              this.showQrReader = true;
            }
          });
        }
      } else {
        this.addNewEntityDataByQr(dataId);
      }
    },
    async prepareTemplateData(template, dataId, isFromQr = false) {
      let entitiesData, data;
      if (isFromQr) {
        entitiesData = await this.getEntityDataByIds(
          this.qrCodeField.entity_id, [dataId]
        );
        data = entitiesData?.[0]?.entityData;
      }
      let form = {};
      (template?.sections[0]?.fields || []).forEach((fd) => {
        if (fd.key) {
          if (this.qrCodeField.key == fd.key) {
            form[fd.key] = dataId;
            if (data) {
              let name = [];
              fd.primary_fields.map((fk) => {
                let [templateId, key] = fk.split("#");
                name.push(data[templateId]?.[key]);
              });
              form[fd.key + "/name"] = name.join(" - ");
            }
          } else if (
            ["DATE", "DATE_TIME", "TIME"].includes(
              fd.input_type
            ) &&
            fd.properties &&
            (fd.properties.defaultTodayDate ||
              fd.properties.defaultTodayTime ||
              fd.properties.defaultTodayDateTime ||
              fd.properties.autofill_datetime_response)
          ) {
            switch (fd.input_type) {
              case "DATE":
                form[fd.key] = moment().startOf("day").utc().toISOString();
                break;
              case "DATE_TIME":
                form[fd.key] = moment().utc().toISOString();
                break;
            }
          }
        }
      });
      return form;
    },
    async checkDuplicateData(templateData, dataId, isFromQr = false) {
      let form = await this.prepareTemplateData(templateData, dataId, isFromQr);
      await this.$store.dispatch("templatesData/checkDuplicateData", {
        data: [{
          form: form,
          template_id: templateData._id,
        },],
        entity_id: this.currentEntity._id,
        parent_entityDataId: null,
      });
      let result = {
        success: true,
        message: "",
      };
      if (this.getDuplicateDataCheck) {
        if (!this.getDuplicateDataCheck?.allowed) {
          let name =
            this.currentEntity && this.currentEntity.name ?
              this.currentEntity.name :
              "";
          let message = "With this data already $entity_name created.";
          let match;
          if (templateData?.duplicate_message) {
            let parsedMessage = JSON.stringify(templateData.duplicate_message);
            let pattern = /\[\[([^[\]]*)\]\]/g;
            let mentionedSubjectLabels = [];
            while (
              (match = pattern.exec(templateData?.duplicate_message)) !== null
            ) {
              mentionedSubjectLabels.push(match[1]);
            }
            mentionedSubjectLabels.map((fd) => {
              let [type, label] = fd.split("-").map((f) => f.trim());
              let field = templateData?.sections[0]?.fields?.find(
                (f) => f.label == label
              );
              if (!field) {
                return;
              }
              let dataForm =
                type == "EXISTED" ?
                  this.getDuplicateDataCheck?.existedData?.entityData?.[
                  templateData._id
                  ] :
                  form;
              let parsedField = ![undefined, null].includes(field) ?
                this.getFormattedFieldData(field, dataForm) :
                " - ";
              parsedMessage = parsedMessage.replace(`[[${fd}]]`, parsedField);
            });
            message = parsedMessage;
          }
          message = message.replaceAll("$entity_name", name);
          result.message = message;
          result.success = false;
        }
      } else {
        result.message = "Error while checking data";
        result.success = false;
      }
      return result;
    },
    displayQRCode() {
      this.showQrReader = true;
    },
    async addNewEntityDataByQr(dataId) {
      /*  this.addedDataIds.push(dataId);
      this.dataAdded = true;
      this.$notify.success({
        title: "Success",
        message: "Data is added successfully",
      });

      */
      try {
        this.loading = true;
        this.showQrReader = false;
        this.qrLoading = false;
        let response = await postAPICall("POST", "/entities-data/qr-code/add", {
          qr_code_field: this.currentEntity.qr_code_import_field,
          qrCodeImportField_search_type: this.currentEntity.qrCodeImportField_search_type,
          qrCodeImportField_search_field: this.currentEntity.qrCodeImportField_search_field,
          entity_id: this.entity_id,
          qr_code_field_value: dataId,
          qr_code_entity: this.qrCodeField.entity_id,
          qr_code_field_primary_fields: this.qrCodeField.primary_fields,
          current_date: moment().format("MM-DD-YYYY"),
        });
        this.loading = false;
        this.loadingText = "";
        Swal.fire({
          title: response.title || "Added !",
          text: response.message || "Data has been added!",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loading = false;
            this.loadingText = "";
            this.showQrReader = true;
            this.qrLoading = false;
          }
        });
      } catch (error) {
        this.loading = true;
        this.showQrReader = false;
        this.qrLoading = false;
        console.log("error", error.response.data.message);

        Swal.fire({
          title: error.response.data.title || "Not Found",
          text: error.response.data.message || error.response.data.message,
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#FF0000",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loading = false;
            this.loadingText = "";
            this.showQrReader = true;
            this.qrLoading = false;
          }
        });
      }
    },
    async checkAndUpdateTableData(data) {
      if (this.entity_id == data.entity_id) {
        if (this.currentPage == 1) {
          if (!this.checkIsAnyPopupsOpen) {
            // if (this.currentFilter) {
            //   this.$message({
            //     dangerouslyUseHTMLString: true,
            //     message: `New ${this.getEntityName() || "data"} is added.`,
            //     duration: 3000,
            //   });
            //   setTimeout(() => {
            //     this.fetchEntitiesDataForTable(
            //       this.applied_quick_filters,
            //       false,
            //       false
            //     );
            //   }, 2000);
            // } else {
            const { data, total } = await this.fetchEntitiesDataForTable(
              this.applied_quick_filters,
              false,
              true
            );
            if (
              this.data &&
              data &&
              (!this.data.length || this.data[0]._id !== data[0]._id)
            ) {
              this.data = data;
              this.total = total;
            }
            // }
          }
        }
      }
    },
    closeAttachmentsModal() {
      this.selectedAttachmentData = null;
      this.openAttachmentsModal = false;
    },
    openAttachments(row) {
      this.selectedAttachmentData = row;
      this.openAttachmentsModal = true;
    },
    closeSelectDropdowns() {
      this.$nextTick(() => {
        document.querySelectorAll(".el-select-dropdown").forEach((dropdown) => {
          const selectComponent = dropdown.__vue__;
          if (selectComponent && selectComponent.$parent) {
            selectComponent.$parent.visible = false;
          }
        });
      });
    },
    checkIsEntityCount(field) {
      return field && field.show_count ? true : false;
    },
    checkCurrency(field) {
      if (
        field?.show_count &&
        (field.show_count_type === "sum" ||
          field.show_count_type === "average") &&
        field.count_field
      ) {
        let [t, k] = field.count_field.split("#");
        let selectedEntity = this.getAllEntitiesInTemplate?.[field.entity_id];
        let selectTemplate = (selectedEntity?.templates || []).find(
          (e) => e.template_id == t
        );
        let isCurrencyField = (
          selectTemplate?.templateInfo?.sections?.[0]?.fields || []
        ).find((e) => e.key == k);
        if (isCurrencyField?.validations?.currency) {
          return isCurrencyField.validations;
        }
        return null;
      }
      return null;
    },
    prepareEntityDataToShowInTable(data, currentEntity) {
      let allColumns = [];
      (currentEntity.templates || []).forEach((template) => {
        if (!template?.is_repeatable &&
          template?.templateInfo?.sections?.[0]?.fields
        ) {
          (
            this.applyCustomizationOnFields(
              template?.templateInfo?.sections[0]?.fields,
              template?.customization,
              template
            ) || []
          ).forEach((field) => {
            let key = field.key;
            allColumns.push({
              ...field,
              ...{
                template_id: template.template_id,
                id: key,
                key: template.template_id + "#" + field.key,
                checkCurrency: this.checkCurrency(field),
                checkIsEntityCount: this.checkIsEntityCount(field),
                getCurrencyFormet: this.getCurrencyFormet(field),
              },
            });
          });
        }
      });
      return data.map((row) => {
        allColumns.forEach((column) => {
          row[column.key] = this.getFieldValue(row, column, column);
          if (column?.checkCurrency) {
            row[column.key + "/checkCurrency"] = column.checkCurrency;
          }
          if (column?.checkIsEntityCount) {
            row[column.key + "/checkIsEntityCount"] = column.checkIsEntityCount;
          }
          if (column?.getCurrencyFormet) {
            row[column.key + "/getCurrencyFormet"] = column.getCurrencyFormet;
          }
        });
        return row;
      });
    },
    closeDetailedCount() {
      this.selectedComponent = null;
      this.countDetailsParentEntityDataId = "";
      this.countDetailsParentEntityId = "";
      this.countDetailsEntityId = "";
      this.appliedFilters = [];
      this.showDetailedCount = false;
    },
    openDetailedCount(key, parent) {
      let field = this.getField(key);
      this.selectedComponent = {
        entity_id: field.entity_id,
        component_name: "",
        customFilers: field.filters || [],
        parent_entityId: parent.entity_id,
        parent_entityDataId: parent._id,
      };
      this.countDetailsParentEntityDataId = parent._id;
      this.countDetailsParentEntityId = parent.entity_id;
      this.countDetailsEntityId = field.entity_id;
      this.appliedFilters = field.filters || [];
      this.showDetailedCount = true;
    },
    formatDate(value) {
      if (!value) {
        return "";
      }

      const date = new Date(value);
      if (isNaN(date.getTime())) {
        return "";
      }

      return this.globalDateTimeFormat(date);
    },
    getNumberOfDataTableFields(row, step) {
      if (
        row.entityData &&
        row.entityData[step.template_id] &&
        row.entityData[step.template_id][step.id]
      ) {
        const dataTable = row.entityData[step.template_id][step.id];
        return Object.keys(dataTable[0]).filter(
          (key) => key !== "keyIndex" && key !== "date_time/dateformat"
        ).length;
      }
      return 0;
    },
    dataTableOpen(row, step) {
      console.log("Opening dialog for row:", row, "step", step);
      this.currentTemplateFields = (this.allEntityFields || []).filter(
        (e) => e.template_id == step.template_id
      );
      this.currentField = (this.currentTemplateFields || []).find(
        (e) => e.key == step.id
      );
      this.currentData = {};
      this.currentData = {
        ...row.entityData[step.template_id],
      };
      this.selectedRoww = row;
      this.isDialogVisible = true;
    },
    dataTableClose() {
      this.isDialogVisible = false;
    },
    globalDateTimeFormat(date) {
      if (!date || isNaN(new Date(date).getTime())) {
        return "";
      }
      return new Date(date).toISOString(); // Formats as 2025-01-28T04:54:02.552Z
    },

    getStandardTemplateInfo(row) {
      if (this.currentEntity && this.currentEntity.templates) {
        let entity = this.currentEntity;
        let standardTemp = "";
        if (entity.entity_type === "INDIVIDUAL") {
          standardTemp = entity.templates.find((temp) => {
            if (
              temp &&
              temp.templateInfo &&
              temp.templateInfo.type === "STANDARD" &&
              temp.template_id
            ) {
              return temp;
            }
          });
          if (
            standardTemp?.template_id &&
            row?.entityData &&
            row.entityData[standardTemp.template_id] &&
            row.entityData[standardTemp.template_id]?.first_name &&
            row.entityData[standardTemp.template_id].last_name
          ) {
            return (
              row.entityData[standardTemp.template_id].first_name +
              "," +
              row.entityData[standardTemp.template_id].last_name
            );
          } else {
            return "";
          }
        }
        return "";
      }
      return "";
    },
    getInitials(name) {
      const words = name.split(",");
      const firstLetter = words[0].charAt(0).toUpperCase();
      const lastLetter = words[words.length - 1].charAt(0).toUpperCase();
      return firstLetter + lastLetter;
    },
    showMap(newValue) {
      this.isShowOnMap = true;
      this.getCoordinates(newValue);
    },
    closeShowOnMap() {
      this.isShowOnMap = false;
    },
    async getCoordinates(address) {
      try {
        // These are query Params to make API call
        const config = {
          params: {
            address: address,
            sensor: true,
            key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
          },
        };

        // Make the API request using the config Params object
        const response = await axios.get(
          "https://maps.googleapis.com/maps/api/geocode/json",
          config
        );

        // Extract coordinates from response
        const coordinates = response.data.results[0].geometry.location;
        // Update the coordinates for the marker to point
        this.coordinates = [coordinates.lat, coordinates.lng];
        // Update coordinates to focus map at where the pointer is
        this.center = [coordinates.lat, coordinates.lng];
        this.$set(
          this.form,
          this.data.key,
          `${this.coordinates[0]}, ${this.coordinates[1]}`
        );
        return coordinates;
      } catch (error) {
        return null;
      }
    },
    checkPermissions(step) {
      let access = "EDIT";
      let template_id = step.template_id;
      if (this.isApplicationUserSide) {
        if (this.getEntityPermission) {
          if (this.currentMenu) {
            if (
              this.currentMenu?.entity_type == "ENTITY" &&
              this.getEntityPermission.other_entities &&
              template_id &&
              this.getEntityPermission.other_entities[this.currentEntity._id]?.templates?.[template_id] &&
              this.getEntityPermission.other_entities[
                this.currentEntity._id
              ]?.templates?.[template_id].indexOf(access) === -1
            ) {
              return false;
            } else if (
              this.currentMenu?.entity_type == "RELATIONAL_ENTITY" &&
              this.getEntityPermission.relational_entities &&
              this.getEntityPermission.relational_entities[
                this.currentEntity._id
              ]?.permissions &&
              this.getEntityPermission.relational_entities[
                this.currentEntity._id
              ]?.templates?.[template_id].indexOf(access) === -1
            ) {
              return false;
            } else if (
              this.currentMenu?.entity_type == "NESTED_RELATIONAL_ENTITY" &&
              this.currentMenu?.nested_relational_entity &&
              this.getEntityPermission.nested_relational_entities &&
              this.getEntityPermission.nested_relational_entities[
                this.currentMenu.nested_relational_entity
              ]?.templates?.[template_id].indexOf(access) === -1
            ) {
              return false;
            }
          } else if (this.$route.query.fromCustom) {
            if (
              this.getEntityPermission?.other_entities &&
              this.getEntityPermission?.other_entities[
                this.currentEntity._id
              ]?.templates?.[template_id].indexOf(access) === -1
            ) {
              return false;
            }
            return true;
          }
        }
        return true;
      }
      return true;
    },
    getFieldForQuickUpdate(step) {
      return this.currentEntity?.templates?.find((e) => e.template_id == step.template_id)
        .templateInfo.sections[0]?.fields.find((e) => e.key == step.id);
    },
    truncateText(text) {
      const cleanedText = text.replace(/<\/?[^>]+(>|$)/g, "");
      const maxLength = 100;
      if (cleanedText.length > maxLength) {
        return cleanedText.substring(0, maxLength) + "...";
      } else {
        return cleanedText;
      }
    },
    openMultiLineData(htmlContent, label, step) {
      this.$nextTick(() => {
        this.adjustEditorSize();
      });
      this.multiLineTextData = htmlContent;
      this.multiLineTextLabel = label;
      if ("currency_code" in step && "data_type" in step.currency_code) {
        this.isRichText = false;
      } else {
        this.isRichText = true;
      }
      this.openMultiLineTextDialog = true;
    },
    closeMultiLneData() {
      this.openMultiLineTextDialog = false;
      this.isRichText = null;
    },
    rulesEventEmitter(template_id, form) {
      let template = this.currentEntity?.templates?.find(
        (e) => e?.templateInfo._id == template_id
      );
      let fields = template?.templateInfo?.sections[0]?.fields || [];
      let templateRules = template.templateInfo.rules.filter(
        (rl) => !rl?.show_alert && rl?.when !== "AFTER_SAVE"
      );
      this.applyRulesOnFields(fields, templateRules, form);
    },
    entitybulkDeleteVisibleDialog() {
      this.entitybulkDeleteVisible = false;
    },
    closeBulkUpdateDialogueBox() {
      this.closeBulkUpdateDialogue = false;
    },
    unMappedDialog() {
      this.unmapped_dialog = false;
    },
    async getNestedRelationshipDataIds() {
      if (
        this.isApplicationUserSide &&
        this.getAuthenticatedUser?.contact_types &&
        this.currentMenu?.nested_relational_entity
      ) {
        let currentActiveWorkspace =
          this.getAuthenticatedUser.contact_types.find((e) => {
            let id =
              e.contact_type && e.contact_type._id ?
                e.contact_type._id :
                e.contact_type;
            if (id && id == this.activeWorkspace) {
              return true;
            }
          });
        let params = {
          parent_entity_id: this.activeWorkspace,
          parent_entity_data_id: currentActiveWorkspace.account_data_id,
          child_entity_id: this.currentMenu.nested_relational_entity.split("#")[0],
          nested_child_entity_id: this.currentMenu.nested_relational_entity.split("#")[1],
        };
        await this.$store.dispatch(
          "entityRelationships/fetchParentEntityNestedRelationshipData",
          params
        );
        if (
          this.getParentEntityNestedRelationshipData &&
          this.getParentEntityNestedRelationshipData.child_entity_data_ids &&
          this.getParentEntityNestedRelationshipData.child_entity_data_ids
            .length
        ) {
          return this.getParentEntityNestedRelationshipData
            .child_entity_data_ids;
        }
      }
      return [];
    },
    addBulkUpdateRow() {
      this.bulkUpdateData.push({
        field: "",
        value: "",
      });
    },
    removeBulkUpdateRow(index) {
      this.bulkUpdateData.splice(index, 1);
    },
    async getEntityFieldOptions(template_id, key) {
      let field = this.currentEntity?.templates?.find((e) => e?.templateInfo._id == template_id)
        .templateInfo.sections[0]?.fields?.find((e) => e.key == key);
      await this.$store.dispatch("entities/fetchEntityRecordsForTable", {
        entity_id: field.entity_id,
        limit: 500,
        page: this.bulkUpdateEntityFieldPage,
      });
      if (
        this.getEntityRecordsForTable &&
        this.getEntityRecordsForTable.length
      ) {
        this.bulkUpdateEntityFieldsData[field.entity_id] = [
          ...this.bulkUpdateEntityFieldsData[field.entity_id],
          ...this.getEntityRecordsForTable,
        ];
      }
    },
    async updateRow(row) {
      this.loading = true;
      await Promise.all(
        this.currentEntity?.templates.map(async (temp) => {
          const hasQuickUpdateData =
            this.quickUpdateRowData[temp.template_id] &&
            typeof this.quickUpdateRowData[temp.template_id] === "object" &&
            !Object.values(this.quickUpdateRowData[temp.template_id]).every(
              (value) => value == null || value === ""
            );

          if (
            (hasQuickUpdateData &&
              !isEqual(
                row.entityData[temp.template_id],
                this.quickUpdateRowData[temp.template_id]
              )) ||
            this.isEmailUpdate
          ) {
            this.loadingText = "Checking duplicate data...";
            await this.$store.dispatch("templatesData/checkDuplicateData", {
              data: [{
                form: this.quickUpdateRowData[temp.template_id],
                template_id: temp.template_id,
              },],
              entity_id: this.entity_id,
              parent_entityDataId: row._id || null,
            });
            if (
              this.getDuplicateDataCheck &&
              !this.getDuplicateDataCheck?.allowed
            ) {
              this.$notify.warning({
                title: "Warning",
                message: "Duplicate data found",
              });
              this.loading = false;
              this.loadingText = "Loading...";
              return;
            }
            this.loadingText = "Updating data...";
            const params = {
              template_data: this.quickUpdateRowData[temp.template_id],
              entity_data_id: row._id,
              template_id: temp.template_id,
              entity_id: this.entity_id,
              ignore_tables: true,
            };
            const response = await postAPICall(
              "PATCH",
              `templates-data/${params.entity_data_id}/${params.template_id}`,
              params
            );
            await this.updateRelationshipsData(
              response.updated_data,
              temp.templateInfo,
              this.relationshipsData
            );
          }
        })
      );
      this.quickUpdateIndex = -1;
      this.loading = false;
      await this.fetchEntitiesDataForTable();
    },
    closeDialog() {
      this.matchDialogVisible = false;
    },
    showError() {
      if (
        (this.isApplicationUserSide && !this.currentMenu) ||
        this.forbiddenAccess
      ) {
        this.isShowError = true;
        return true;
      }
      this.isShowError = false;
      return false;
    },
    openFiltersCollapse() {
      this.isFilterOpen = !this.isFilterOpen;
    },
    async duplicateEntityData(row) {
      this.loading = true;
      let data = {
        entity_id: this.entity_id,
        existed_data_id: row._id,
        current_date: this.getCurrentDate,
      };
      await this.$store.dispatch("templatesData/duplicateEntityData", data);
      if (this.getDuplicateEntityData?._id) {
        this.$notify.success({
          title: "Success",
          message: "Data duplicated successfully",
        });
        this.loading = false;
        this.fetchEntitiesDataForTable();
      } else {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: "Error while duplicating " +
            (this.currentEntity && this.currentEntity.name ?
              this.currentEntity.name :
              "data"),
        });
      }
    },
    searchDocumentName() {
      this.filteredDocuments = this.documentsData.filter((document) => {
        return document.name
          .toLowerCase()
          .includes(this.searchDocument.toLowerCase());
      });
    },
    stepNavigation(step) {
      if (this.is_active > step) {
        if (this.is_active == 2) {
          if (step == 1) {
            this.foundArray = [];
            this.preparedData = [];
          } else if (step == 0) {
            this.mappedFileName = "";
            this.existedField = "";
            this.uploadedFileObject = null;
            this.mapped_fields = [];
            this.unmapped_fields = [];
            this.foundArray = [];
            this.preparedData = [];
            this.is_excelUploaded = false;
          }
        } else if (this.is_active == 1) {
          this.mappedFileName = "";
          this.existedField = "";
          this.uploadedFileObject = null;
          this.mapped_fields = [];
          this.unmapped_fields = [];
          this.is_excelUploaded = false;
        }
        this.is_active = step;
      } else {
        this.$message.warning("You can't go to that step");
      }
    },
    checkClearFileOrNot() {
      if (this.mappedFileName == "" || this.existedField == "") {
        this.is_excelUploaded = false;
      }
    },
    async onPreview(data) {
      let fileName = data.name;
      fileName = await fileName.replace(/\//g, "-");
      fileName = await fileName.replace(/ /g, "_");
      fileName = await fileName.replace(/[()]/g, "");
      if (data && !data.path) {
        data.path = `template-data-documents/${fileName}`;
      }
      await this.$store.dispatch("templatesData/downloadTemplateDocument", {
        path: data.path,
      });
      let newWindow = open(
        this.getDownloadUrl,
        "example",
        "width=1500,height=900"
      );
      newWindow.focus();
      newWindow.onload = function () { };
    },
    async onDownloadFile(data) {
      this.loading = true;
      this.loadingText = "Downloading File....";
      let fileName = data.name;
      fileName = await fileName.replace(/\//g, "-");
      fileName = await fileName.replace(/ /g, "_");
      fileName = await fileName.replace(/[()]/g, "");
      if (data && !data.path) {
        data.path = `template-data-documents/${fileName}`;
      }
      await this.$store.dispatch("templatesData/downloadTemplateDocument", {
        path: data.path,
      });
      await fetch(this.getDownloadUrl, {
        method: "GET",
        responseType: "blob",
      })
        .then((res) => res.blob())
        .then((response) => {
          var fileURL = window.URL.createObjectURL(response);
          const link = document.createElement("a"); // Create an anchor tag
          link.href = fileURL; // Set the URL as the href attribute
          link.download = fileName; // Set the desired filename
          document.body.appendChild(link); // Append the anchor tag to the document body
          link.click(); // Simulate a click on the anchor tag to initiate the download
          document.body.removeChild(link);
          this.loading = false;
        });
      if (this.getDownloadUrl) {
        // window.open(this.getDownloadUrl, "_blank");
        // this.$refs.this.getDownloadUrl.click();
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getDownloadError,
        });
      }
      this.loading = false;
      this.loadingText = "Loading...";
    },
    async headerDragged(newWidth, oldWidth, column) {
      if (
        this.getCurrentFilter?.columns_settings &&
        this.getCurrentFilter.columns_settings.length
      ) {
        const columnSetting = this.getCurrentFilter.columns_settings;
        let resizedField = columnSetting.map((e) =>
          e.label == column.label ? { ...e, width: newWidth } : e
        );
        await this.$store.dispatch("filters/updateFilter", {
          id: this.getCurrentFilter._id,
          params: {
            columns_settings: resizedField,
          },
        });
      } else if (
        this.currentEntity.settings &&
        this.currentEntity.settings.length
      ) {
        let entity = this.currentEntity;
        let draggedField = entity.settings.find((e) => e.label == column.label);
        let draggedFieldIndex = 0;
        let settings = entity.settings.filter((e) => {
          if (e.label !== draggedField.label) {
            return e;
          } else {
            draggedFieldIndex = entity.settings.indexOf(e);
          }
        });
        draggedField["width"] = newWidth;
        settings.splice(draggedFieldIndex, 0, draggedField);
        let params = {
          name: entity.name,
          description: entity.description,
          entity_type: entity.entity_type,
          templates: entity.templates,
          id: entity._id,
          primaryFields: entity.primaryFields,
          settings: settings,
        };
        await this.$store.dispatch("entities/updateEntityById", params);
      }
    },
    cellDoubleClicked(row) {
      if (this.checkUrlType() == "SignIn") {
        if (!this.checkPerimission("ROW DATA EDIT") ||
          !this.checkRolePermission("editEntitiesData")
        ) {
          this.$message({
            message: "You don't have permission to edit the data",
            type: "warning",
          });
          return;
        }
        if (row.status == "ARCHIVED") {
          this.$message({
            message: "Can't update archived data. Please restore it.",
            type: "warning",
          });
          return;
        }
        this.quickUpdateIndex = this.data.findIndex(
          (e) => e._id.toString() == row._id.toString()
        );
        this.quickUpdateRowData = null;
        this.quickUpdateRowData = JSON.parse(JSON.stringify(row.entityData));
        if (
          this.currentEntity?.templates.length !== Object.keys(row.entityData)
        ) {
          this.currentEntity?.templates.map((tmp) => {
            if (!Object.keys(this.quickUpdateRowData).includes(tmp.template_id)) {
              let emptyTemplateDataObj = {};
              tmp.templateInfo.sections[0].fields.map((fd) => {
                emptyTemplateDataObj[fd.key] = "";
              });
              this.quickUpdateRowData[tmp.template_id] = emptyTemplateDataObj;
            }
          });
        }
      }
    },
    checkPermission(access) {
      if (!this.entityFiltersData?.permissions?.access_set?.length) {
        return true;
      }
      return this.entityFiltersData?.permissions?.access_set?.includes(access);
    },
    downloadDocumentsPopup(row, step) {
      this.documentsData =
        row.entityData[step.template_id][step.id].files &&
          row.entityData[step.template_id][step.id].files.length ?
          row.entityData[step.template_id][step.id].files :
          row.entityData[step.template_id][step.id];
      this.downloadDocuments = true;
      //this.downloadDocuments = true
    },
    closeDownloadDocuments() {
      this.downloadDocuments = false;
    },
    headerCellStyle() {
      return {
        backgroundColor: "#F2F6FC",
        color: "#606266",
        fontWeight: "bold",
      };
    },
    getFieldsForBulkUpdate() {
      let templates = this.currentEntity?.templates ?
        JSON.parse(JSON.stringify(this.currentEntity?.templates)) :
        [];
      if (this.isApplicationUserSide) {
        let templateIds = [];
        let entityId;
        if (this.currentMenu && this.currentMenu.entity_id) {
          entityId = this.currentMenu.entity_id._id ?
            this.currentMenu.entity_id._id :
            this.currentMenu.entity_id;
          if (
            entityId &&
            this.getEntityPermission &&
            this.getEntityPermission.other_entities &&
            this.getEntityPermission.other_entities[entityId] &&
            this.getEntityPermission.other_entities[entityId].templates
          ) {
            Object.keys(
              this.getEntityPermission.other_entities[entityId].templates
            ).forEach((e) => {
              if (
                this.getEntityPermission.other_entities[entityId].templates[
                  e
                ].includes("BULK UPDATE")
              ) {
                templateIds.push(e);
              }
            });
          }
        } else if (this.currentMenu && this.currentMenu.relational_entity) {
          entityId = this.currentMenu.relational_entity._id ?
            this.currentMenu.relational_entity._id :
            this.currentMenu.relational_entity;
          if (
            entityId &&
            this.getEntityPermission &&
            this.getEntityPermission.relational_entities &&
            this.getEntityPermission.relational_entities[entityId] &&
            this.getEntityPermission.relational_entities[entityId].templates
          ) {
            Object.keys(
              this.getEntityPermission.relational_entities[entityId].templates
            ).forEach((e) => {
              if (
                this.getEntityPermission.relational_entities[
                  entityId
                ].templates[e].includes("BULK UPDATE")
              ) {
                templateIds.push(e);
              }
            });
          }
        }
        let indexesToBeDeleted = [];
        templates.forEach((temp) => {
          if (!templateIds.includes(temp.templateInfo._id.toString())) {
            indexesToBeDeleted.push(templates.indexOf(temp));
          }
        });
        indexesToBeDeleted.sort((a, b) => b - a);
        indexesToBeDeleted.forEach((num) => {
          templates.splice(num, 1);
        });
      }
      this.selectAndEntityFields = [];
      this.bulkUpdateFields = [];
      let selectFields = [];
      let entityFields = [];
      let multiSelectFields = [];
      let checkboxFields = [];
      templates.forEach((template) => {
        (template?.templateInfo?.sections?.[0]?.fields || []).map((fd) => {
          if (fd?.key.includes("#")) {
            fd.key = fd.key.split("#")[1];
          }
        });
        let select = template?.templateInfo?.sections[0]?.fields.filter(
          (e) =>
            e.input_type === "SELECT" && e.properties.filed_content !== "Hide"
        );
        let entity = template?.templateInfo?.sections[0]?.fields.filter(
          (e) =>
            e.input_type === "ENTITY" && e.properties.filed_content !== "Hide"
        );
        let multiSelect = template?.templateInfo?.sections[0]?.fields.filter(
          (e) =>
            e.input_type === "MULTI_SELECT" &&
            e.properties.filed_content !== "Hide"
        );
        let checkbox = template?.templateInfo?.sections[0]?.fields.filter(
          (e) =>
            e.input_type === "CHECKBOX" && e.properties.filed_content !== "Hide"
        );
        select.forEach((e) => {
          e["template_id"] = template.template_id;
        });
        entity.forEach((e) => {
          e["template_id"] = template.template_id;
        });
        multiSelect.forEach((e) => {
          e["template_id"] = template.template_id;
        });
        checkbox.forEach((e) => {
          e["template_id"] = template.template_id;
        });
        selectFields = [...selectFields, ...select];
        entityFields = [...entityFields, ...entity];
        multiSelectFields = [...multiSelectFields, ...multiSelect];
        checkboxFields = [...checkboxFields, ...checkbox];
      });
      if (selectFields && selectFields.length) {
        this.bulkUpdateFields.push({
          label: "Select",
          options: selectFields,
        });
      }
      if (multiSelectFields && multiSelectFields.length) {
        this.bulkUpdateFields.push({
          label: "Multi Select",
          options: multiSelectFields,
        });
      }
      if (entityFields && entityFields.length) {
        this.bulkUpdateFields.push({
          label: "Entity",
          options: entityFields,
        });
      }
      if (checkboxFields && checkboxFields.length) {
        this.bulkUpdateFields.push({
          label: "Checkbox",
          options: checkboxFields,
        });
      }
      this.selectAndEntityFields = [
        ...selectFields,
        ...entityFields,
        ...multiSelectFields,
        ...checkboxFields,
      ].map((f) => {
        if (f?.key && f.key.includes("#")) {
          f.key = f.key.split("#")[1];
        }
        return f;
      });
      this.bulkUpdateDialgVisible = true;
    },
    async fetchValuesForSelectField(updateField) {
      this.optionsLoading = true;
      this.bulkUpdateFieldValuesMapping[updateField] = [];
      let field = this.selectAndEntityFields.find(
        (e) => e._id == updateField.split("#")[0]
      );
      if (
        field?.input_type == "SELECT" ||
        field?.input_type == "MULTI_SELECT"
      ) {
        let options = field.options;
        if (field?.is_global_variable && field?.global_variable_id) {
          let globalVariable = await this.setGlobalVariableData(
            field.global_variable_id
          );
          options = globalVariable?.options;
        }
        this.bulkUpdateFieldValuesMapping[updateField] = options.map(
          (option) => {
            return {
              label: option,
              value: option,
            };
          }
        );
      } else if (field?.input_type == "ENTITY") {
        if (!field.primary_fields.length &&
          !this.entityMapping[field.entity_id]
        ) {
          this.entityMapping[field.entity_id] = await fetchEntityById(
            field.entity_id
          );
        }
        if (!this.entityDataMapping[field.entity_id]) {
          let response = await postAPICall(
            "POST",
            "/entities-data/entity/data", { entity_id: field.entity_id }
          );
          if (response?.data) {
            this.entityDataMapping[field.entity_id] = [...response.data];
          }
        }
        this.bulkUpdateFieldValuesMapping[updateField] = (
          this.entityDataMapping[field.entity_id] || []
        ).map((record) => {
          let label;
          if (field.primary_fields.length) {
            label = "";
            (field.primary_fields || []).map((pf) => {
              let [t, k] = pf.split("#");
              label =
                label + (label == "" ? "" : " - ") + record.entityData[t][k];
            });
            return {
              label: label,
              value: label + "#" + record._id,
            };
          } else {
            label =
              record.entityData[
              this.entityMapping[field.entity_id].primaryFields[0].template_id
              ][this.entityMapping[field.entity_id].primaryFields[0].key];
            return {
              label: label,
              value: label + "#" + record._id,
            };
          }
        });
      } else if (field?.input_type == "CHECKBOX") {
        this.bulkUpdateFieldValuesMapping[updateField] = [
          { label: "Checked", value: true },
          { label: "Unchecked", value: false },
        ];
      }
      this.optionsLoading = false;
    },
    async bulkUpdate() {
      let selectedIds = this.selectedRowsIds.map((row) => {
        return row._id;
      });

      let params = {
        data: this.bulkUpdateData,
        ids: selectedIds,
        entity_id: this.entity_id,
      };
      await this.$store.dispatch("entities/bulkUpdateEntityData", params);
      this.bulkUpdateDialgVisible = false;
      if (this.getBulkUpdateStatus) {
        this.fetchEntitiesDataForTable();
        this.selectedRowsIds = [];
        this.$notify.success({
          title: "Success",
          message: "Your data updated successfully",
        });
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error in updating  the data",
        });
      }
      this.bulkUpdateData = [{
        field: "",
        value: "",
      },];
      this.bulkUpdateFields = [];
    },
    closeBulkUpdateDialogue() {
      this.bulkUpdateData = [{
        field: "",
        value: "",
      },];
      this.bulkUpdateFields = [];
      this.bulkUpdateDialgVisible = false;
    },
    openCountDialog(data, step) {
      this.countComponent = null;
      this.countComponentFilters = [];
      this.countComponentFilters.push({
        field: this.entityFiltersData.tableFieldGroupBy,
        operator: "in",
        value: [data._id],
        value_type: "",
        value_source: "",
        data_type: "STRING",
        query_type: "AND",
        value_field: "",
        validations: {
          data_type: "",
        },
        data_source: "field_option",
      });
      if (
        this.entityFiltersData.tableFieldSubGroupBy &&
        step &&
        step.includes("?")
      ) {
        this.countComponentFilters.push({
          field: this.entityFiltersData.tableFieldSubGroupBy,
          operator: "in",
          value: [step.split("?")[0]],
          value_type: "",
          value_source: "",
          data_type: "STRING",
          query_type: "AND",
          value_field: "",
          validations: {
            data_type: "",
          },
          data_source: "field_option",
        });
      }
      if (this.applied_filters && this.applied_filters.length) {
        this.countComponentFilters = [
          ...this.countComponentFilters,
          ...this.applied_filters,
        ];
      }
      this.countComponent = {
        component_name: this.currentEntity?.name,
        entity_id: this.currentEntity._id,
      };
      this.showCountDialog = true;
    },
    getCurrencyFormat(value, key) {
      let field = this.getField(key);
      let label = "";
      if (field?.inputType == "CURRENCY") {
        let type = field.validations.currency ?
          field.validations.currency :
          "USD";
        let locale = field.validations.locale ?
          field.validations.locale :
          "en-US";
        label = new Intl.NumberFormat(locale, {
          style: "currency",
          currency: type,
        }).format(value);
      } else if (
        field?.inputType == "AGGREGATE_FUNCTION" ||
        field?.inputType == "FORMULA"
      ) {
        let isCurrencyField = (field.selected_fields || []).find((el) => {
          if (
            el?.inputType == "CURRENCY" ||
            el?.input_type == "CURRENCY" ||
            el?.result_type == "CURRENCY"
          ) {
            return true;
          }
        });
        if (!isCurrencyField &&
          field?.inputType == "FORMULA" &&
          field.result_type == "CURRENCY" &&
          field.selected_fields?.[0]?.validations?.currency
        ) {
          let locale = field.validations?.locale || "en-US";
          return (label = new Intl.NumberFormat(locale, {
            style: "currency",
            currency: field.selected_fields[0].validations.currency,
          }).format(value));
        }
        if (isCurrencyField) {
          if (field?.inputType == "AGGREGATE_FUNCTION") {
            let dataTableField = this.getField(
              field.template_id + "#" + isCurrencyField.data_table_key
            );
            if (dataTableField) {
              let rField = (dataTableField.data_table_columns || []).find(
                (e) => e.key == isCurrencyField.key
              );
              if (rField?.validations) {
                isCurrencyField["validations"] = rField.validations;
              }
            }
          } else {
            if (field?.validations) {
              isCurrencyField["validations"] = field.validations;
            }
          }
          let type =
            isCurrencyField &&
              isCurrencyField.validations &&
              isCurrencyField.validations.currency ?
              isCurrencyField.validations.currency :
              "USD";
          let locale =
            isCurrencyField &&
              isCurrencyField.validations &&
              isCurrencyField.validations.locale ?
              isCurrencyField.validations.locale :
              "en-US";
          label = new Intl.NumberFormat(locale, {
            style: "currency",
            currency: type,
          }).format(value);
        } else {
          label = value;
        }
      } else {
        label = value;
      }
      return label;
    },
    getLabel(field) {
      let found = this.getField(field);
      return found && found.label ? found.label : "";
    },
    getCalendarMessage(data, body) {
      let str = "";
      if (data && data.entityData && body?.custom_message?.content) {
        (body?.custom_message?.content || []).forEach((el) => {
          (el?.content || []).forEach((e) => {
            if (e?.type == "text") {
              str = str + e.text;
            } else if (
              e?.type == "mention" &&
              e?.attrs?.id &&
              e.attrs.id.includes("#")
            ) {
              let [slug, key] = e?.attrs?.id.split("#");
              let templates = (this.currentEntity.templates || []).map(
                (temp) => temp.templateInfo
              );
              let temp = (templates || []).find((e) => e.slug == slug);
              if (
                temp?._id &&
                data.entityData[temp._id] &&
                data.entityData[temp._id][key] &&
                temp?.sections[0]?.fields
              ) {
                let field = temp.sections[0].fields.find((e) => e.key == key);
                if (data.entityData[temp._id][key + "/name"]) {
                  str = str + data.entityData[temp._id][key + "/name"];
                } else if (field.inputType == "CURRENCY") {
                  str =
                    str +
                    Intl.NumberFormat(this.getCurrencyFormet(field).code, {
                      style: "currency",
                      currency: this.getCurrencyFormet(field).currency,
                    }).format(data.entityData[temp._id][key]);
                } else {
                  str = str + data.entityData[temp._id][key];
                }
              }
            }
          });
        });
      }
      return str;
    },
    async openSendNotification(entityData) {
      this.selectedNotifyEntity = entityData;
      this.sendNotificationDialog = true;
      this.sendWANotificationDialog = false;
    },
    async openWASendNotification(entityData) {
      this.selectedNotifyEntity = entityData;
      this.sendNotificationDialog = false;
      this.sendWANotificationDialog = true;
    },
    async openScheduleEventModal(entityData) {
      let currentEntityData = entityData
      let currentEntity = this.currentEntity
      this.$eventBus.$emit("showCalendarEvent", {
        source: "entity",
        entityId: currentEntity._id,
        entityDataId: currentEntityData._id,
      });
    },
    async openBulkSendNotification() {
      this.selectedIds = this.selectedRowsIds.map((row) => {
        return row._id;
      });
      this.bulkSendNotificationDialog = true;
      this.bulkSendNotificationWADialog = false;
    },
    async openBulkWASendNotification() {
      this.selectedIds = this.selectedRowsIds.map((row) => {
        return row._id;
      });
      this.bulkSendNotificationDialog = false;
      this.bulkSendNotificationWADialog = true;
    },

    generateShowFields(entity) {
      if (entity?.templates) {
        if (entity?.entity_type == "INDIVIDUAL") {
          let standardTemp = entity?.templates.find(
            (e) => e && e?.templateInfo?.type == "STANDARD"
          );
          this.showFieldsParent.profile = "defaultpic";
          if (standardTemp?.template_id) {
            this.showFieldsParent.title =
              standardTemp.template_id + "#" + "name";
            this.showFieldsParent.description =
              standardTemp.template_id + "#" + "title";
          }
        } else {
          entity?.templates.forEach((temp) => {
            if (temp?.templateInfo && !temp?.is_repeatable) {
              if (!this.showFieldsParent.profile) {
                let field = (
                  this.getTemplateFields(temp.templateInfo) || []
                ).find((el) => el.inputType == "IMAGE");
                if (field?.key) {
                  this.showFieldsParent.profile = field.key;
                }
              }
              if (!this.showFieldsParent.description) {
                let field = (
                  this.getTemplateFields(temp.templateInfo) || []
                ).find((el) => el.inputType == "MULTI_LINE_TEXT");
                if (field?.key) {
                  this.showFieldsParent.description = field.key;
                }
              }
            }
          });
          if (!this.showFieldsParent.title &&
            entity.primaryFields &&
            entity.primaryFields[0]
          ) {
            this.showFieldsParent.title =
              entity.primaryFields[0].template_id +
              "#" +
              entity.primaryFields[0].key;
          }
        }
        entity?.templates.forEach((temp) => {
          if (!this.showFieldsParent.chart_field) {
            let field = (this.getTemplateFields(temp.templateInfo) || []).find(
              (el) => ["SELECT", "YES_OR_NO", "MULTI_SELECT"].indexOf(el.inputType) !=
                -1
            );
            if (field?.key) {
              this.showFieldsParent.chart_field = field.key;
            }
          }
          if (!this.showFieldsParent.board_field) {
            let field = (this.getTemplateFields(temp.templateInfo) || []).find(
              (el) => ["SELECT", "YES_OR_NO", "MULTI_SELECT"].indexOf(el.inputType) !=
                -1
            );
            if (field?.key) {
              this.showFieldsParent.board_field = field.key;
            }
          }
        });
      }
    },
    getEntityFieldsByType(types) {
      let results = [];
      (this.currentEntity && this.currentEntity.templates ?
        this.currentEntity.templates :
        []
      ).forEach((temp) => {
        if (temp?.templateInfo?.sections && !temp.is_repeatable) {
          if (types && types.length) {
            results = [
              ...results,
              ...temp.templateInfo.sections[0].fields
                .filter((e) => types.indexOf(e.inputType) != -1)
                .map((el) => {
                  let key = el.key;
                  if (key && !key.includes("#")) {
                    key = temp.template_id + "#" + key;
                  }
                  el.key = this.normalizeFieldKey(key);
                  return el;
                }),
            ];
          } else {
            results = [
              ...results,
              ...temp.templateInfo.sections[0].fields.map((el) => {
                let key = el.key;
                if (key && !key.includes("#")) {
                  key = temp.template_id + "#" + key;
                }
                el.key = this.normalizeFieldKey(key);
                return el;
              }),
            ];
          }
        }
      });
      if (
        types &&
        types.includes("IMAGE") &&
        this.currentEntity?.entity_type == "INDIVIDUAL"
      ) {
        results.push({
          label: "Default picture",
          key: "defaultpic",
        });
      }
      return results;
    },
    async checkForFilterUpdate() {
      this.entityViewModal = false;
      this.filterColumList = this.filterColumList.filter(
        (e) => e.id && e.template_id
      );
      if (this.isApplicationUserSide) {
        let data = this.getEntityShowFields || {};
        if (data) {
          data[this.currentEntity._id] = { ...this.showFieldsParent };
        }
        await this.$store.commit("entities/setEntityShowFields", data, {
          root: true,
        });
      } else if (this.currentFilter) {
        await this.$store.dispatch("filters/updateFilter", {
          id: this.currentFilter,
          params: {
            columns_settings: this.filterColumList,
            email_template_id: this.getSelectedEmailTemplate(),
          },
        });
      } else {
        await this.$store.dispatch("entities/updateEntityById", {
          settings: this.filterColumList,
          name: this.currentEntity.name,
          description: this.currentEntity.description,
          entity_type: this.currentEntity.entity_type,
          templates: this.currentEntity.templates,
          id: this.currentEntity._id,
          primaryFields: this.currentEntity.primaryFields,
          views_configuration: this.showFieldsParent,
          viewType: this.activeLayout,
        });
      }
    },
    selectAlltemplates(temp) {
      let fields = this.getTemplateFields(temp.templateInfo);
      if (fields && fields.length) {
        fields.forEach((field) => {
          if (field) {
            let key;
            if (field?.key && field.key.includes("#")) {
              key = field.key.split("#")[1];
            }
            let index = this.filterColumList.findIndex(
              (e) => e.id == key && e.template_id == field.template_id
            );
            if (index == -1) {
              this.filterColumList.push({
                id: key,
                label: field.label,
                template_id: field.template_id,
                type: field.inputType,
              });
            }
          }
        });
      }
    },
    checkfieldVisible(data, template_id) {
      let field = { ...data, ...{ template_id: template_id } };
      if (field && field.key) {
        let key, found;
        if (field.key.includes("#")) {
          key = field.key.split("#")[1];
        } else {
          key = field.key;
        }
        if (this.getFilterColumList && this.getFilterColumList.length) {
          found = this.getFilterColumList.find((e) => {
            if (e.id && e.id.includes("#")) {
              e.id = e.id.split("#")[1];
            }
            if (e.id == key && e.template_id == field.template_id) {
              return true;
            }
          });
        }
        return found ? true : false;
      }
      return false;
    },
    selectField(data, template_id) {
      let field = { ...data, ...{ template_id: template_id } };
      let existed = this.checkfieldVisible(field, template_id);
      let key;
      if (field.key.includes("#")) {
        key = field.key.split("#")[1];
      } else {
        key = field.key;
      }
      if (existed) {
        let index = this.filterColumList.findIndex(
          (e) => e.id == key && e.template_id == field.template_id
        );
        if (index > -1) {
          this.filterColumList.splice(index, 1);
        }
      } else {
        this.filterColumList.push({
          id: key,
          label: field.label,
          template_id: field.template_id,
          type: field.inputType,
        });
      }
    },
    async activeLayout(layout) {
      if (this.activeEntityView !== layout) {
        try {
          this.activeEntityView = layout;

          let query = await this.getNavigationQuery(this.$route.query);
          query["viewType"] = layout;

          await this.$router.push({
            name: this.$route.name,
            query: query,
          });
          let entity = this.currentEntity;
          let params = {
            id: entity._id,
            name: entity.name,
            description: entity.description,
            entity_type: entity.entity_type,
            templates: entity.templates,
            primaryFields: entity.primaryFields,
            settings: entity.settings,
            viewType: layout,
          };
          if (layout == "HTML_CONTENT" && this.getSelectedEmailTemplate()) {
            params["selected_email_template"] = this.getSelectedEmailTemplate();
            params["columns_per_row"] = this.showFieldsParent.columnsPerRow;
            params["emailView"] = this.showFieldsParent.emailView;
          }
          await this.$store.dispatch("entities/updateEntityById", params);
          if (layout == "HTML_CONTENT") this.fetchEntitiesDataForTable([]);
        } catch (error) {
          console.error("Error updating layout:", error);
        }
      }
    },
    getSelectedEmailTemplate() {
      let emailTemplateId;
      if (this.showFieldsParent.selected_email_template) {
        emailTemplateId = this.showFieldsParent.selected_email_template;
      } else if (this.$route?.query?.filter) {
        let selectedFilter = this.entityAllFilters.find(
          (e) => e && e._id == this.$route?.query?.filter
        );
        if (selectedFilter?.email_template_id) {
          emailTemplateId = selectedFilter.email_template_id;
        }
      } else if (this.allEmailTemplates?.length) {
        emailTemplateId = this.allEmailTemplates[0]._id;
      }
      return emailTemplateId;
    },

    searchEntityData() {
      if (
        this.activeEntityView == "TABLE" ||
        this.activeEntityView == "HTML_CONTENT"
      ) {
        this.fetchEntitiesDataForTable([]);
      }
    },
    resetSearch() {
      this.search_string = "";
      this.fetchEntitiesDataForTable([]);
    },
    changeEntityView(view) {
      this.activeEntityView = view;
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route?.query, ...{ viewType: view } },
      });
    },
    //entity data edit code in import
    getPropValue(step) {
      if (step.type == "ENTITY") {
        return step.template_id + "#" + step.id + "/name";
      }
      return step.template_id + "#" + step.id;
    },
    checkFieldDisabled(data) {
      let existedField = this.mapped_fields.find(
        (e) =>
          e.template_id == data.template_id &&
          e.template_filed_id == e.template_filed_id &&
          e.keyIndex != data.keyIndex
      );
      if (existedField) {
        return true;
      }
      return false;
    },
    downloadExcelFromJson(csvData, fileName) {
      const worksheet = XLSX.utils.json_to_sheet(csvData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();

      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }, 0);
    },

    downloadExcelFile() {
      let templateFields = [];
      if (this.current_entity_tempates) {
        this.current_entity_tempates.forEach((temp) => {
          if (temp && temp.is_repeatable === false) {
            if (temp.sections && temp.sections[0] && temp.sections[0].fields) {
              temp.sections[0].fields.forEach((fi) => {
                templateFields.push(fi.label);
              });
            }
          }
        });
      }
      this.gotoDownload(templateFields);
    },
    gotoDownload(data) {
      const headers = data;
      const worksheet = XLSX.utils.json_to_sheet([], { header: headers });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = this.currentEntity.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      // const a = document.createElement("a");
      // a.setAttribute("hidden", "");
      // a.setAttribute("href", url);
      // a.setAttribute("download", `filename.xlsx`);
      // document.body.appendChild(a);
      // a.click();
      // document.body.removeChild(a);
    },
    clearImportData() {
      this.mapped_fields = [];
      this.unmapped_fields = [];
      this.getmappedFieldsData = [];
      this.uploadedFileObject = null;
      this.is_excelUploaded = false;
      this.is_active = 0;
      this.mappedFileName = "";
      this.existedField = "";
      this.matchDialogVisible = true;
    },
    async displayimportdialog() {
      let params = {
        id: this.entity_id,
      };
      this.loading = true;
      await this.$store.dispatch("entities/getuploadedFiles", params);
      if (this.getuploadedFilesData && this.getuploadedFilesData.data) {
        this.uploadedfileDataNames = this.getuploadedFilesData.data;
      }
      this.clearImportData();
      this.loading = false;
    },
    editMappedFileds() {
      this.is_active = this.is_active - 1;
      this.importedData = this.mappedData;
    },
    updateField(selectedField) {
      if (selectedField.template_id && selectedField.template_filed_id) {
        let index = this.unmapped_fields.findIndex(
          (e) => e.keyIndex === selectedField.keyIndex
        );
        let item = this.unmapped_fields[index];
        this.mapped_fields.push(item);
        this.unmapped_fields.splice(index, 1);
      }
    },
    updateIgnoredRow(checkedField) {
      if (checkedField.ignore === true) {
        let index = this.mapped_fields.findIndex(
          (e) => e.keyIndex === checkedField.keyIndex
        );
        let item = this.mapped_fields[index];
        item.template_id = "";
        item.template_filed_id = "";
        this.unmapped_fields.push(item);
        this.mapped_fields.splice(index, 1);
      }
      // if(checkedField.ignore===true){

      //   let item=this.mapped_fields[index];
      //   item.ignore=true;
      //   item.template_filed_id="";
      //   item.template_id="";
      //   this.unmapped_fields.push(item);
      //   this.mapped_fields.splice(index,1);
      // }
    },
    async downloadGroupbyData() {
      let filters = this.applied_filters;
      if (
        this.currentMenu?.entity_type == "RELATIONAL_ENTITY" &&
        this.isApplicationUserSide
      ) {
        filters = [...filters, ...this.getSelfEntityFilters];
      }
      if (!this.entityFiltersData && this.$route.query.filter) {
        this.resetEntityFiltersData();
      }
      filters = [...this.entityFiltersData.filters, ...filters];
      this.exportFilters = filters;
      let checkDataTable = this.filterColumList.find(
        (e) => e.type == "DATA_TABLE"
      );
      let params = {
        entity_id: this.entity_id,
        template_fields_data: (this.currentEntity &&
          this.currentEntity.templates ?
          this.currentEntity.templates :
          []
        ).map((template) => {
          return {
            template_id: template.template_id,
          };
        }),
        filters: this.mapDateFilters(
          this.addGlobalFilters(
            filters || [],
            this.entity_id,
            this.checkGlobalFilter
          )
        ),
        sortBy: this.sortBy,
        sortOrder: this.sortOrder,
        search_string: this.search_string,
        data_table_field: checkDataTable,
      };
      if (this.entityFiltersData?.tableFieldGroupBy) {
        let subField = this.getField(this.entityFiltersData.tableFieldGroupBy);
        if (subField?.inputType == "MULTI_SELECT") {
          params.unwind = true;
        }
        params.group_by = this.entityFiltersData.tableFieldGroupBy;
        params.group_by_fields = this.entityFiltersData.tableFieldGroupByFields;
        if (this.entityFiltersData?.tableFieldSubGroupBy) {
          params.sub_group_by = this.entityFiltersData?.tableFieldSubGroupBy;
        } else {
          params.entity_variable_columns =
            this.entityFiltersData.filterEntityVariableColumns;
          if (
            this.getField(this.entityFiltersData.tableFieldGroupBy)?.entity_id
          ) {
            params.filter_variable_entity = this.getField(
              this.entityFiltersData.tableFieldGroupBy
            ).entity_id;
          }
        }
      }
      await this.$store.dispatch("entities/fetchEntityRecordsForTable", params);
      let groupedData = [],
        csvData = [];
      if (this.entityFiltersData?.tableFieldGroupBy) {
        if (this.entityFiltersData?.tableFieldSubGroupBy) {
          let custom_columns = [];
          groupedData = this.getEntityRecordsForTable.data.map((data) => {
            if (data?.properties) {
              let label = "",
                total = 0;
              (data.properties || []).forEach((el) => {
                if (!label) {
                  label = el.entity_label;
                }
                total = total + el.count;
                let value = el.sub_entity_label ?
                  el.sub_entity_label :
                  el.label;
                let index = custom_columns.findIndex(
                  (e) => e.value == el.label
                );
                if (index == -1 && value) {
                  custom_columns.push({
                    value: el.label,
                    label: value,
                  });
                }
                (Object.keys(el) || []).forEach((key) => {
                  if (
                    ["entity_label", "label", "sub_entity_label"].indexOf(
                      key
                    ) == -1
                  ) {
                    data[el.label + "?" + key] = el[key];
                    data[value + "?" + key] = el[key];
                  }
                });
              });

              data.entity_label = label;
              data.count = total;
            }
            return data;
          });
          if (
            this.entityFiltersData?.table_field_group_by_selected_options &&
            this.entityFiltersData.table_field_group_by_selected_options.length
          ) {
            custom_columns = custom_columns.filter(
              (e) =>
                this.entityFiltersData.table_field_group_by_selected_options.indexOf(
                  e.value
                ) != -1
            );
          }
          this.entityFiltersData.tableFieldGroupByFields
            .filter((e) => e.sub_group)
            .forEach((field) => {
              (custom_columns || []).forEach((col) => {
                this.custom_columns.push({
                  label: col.label + "-" + field.name,
                  value: col.value + "?" + field.key,
                });
              });
            });
        } else {
          groupedData = this.getEntityRecordsForTable.data;
          groupedData = this.groupedData.map((e) => {
            if (e && e.name && e.name.includes("#")) {
              e.name = e.name.split("#")[0];
            }
            return e;
          });
        }
      }
      groupedData.forEach((data) => {
        let obj = {};
        obj[this.getMainLabel] = data.entity_label ?
          data.entity_label :
          data._id;
        this.entityFiltersData.tableFieldGroupByFields
          .filter((e) => !e.sub_group)
          .forEach((step) => {
            obj[step.name] = data[step.key];
          });
        this.custom_columns.forEach((step) => {
          obj[step.label] = this.getSubField(data, step.value);
        });
        csvData.push(obj);
      });
      this.downloadExcelFromJson(csvData, "ExcelData");
    },
    async exportCSV() {
      this.openExportCSVDialogModal = false;
      if (this.entityFiltersData.tableFieldGroupBy) {
        await this.downloadGroupbyData();
        return;
      }
      let params = {
        id: this.entity_id,
        entityName: this.currentEntity.name,
        filters: this.mapDateFilters(this.exportFilters || []),
        dataIds: this.exportDataIds,
        selectedFilter: this.currentFilter,
        selectedExcelTypeForTables: this.selectedTableExcelType,
      };
      this.loadingText = "Generating Excel...";
      this.loading = true;
      await this.$store.dispatch("entities/entityDataExport", params);
      this.loading = false;
      this.loadingText = "Loading...";
    },
    async exportPDF() {
      let params = {
        id: this.entity_id,
        entityName: this.currentEntity.name,
        filters: this.mapDateFilters(this.exportFilters || []),
        selectedFilter: this.currentFilter,
        dataIds: this.exportDataIds,
        document_id: this.currentEntity?.export_printing_document,
      };
      this.loading = true;
      this.loadingText = "Generating PDF...";
      await this.$store.dispatch("entities/entityDataExportPDF", params);
      if (
        this.getEntityDataExportPDFStatus &&
        this.getEntityDataExportPDFStatus.success &&
        this.getEntityDataExportPDFStatus.data.length
      ) {
        await this.downloadEntityDataPdf(
          this.getEntityDataExportPDFStatus.data[0],
          this.currentEntity?.name
        );
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error while generating Document",
        });
      }
      this.loading = false;
      this.loadingText = "Loading...";
    },
    async prepareImportEntityHeaders() {
      //eliminating repeatable templates
      this.entityTempsWithException = [];
      this.current_entity_tempates.forEach((template) => {
        if (template && template.is_repeatable === false) {
          this.entityTempsWithException.push(template);
        }
      });
      let nextBtn = document.getElementById("prepareImportEntityNext");
      nextBtn.style.color = "black";
      if (this.is_excelUploaded === true) {
        this.dialogLoading = true;
        if (this.mappedFileName.length > 0 || this.existedField.length > 0) {
          this.uploadedFileObject.name =
            this.uploadedFileObject.name.includes(".") &&
              this.uploadedFileObject.name.split(".")[0] ?
              this.uploadedFileObject.name.split(".")[0] :
              this.uploadedFileObject.name;
          // var formData = new FormData();
          // formData.append(
          //   "file",
          //   this.uploadedFileObject.raw !== undefined
          //     ? this.uploadedFileObject.raw
          //     : this.uploadedFileObject
          // );
          // let params = {
          //   id: this.entity_id,
          //   data: formData,
          //   fileName: this.mappedFileName
          //     ? this.mappedFileName
          //     : this.existedField,
          // };
          // await this.$store.dispatch("entities/storeUploadedFile", params);
          let params = {
            id: this.entity_id,
            name: this.mappedFileName,
            mappedFileId: this.existedField,
            excelHeaders: this.excelFileHeaders,
          };
          await this.$store.dispatch("entities/uploadCSVfile", params);
          if (
            this.getUploadCSVfileStatus &&
            this.getUploadCSVfileStatus.data &&
            this.getUploadCSVfileStatus.data.mapped_fields
          ) {
            this.is_active++;
            this.dialogLoading = false;
            let mappingFields = this.getUploadCSVfileStatus.data.mapped_fields;
            mappingFields.forEach((e) => {
              let keyIndex = Math.random().toString(36).substring(1, 9);
              e.keyIndex = keyIndex;
            });
            mappingFields.forEach((field) => {
              if (
                field &&
                field.template_filed_id &&
                field.template_id &&
                field.ignore === false
              ) {
                this.mapped_fields.push(field);
              } else {
                this.unmapped_fields.push(field);
              }
              // }
            });
            this.mapped_fields.forEach((f) => {
              let duplicate = this.unmapped_fields.find((e) => {
                if (e.excel_index == f.excel_index) {
                  return true;
                }
              });
              if (duplicate) {
                this.mapped_fields.splice(this.mapped_fields.indexOf(f), 1);
              }
            });
            this.mapped_fields.forEach((field) => {
              let duplicate = this.mapped_fields.find((e) => {
                if (
                  e.excel_index == field.excel_index &&
                  e.excel_field !== field.excel_field
                )
                  return true;
              });
              if (
                duplicate &&
                !this.uploadedfileDataNames[0].mapped_fields.includes(field)
              ) {
                this.mapped_fields.splice(this.mapped_fields.indexOf(field), 1);
              }
            });
          } else {
            this.dialogLoading = true;
          }
        } else {
          this.$notify({
            title: "Error",
            message: "give the name for a file or select a file.",
            type: "error",
          });
          this.dialogLoading = false;
        }
      } else {
        this.$notify({
          title: "Error",
          message: "Please upload the file.",
          type: "error",
        });
      }
    },
    uploadCSVfile(file) {
      this.uploadedFileObject = file;
      let fileNameArray = this.uploadedFileObject.name.split(".");
      fileNameArray.splice(fileNameArray.length - 1, 1);
      let fileName = fileNameArray.join(".");
      if (this.uploadedfileDataNames.length > 0) {
        let foundObject = this.uploadedfileDataNames.find((e) => {
          if (e.name.toLowerCase() == fileName.toLowerCase()) {
            return true;
          }
        });
        if (foundObject && foundObject._id) {
          this.existedField = foundObject._id;
        } else {
          this.mappedFileName = fileName;
        }
      } else {
        this.mappedFileName = fileName;
      }
      this.is_excelUploaded = true;
      this.handleFileUpload(this.uploadedFileObject);
    },
    handleFileUpload(fileData) {
      const file = fileData.raw;
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = Buffer.from(e.target.result);
        const workbook = XLSX.read(data, { type: "array", cellDates: true });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        this.jsonDataOfExcel = this.convertDataToJSON(jsonData);
        this.jsonDataOfExcel.map((e) => {
          Object.keys(e).map((f) => {
            if (moment.isDate(e[f])) {
              e[f] = moment(e[f]).add(10, "seconds");
            }
          });
        });
      };
      reader.onerror = (e) => {
        console.error("File reading error:", e);
      };
      reader.readAsArrayBuffer(file);
    },
    convertDataToJSON(data) {
      const headers = data[0];
      this.excelFileHeaders = headers;
      const jsonData = [];
      for (let i = 1; i < data.length; i++) {
        const row = data[i];
        let item = {};
        let allKeys = [];
        for (let j = 0; j < headers.length; j++) {
          const value = row[j] !== null ? row[j] : "";
          let itemKeys = Object.keys(item);
          allKeys.push(headers[j]);
          if (
            itemKeys &&
            itemKeys.length > 0 &&
            itemKeys.includes(headers[j])
          ) {
            let count =
              allKeys.filter((element) => element === headers[j]).length - 1;
            headers[j] = headers[j] + "_" + count;
            item[headers[j]] = value;
          } else {
            item[headers[j]] = value;
          }
        }
        jsonData.push(item);
      }
      return jsonData;
    },
    async gotoDataPreviewScreen() {
      // let primaryFields =
      let nextBtn = document.getElementById("gotoDataPreviewScreenNext");
      nextBtn.style.color = "black";
      let unmappedFields = (this.currentEntity.primaryFields || []).filter(
        (e) => {
          let mapped = this.mapped_fields.find((fl) => {
            let key =
              fl?.template_filed_id &&
                fl.template_filed_id.includes("#") &&
                fl.template_filed_id.split("#")[1] ?
                fl.template_filed_id.split("#")[1] :
                fl.template_filed_id;
            if (key == e.key && fl.template_id == e.template_id) {
              return true;
            }
          });
          if (!mapped) {
            return true;
          }
        }
      );
      let allMappedFields = [];
      this.mapped_fields.forEach((e) => {
        allMappedFields.push(e.template_filed_id);
      });
      let allUnmappedFields = [];
      unmappedFields.forEach((e) => {
        allUnmappedFields.push(e.key);
      });
      let requiredFields = [];
      this.currentEntity.templates.map((e) => {
        if (e.templateInfo.type != "STANDARD") {
          let req = e.templateInfo.sections[0].fields.filter(
            (f) => f && f.validations && f.validations.required
          );
          requiredFields = [...requiredFields, ...req];
        }
      });
      if (allMappedFields && allMappedFields.length) {
        let requiredFieldsMapped = requiredFields
          .map((e) => e.key)
          .every((e) => allMappedFields.includes(e));
        if (requiredFieldsMapped == false) {
          this.$notify({
            title: "Error",
            message: "Please map all the required fields",
            type: "error",
          });
          return;
        }
      }
      if (this.currentEntity.entity_type == "INDIVIDUAL") {
        let firstLastNameMapped = ["first_name", "last_name"].every((field) =>
          allMappedFields.includes(field)
        );
        if (!allMappedFields.includes("name") && !firstLastNameMapped) {
          this.$notify.error({
            title: "Error",
            message: "First name and Last name Or Name should be mapped",
          });
          return;
        }
        if (!allMappedFields.includes("email")) {
          this.$notify.error({
            title: "Error",
            message: "Email should be mapped",
          });
          return;
        }
      }
      if (unmappedFields && unmappedFields.length) {
        if (
          unmappedFields.length === 1 &&
          unmappedFields[0].key === "name" &&
          allMappedFields.includes("first_name") &&
          allMappedFields.includes("last_name")
        ) {
          /* unmappedFields */
        } else if (
          (unmappedFields.length === 1 || unmappedFields.length === 2) &&
          (allUnmappedFields.includes("first_name") ||
            allUnmappedFields.includes("last_name")) &&
          allMappedFields.includes("name")
        ) {
          /* unmappedFields */
        } else {
          let keys = unmappedFields.flatMap((e) => e.label).join(",");
          this.$notify({
            title: "Error",
            message: `Primary fields must be mapped  (${keys})`,
            type: "error",
          });
          return;
        }
      }
      if (this.unmapped_fields.length > 0) {
        this.unmapped_dialog = true;
      } else {
        this.directToPreviewScreen();
      }
    },
    displayUnmappedDialog() {
      this.unmapped_dialog = false;
      this.directToPreviewScreen();
    },
    async directToPreviewScreen() {
      this.dialogLoading = true;
      this.unmapped_fields.forEach((e) => {
        e.ignore = true;
      });
      let params = {
        id: this.getUploadCSVfileStatus.excelFile_id,
        mapped_fields: this.mapped_fields,
      };
      await this.$store.dispatch("entities/updatemappedFields", params);
      if (this.getupdateMappedFieldStatus) {
        this.$notify.success({
          title: this.getupdateMappedFieldStatus.status,
          message: this.getupdateMappedFieldStatus.message,
        });
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error in updating data",
        });
      }
      this.mappedData = this.jsonDataOfExcel;
      this.prepareEntityImportData(
        this.mappedData,
        this.getupdateMappedFieldStatus.data.mapped_fields
      );
      // if (this.getmappedFieldsData) {
      // }
      this.dialogLoading = false;
      this.is_active++;
    },
    checkFieldData(individualFieldValue, foundedField) {
      if (foundedField && individualFieldValue) {
        if (foundedField.inputType == "SELECT") {
          let individualFieldValueLower = individualFieldValue
            .toString()
            .toLowerCase();
          let selectArr = [];
          foundedField.options.forEach((e) => {
            e = e.trim();
            selectArr.push(e.toLowerCase());
          });
          let isExisted = selectArr.includes(individualFieldValueLower.trim());
          if (isExisted) {
            return {
              success: true,
              data: individualFieldValue,
            };
          } else {
            return {
              success: false,
              data: "",
              message: "Entered data not matched",
            };
          }
        } else if (foundedField && foundedField.inputType === "CURRENCY") {
          if (typeof individualFieldValue === "number") {
            return {
              success: true,
              data: individualFieldValue,
            };
          } else {
            return {
              success: false,
              data: "",
              message: "Entered data not matched",
            };
          }
        } else if (foundedField && foundedField.inputType === "MULTI_SELECT") {
          let resultArr = [];
          if (typeof individualFieldValue == "object") {
            if (individualFieldValue.length > -1) {
              resultArr = foundedField.options.filter((e) => {
                return (
                  individualFieldValue
                    .map((el) => el.toLowerCase())
                    .indexOf(e) != -1
                );
              });
            }
          } else {
            if (individualFieldValue.includes(",")) {
              let multiArr = individualFieldValue
                .split(",")
                .map((e) => e.toLowerCase());
              // let smallMultiArr = [];
              resultArr = foundedField.options.filter((op) => {
                if (op && multiArr.indexOf(op.toLowerCase()) != -1) {
                  return op;
                }
              });
            } else {
              let found = foundedField.options.find((e) => {
                if (
                  e &&
                  e.toLowerCase() == individualFieldValue.toLowerCase()
                ) {
                  return e;
                }
              });
              resultArr = found ? [found] : [];
            }
          }
          return {
            success: true,
            data: resultArr,
          };
        } else if (foundedField && foundedField.inputType === "CHECKBOX") {
          if (typeof individualFieldValue === "boolean") {
            return {
              success: true,
              data: individualFieldValue,
            };
          } else {
            return {
              success: false,
              data: false,
              message: "Entered data not matched",
            };
          }
        } else if (
          foundedField &&
          foundedField.inputType === "CHECKBOX_GROUP"
        ) {
          // let options = [];
          // foundedField.options.forEach((e) => {
          //   options.push(e.toLowerCase());
          // });
          let resultArr = [];
          if (typeof individualFieldValue == "object") {
            if (individualFieldValue.length > -1) {
              resultArr = foundedField.options.filter((e) => {
                return (
                  individualFieldValue
                    .map((el) => el.toLowerCase())
                    .indexOf(e) != -1
                );
              });
            }
          } else {
            if (individualFieldValue.includes(",")) {
              let inputArr = individualFieldValue
                .split(",")
                .map((e) => e.toLowerCase());
              resultArr = foundedField.options.filter((op) => {
                if (op && inputArr.indexOf(op.toLowerCase()) != -1) {
                  return op;
                }
              });
            } else {
              let found = foundedField.options.find((e) => {
                if (
                  e &&
                  e.toLowerCase() == individualFieldValue.toLowerCase()
                ) {
                  return e;
                }
              });
              resultArr = found ? [found] : [];
            }
          }
          // else {
          //   let isExisted = options.includes(
          //     individualFieldValue.toLowerCase()
          //   );
          //   if (isExisted) {
          //     resultantArr.push(individualFieldValue.toLowerCase());
          //   }
          // }
          if (
            resultArr.length >= foundedField.min_selection &&
            resultArr.length <= foundedField.max_selection
          ) {
            return {
              success: true,
              data: resultArr,
            };
          } else {
            resultArr = [];
            return {
              success: false,
              data: resultArr,
            };
          }
        } else if (foundedField && foundedField.inputType === "DATE") {
          var parsedDate = Date.parse(individualFieldValue);
          if (isNaN(parsedDate)) {
            return {
              success: false,
              data: "",
              message: "Entered data not matched",
            };
          } else {
            if (typeof individualFieldValue === "number") {
              const d = new Date(
                Math.round((individualFieldValue - 25569) * 86400 * 1000)
              );
              individualFieldValue = d;
            }
            return {
              success: true,
              data: individualFieldValue,
            };
          }
        }
        return {
          success: true,
          data: individualFieldValue,
        };
      }
      return {
        success: false,
        data: "",
        message: "Field is empty",
      };
    },
    async prepareEntityImportData(data, mapped_fields) {
      this.excelFileData = JSON.parse(JSON.stringify(data));
      let entity = this.currentEntity;
      //first part==>field level validations
      this.currentEntityAllFields = [];
      this.successedExcelImportData = [];
      this.errorExcelImportData = [];
      this.foundArray = [];
      if (entity && entity.templates) {
        entity.templates.forEach((temp) => {
          if (
            temp &&
            temp.templateInfo &&
            temp.templateInfo.sections[0] &&
            temp.templateInfo.sections[0].fields
          ) {
            temp.templateInfo.sections[0].fields.forEach((field) => {
              if (field && field.key) {
                if (field.key.includes("#")) {
                  let key = field.key.split("#")[1];
                  field.key = key;
                }
              }
              field = { ...field, ...{ template_id: temp.templateInfo._id } };
              this.currentEntityAllFields.push(field);
            });
          }
        });
      }
      let templatesData = [];
      this.foundArray = this.currentEntityAllFields.filter((e) => {
        let fieldPresent = mapped_fields.find((fie) => {
          if (
            e.key == fie.template_filed_id &&
            e.template_id == fie.template_id
          ) {
            return true;
          }
        });
        if (fieldPresent) {
          return true;
        }
      });
      await (data || []).forEach((details) => {
        let row = {};
        let fields = Object.keys(details);
        fields.forEach((field) => {
          let fieldPresent = mapped_fields.find((e) => {
            if (e.excel_field == field) {
              return true;
            }
          });
          if (fieldPresent) {
            if (row[fieldPresent.template_id]) {
              // let foundedField = this.allCurrentEntityFields.find((fie) => {
              //   if (
              //     fie &&
              //     fie.key &&
              //     fie.template_id &&
              //     fie.key == fieldPresent.template_filed_id &&
              //     fie.template_id == fieldPresent.template_id
              //   ) {
              //     return true;
              //   }
              // });
              // let individualFieldValue = details[field];
              // if (foundedField) {
              //   let checkIsvalid = this.checkFieldData(
              //     individualFieldValue,
              //     foundedField
              //   );
              //   if (checkIsvalid && checkIsvalid.success) {
              //     details[field] = checkIsvalid.data;
              //   } else {
              //     details[field] = "";
              //   }
              // }
              row[fieldPresent.template_id] = {
                ...row[fieldPresent.template_id],
                ...{
                  [fieldPresent.template_filed_id]: details[field]
                },
              };
            } else {
              row[fieldPresent.template_id] = {
                [fieldPresent.template_filed_id]: details[field],
              };
            }
          }
        });
        templatesData.push(row);
      });
      //let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      this.foundArray.map((e) => {
        //if (e.inputType == "TIME" || e.inputType == "FIXED_TIME") {
        //  templatesData.map((data) => {
        //    let time = this.validateAndFormatTime(
        //      templatesData[templatesData.indexOf(data)][
        //        e.template_id.toString()
        //      ][e.key]
        //    );
        //    if (!time?.valid) {
        //      templatesData[templatesData.indexOf(data)][
        //        e.template_id.toString()
        //      ][e.key] = "";
        //    }
        //  });
        //} else
        if (e.inputType == "SELECT") {
          templatesData.map((data) => {
            if (
              templatesData[templatesData.indexOf(data)][
              e.template_id.toString()
              ][e.key] !== undefined
            ) {
              templatesData[templatesData.indexOf(data)][
                e.template_id.toString()
              ][e.key] =
                templatesData[templatesData.indexOf(data)][
                  e.template_id.toString()
                ][e.key].toString();
            }
          });
        } else if (e.inputType == "MULTI_SELECT") {
          templatesData.map((data) => {
            if (
              data[e.template_id][e.key] &&
              data[e.template_id][e.key].toString().split(",").length
            ) {
              let multiSelectArray = templatesData[templatesData.indexOf(data)][
                e.template_id.toString()
              ][e.key]
                .toString()
                .split(",");
              multiSelectArray.forEach((e) => {
                multiSelectArray[multiSelectArray.indexOf(e)] = e.toString();
              });
              templatesData[templatesData.indexOf(data)][
                e.template_id.toString()
              ][e.key] = multiSelectArray;
            }
          });
        } else if (e.inputType == "FORMULA") {
          templatesData.map((data) => {
            data[e.template_id][e.key] = 0;
          });
        } else if (
          e.inputType == "NUMBER" &&
          e.input_type !== "ENTITY_VARIABLE"
        ) {
          templatesData.map((data) => {
            let value = parseFloat(data[e.template_id][e.key]);
            if (!isNaN(value)) {
              data[e.template_id][e.key] = value;
            } else {
              data[e.template_id][e.key] = 0;
            }
          });
        } else if (
          e.inputType == "DATE" &&
          e.date_view_type &&
          e.date_view_type != "day"
        ) {
          templatesData.map((data) => {
            if (data?.[e.template_id]?.[e.key]) {
              data[e.template_id][e.key] =
                e.date_view_type == "month" ?
                  moment().month(data[e.template_id][e.key].toString()) :
                  moment(data[e.template_id][e.key].toString());
            }
          });
        } else if (e.inputType == "PHONE_COUNTRY_CODE") {
          templatesData.map(async (data) => {
            /*** Note: If the validtaion failed here we setting phone number value to null **/
            if (data[e.template_id][e.key]) {
              let contactNumber = data[e.template_id][e.key].toString();
              data[e.template_id][e.key + "_code"] =
                this.getDefaultCountry(contactNumber);
              data[e.template_id][e.key] =
                data[e.template_id][e.key + "_code"].formatNational;
            } else {
              data[e.template_id][e.key + "_code"] = {};
            }
          });
        } else if (e.inputType == "CHECKBOX") {
          templatesData.map((data) => {
            if (
              data[e.template_id][e.key] && ["yes", "true"].includes(
                data[e.template_id][e.key].toString().toLowerCase()
              )
            ) {
              data[e.template_id][e.key] = true;
            } else {
              data[e.template_id][e.key] = false;
            }
          });
        }
      });
      this.preparedData = templatesData;
      //entity type validations
      // let standardTemp = "";
      // if (entity.entity_type === "INDIVIDUAL") {
      //   standardTemp = this.currentEntity.templates.find((temp) => {
      //     if (
      //       temp &&
      //       temp.templateInfo &&
      //       temp.templateInfo.type === "STANDARD"
      //     ) {
      //       return true;
      //     }
      //   });
      // }
      // await templatesData.forEach((rowData) => {
      //   if (entity.entity_type === "INDIVIDUAL") {
      //     if (rowData[standardTemp.template_id]) {
      //       if (
      //         ((Object.keys(rowData[standardTemp.template_id]).indexOf(
      //           "first_name"
      //         ) !== -1 &&
      //           Object.keys(rowData[standardTemp.template_id]).indexOf(
      //             "last_name"
      //           ) !== -1) ||
      //           Object.keys(rowData[standardTemp.template_id]).indexOf(
      //             "name"
      //           ) !== -1) &&
      //         Object.keys(rowData[standardTemp.template_id]).indexOf(
      //           "email"
      //         ) !== -1
      //       ) {
      //         if (
      //           rowData[standardTemp.template_id]["name"] &&
      //           typeof rowData[standardTemp.template_id]["name"] === "string"
      //         ) {
      //           rowData[standardTemp.template_id]["name"] =
      //             rowData[standardTemp.template_id]["name"].trim();
      //         }
      //         if (!rowData[standardTemp.template_id]["first_name"]) {
      //           if (!rowData[standardTemp.template_id]["name"]) {
      //             this.errorExcelImportData.push(rowData);
      //             return;
      //           }
      //         }
      //         if (!rowData[standardTemp.template_id]["last_name"]) {
      //           if (!rowData[standardTemp.template_id]["name"]) {
      //             this.errorExcelImportData.push(rowData);
      //             return;
      //           }
      //         }
      //         if(Object.keys(rowData[standardTemp.template_id]).indexOf('phone_number') !== -1){
      //           if(rowData[standardTemp.template_id]['phone_number'] !== ''){
      //             const phoneValidation = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/
      //             if( !phoneValidation.test(rowData[standardTemp.template_id]['phone_number'])){
      //               this.errorExcelImportData.push(rowData);
      //               return
      //             }
      //           }
      //         }
      //         if (rowData[standardTemp.template_id]["email"] !== "") {
      //           var mailformat =
      //             /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      //           if (
      //             rowData[standardTemp.template_id]["email"].match(
      //               mailformat
      //             ) === null
      //           ) {
      //             this.errorExcelImportData.push(rowData);
      //             return;
      //           }
      //         } else {
      //           this.errorExcelImportData.push(rowData);
      //           return;
      //         }
      //       } else {
      //         this.errorExcelImportData.push(rowData);
      //         return;
      //       }
      //     } else {
      //       //stabdard temp found avvakapothe
      //       this.errorExcelImportData.push(rowData);
      //       return;
      //     }
      //     this.successedExcelImportData.push(rowData);
      //   } else if (
      //     entity.entity_type === "GENERAL" ||
      //     entity.entity_type === "BUSINESS"
      //   ) {
      //     //need to write primary fields conditions
      //     let result = entity.primaryFields.every((pr) => {
      //       if (
      //         pr.template_id &&
      //         rowData[pr.template_id] &&
      //         rowData[pr.template_id][pr.key]
      //       ) {
      //         return true;
      //       }
      //     });
      //     if (result) {
      //       this.successedExcelImportData.push(rowData);
      //     } else {
      //       this.errorExcelImportData.push(rowData);
      //     }
      //   }
      // });
    },
    validateAndFormatTime(timeString) {
      const timeWithSeconds = /^\d{2}:\d{2}:\d{2}$/;
      const timeWithoutSeconds = /^\d{2}:\d{2}$/;

      if (timeWithSeconds.test(timeString)) {
        return {
          valid: true,
          value: timeString,
        };
      }

      if (timeWithoutSeconds.test(timeString)) {
        return {
          valid: false,
          value: timeString + ":00",
        };
      }

      return {
        valid: false,
      };
    },
    handleSizeChangeForIE(val) {
      this.pageSizeForIE = val;
    },
    handleCurrentChangeForIE(val) {
      this.currentPageForIE = val;
    },
    handleSizeChangeForIEDS(val) {
      this.pageSizeForIEDS = val;
    },
    handleCurrentChangeForIEDS(val) {
      this.currentPageForIEDS = val;
    },
    editSuccessedExcelImportData(row) {
      this.currentActiveSuccessRowIndex =
        this.successedExcelImportData.indexOf(row);
    },
    resetSuccessedExcelImportData() {
      let selectedRow =
        this.successedExcelImportData[this.currentActiveSuccessRowIndex];
      let isVaild = this.validateSelectedRow(selectedRow);
      if (isVaild && isVaild.success === false) {
        this.errorExcelImportData.push(selectedRow);
        this.successedExcelImportData.splice(
          this.currentActiveSuccessRowIndex,
          1
        );
      }
    },
    editErrorExcelImportData(row) {
      this.currentActiveErrorRowIndex = this.errorExcelImportData.indexOf(row);
    },
    async closeSendNotificationDialog(params) {
      this.emailConfigLoading = true;
      if (
        params.attachments &&
        params.attachments?.length &&
        params.event_type == "WA"
      ) {
        this.loadingText = "Uploading Files";
        let files = params.attachments.map((e) => {
          return {
            name: e.filename,
            mimetype: e.type,
            data: e.content,
            size: e.size,
          };
        });
        let paramsData = {
          files: files,
          path: "email-attachments",
        };
        await this.$store.dispatch(
          "s3FileUpload/uploadMultipleBase64Files",
          paramsData
        );
        if (this.getFileUploadData && this.getFileUploadData.length) {
          params.attachments = this.getFileUploadData;
        }
      }
      await this.$store.dispatch(
        "entities/sendNotificationToEntityDatausers",
        params
      );
      if (this.getSentEntityDataNotificationStatus) {
        this.emailConfigLoading = false;
        if (this.sendNotificationDialog) {
          this.$notify({
            title: "Success",
            message: "Sending mail(s) initiated",
            type: "success",
          });
        } else {
          this.$notify({
            title: "Success",
            message: "Sending whatsapp message",
            type: "success",
          });
        }
      } else {
        this.emailConfigLoading = false;
        this.$notify({
          title: "Error",
          message: this.getSentEntityNotificationStatusErrors ||
            "Error while sending mails",
          type: "error",
        });
      }
      this.paramsData = {};
      this.sendNotificationDialog = false;
      this.sendWANotificationDialog = false;
    },
    async closeBulkSendNotificationDialog(params) {
      this.emailConfigLoading = true;
      if (
        params.attachments &&
        params.attachments?.length &&
        params.event_type == "WA"
      ) {
        this.loadingText = "Uploading Files";
        let files = params.attachments.map((e) => {
          return {
            name: e.filename,
            mimetype: e.type,
            data: e.content,
            size: e.size,
          };
        });
        let paramsData = {
          files: files,
          path: "email-attachments",
        };
        await this.$store.dispatch(
          "s3FileUpload/uploadMultipleBase64Files",
          paramsData
        );
        if (this.getFileUploadData && this.getFileUploadData.length) {
          params.attachments = this.getFileUploadData;
        }
      }
      await this.$store.dispatch("entities/sendNotificationToAllUsers", params);
      if (this.getSentEntityNotificationStatus) {
        this.emailConfigLoading = false;
        if (this.bulkSendNotificationDialog) {
          this.$notify({
            title: "Success",
            message: "Sending mails initiated",
            type: "success",
          });
        } else {
          this.$notify({
            title: "Success",
            message: "Sending whatsapp message",
            type: "success",
          });
        }
      } else {
        this.emailConfigLoading = false;
        this.$notify({
          title: "Error",
          message: this.getSentEntityNotificationStatusErrors.message,
          type: "error",
        });
      }
      this.paramsData = {};
      this.emailConfigLoading = false;
      this.bulkSendNotificationDialog = false;
      this.bulkSendNotificationWADialog = false;
    },
    resetErrorExcelImportData() {
      let selectedRow =
        this.errorExcelImportData[this.currentActiveErrorRowIndex];
      let isValid = this.validateSelectedRow(selectedRow);
      if (isValid && isValid.success === true) {
        this.successedExcelImportData.push(selectedRow);
        this.errorExcelImportData.splice(this.currentActiveErrorRowIndex, 1);
      }
    },
    validateSelectedRow(rowData) {
      //1st part
      let entity = this.currentEntity;
      let standardTemp = "";
      if (entity.entity_type === "INDIVIDUAL") {
        standardTemp = entity.templates.find((temp) => {
          if (
            temp &&
            temp.templateInfo &&
            temp.templateInfo.type === "STANDARD"
          ) {
            return true;
          }
        });
      }
      //second part
      if (rowData) {
        if (entity.entity_type === "INDIVIDUAL") {
          if (rowData[standardTemp.template_id]) {
            // if(Object.keys(rowData[standardTemp.template_id]).indexOf('phone_number') !== -1){
            //   if(rowData[standardTemp.template_id]['phone_number'] !== ''){
            //     const phoneValidation = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/
            //     if(!phoneValidation.test(rowData[standardTemp.template_id]['phone_number'])){
            //       return{
            //         success : false
            //       }
            //     }
            //   }
            // }
            if (
              ((Object.keys(rowData[standardTemp.template_id]).indexOf(
                "first_name"
              ) !== -1 &&
                Object.keys(rowData[standardTemp.template_id]).indexOf(
                  "last_name"
                ) !== -1) ||
                Object.keys(rowData[standardTemp.template_id]).indexOf(
                  "name"
                ) !== -1) &&
              Object.keys(rowData[standardTemp.template_id]).indexOf(
                "email"
              ) !== -1
            ) {
              if (!rowData[standardTemp.template_id]["first_name"]) {
                if (!rowData[standardTemp.template_id]["name"]) {
                  return {
                    success: false,
                  };
                }
              }
              if (!rowData[standardTemp.template_id]["last_name"]) {
                if (!rowData[standardTemp.template_id]["name"]) {
                  return {
                    success: false,
                  };
                }
              }
              if (rowData[standardTemp.template_id]["email"] !== "") {
                var mailformat =
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                if (
                  rowData[standardTemp.template_id]["email"].match(
                    mailformat
                  ) === null
                ) {
                  return {
                    success: false,
                  };
                }
              } else {
                return {
                  success: false,
                };
              }
            } else {
              return {
                success: false,
              };
            }
          } else {
            //stabdard temp found avvakapothe
            return {
              success: false,
            };
          }
          return {
            success: true,
          };
        } else if (
          entity.entity_type === "GENERAL" ||
          entity.entity_type === "BUSINESS"
        ) {
          //need to write primary fields conditions
          let result = entity.primaryFields.every((pr) => {
            if (
              pr.template_id &&
              rowData[pr.template_id] &&
              rowData[pr.template_id][pr.key]
            ) {
              return true;
            }
          });
          if (result) {
            return {
              success: true,
            };
          } else {
            return {
              success: false,
            };
          }
        }
      }
      //validate selected row
    },
    checkIsFieldSelected(field) {
      return this.mapped_fields.find(
        (e) =>
          e.template_id == field.template_id && e.template_filed_id == field.key
      ) ?
        true :
        false;
    },
    getSelectedTemplate(template_id) {
      let contentFields = [
        "HEADING",
        "PARAGRAPH",
        "HTML_CONTENT",
        "SINGLE_LINE_CONTENT",
        "VIDEO",
        "ACTION_BUTTON",
        "HORIZONTAL_LINE",
        "DIVISION",
      ];
      if (template_id) {
        let selectedTemplate = this.entityTempsWithException.find(
          (e) => e._id == template_id
        );
        if (
          selectedTemplate &&
          selectedTemplate.sections &&
          selectedTemplate.sections[0] &&
          selectedTemplate.sections[0].fields
        ) {
          let filteredFields = selectedTemplate.sections[0].fields.filter(
            (field) => {
              if (
                field &&
                field.input_type &&
                contentFields.includes(field.input_type) === false
              ) {
                return true;
              } else {
                return false;
              }
            }
          );
          if (filteredFields) {
            return filteredFields.map((e) => {
              e.template_id = template_id;
              return e;
            });
          }
        }
        return [];
      }
      return [];
    },
    getSelectedTemplateName(row) {
      if (row.template_id) {
        let selectedField = this.mapped_fields.filter(
          (e) => e.keyIndex == row.keyIndex
        );
        selectedField.forEach((e) => {
          if (e?.template_filed_id && e.template_filed_id.includes("#")) {
            let splitted_one = e.template_filed_id.split("#");
            e.template_filed_id = splitted_one[1];
          } else {
            let templtate_filled_id = e.template_filed_id;
            e.template_filed_id = templtate_filled_id;
          }
        });
        return selectedField;
      }
    },

    getSelectedTemplatemappedData(row) {
      if (row.template_id) {
        let excelfield = row.excel_field;
        let individualArray = [];
        this.getmappedFieldsData.forEach((e) => {
          let objectKeys = Object.keys(e);
          let is_existed = objectKeys.includes(excelfield);
          if (is_existed) {
            individualArray.push(e[excelfield]);
          }
        });
        return individualArray;
      }
    },
    async importMappedData() {
      this.matchDialogVisible = false;
      // let excelFileName = this.getUploadCSVfileStatus.excelfilePath;
      // let params = {
      //   excelFileId: this.getUploadCSVfileStatus.excelFile_id,
      //   excelFilePath: excelFileName,
      // };
      let params = {
        data: this.successedExcelImportData,
        entityId: this.currentEntity._id,
        excelFileId: this.getUploadCSVfileStatus?.excelFile_id || "",
        //excelFilePath: this.getUploadCSVfileStatus.excelfilePath,
      };

      this.mappingLoadingDialogVisible = true;
      this.loading = true;
      await this.$store.dispatch("entities/importMappedDataVersion", params);
      this.loading = false;
      if (this.getimportMappedDataStatusUpdatedVersion) {
        if (
          this.getimportMappedDataStatusUpdatedVersion.status &&
          this.getimportMappedDataStatusUpdatedVersion.message
        ) {
          this.$notify.success({
            title: this.getimportMappedDataStatusUpdatedVersion.status,
            message: this.getimportMappedDataStatusUpdatedVersion.message,
          });
          this.mappingLoadingDialogVisible = false;
          this.mappingStatusDialogVisible = true;
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error in importing data",
          });
        }
      } else {
        this.mappingLoadingDialogVisible = false;
        this.$notify.error({
          title: "Error",
          message: this.getimportMappedDataStatusUpdatedVersion.data.errors[0].message,
        });
      }
    },
    gotoDashboard() {
      this.ImportProcessInitiatedVisible = false;
      this.ImportZip = false;
      this.mappingStatusDialogVisible = false;
      if (this.isApplicationUserSide) {
        window.location.reload();
      } else {
        let UrlPathAdd = "";
        if (this.getIframeUrlInfo != null) {
          UrlPathAdd = `/if/${this.getIframeUrlInfo._id}/entity`;
        } else {
          UrlPathAdd = "/entity";
        }
        this.$router.push(UrlPathAdd);
      }
    },

    handleSelectionChange(checkedDocs) {
      this.selectedRowsIds = checkedDocs;
    },
    async deleteOncheck() {
      this.entitybulkDeleteVisible = true;
    },
    async downloadCheckedEntityData() {
      try {
        this.loading = true;
        this.loadingText = "Generating the zip of pdfs, please wait...";
        let selectedIds = [];
        this.selectedRowsIds.forEach((item) => {
          if (item && item._id) {
            selectedIds.push(item._id);
          }
        });
        if (!this.currentEntity?.default_printing_document) {
          this.loading = false;
          return this.$message({
            message: "Please configure the document template",
            type: "warning",
          });
        }
        if (
          this.entity_id &&
          selectedIds?.length &&
          this.currentEntity?.default_printing_document
        ) {
          await this.$store.dispatch(
            "entitiesData/createDocumentFromEntityData", {
            entity_id: this.entity_id,
            document_id: this.currentEntity.default_printing_document || "",
            entity_data_ids: selectedIds,
          }
          );

          if (
            this.getCreateDocumentForEntityData &&
            this.getCreateDocumentForEntityData.generatedDocuments &&
            this.getCreateDocumentForEntityData.generatedDocuments.length
          ) {
            this.downloadMultipleEntityDataPdfs(
              this.getCreateDocumentForEntityData.generatedDocuments,
              this.currentEntity?.name || "Documents"
            );
            this.loading = false;
          }
        }
        this.loadingText = "";
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("Error downloading entity data:", error);
      }
    },

    downloadMultipleEntityDataPdfs(pdfUrls, name) {
      const zip = new JSZip();
      let zipName = name ? name : "Documents";
      const promises = [];

      pdfUrls.forEach((url, index) => {
        const filename = `${zipName}_${index + 1}.pdf`;
        promises.push(
          axios
            .get(url, { responseType: "blob" })
            .then((response) => zip.file(filename, response.data))
        );
      });

      Promise.all(promises)
        .then(() => {
          zip.generateAsync({ type: "blob" }).then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${zipName}.zip`;
            a.click();
            window.URL.revokeObjectURL(url);
          });
        })
        .catch((error) => {
          console.log("Error while downloading files:", error);
        });
    },
    async deleteCheckedEntityData() {
      let selectedIds = [];
      this.selectedRowsIds.forEach((item) => {
        if (item && item._id) {
          selectedIds.push(item._id);
        }
      });
      this.count = selectedIds.length;
      let params = {
        selectedIds: selectedIds,
        entity_id: this.entity_id,
      };
      this.entitybulkDeleteVisible = false;
      this.loading = true;
      await this.$store.dispatch("entities/entitydataBulkDelete", params);
      if (
        this.getentitydataBulkDeleteStatus &&
        this.getentitydataBulkDeleteStatus.message
      ) {
        this.fetchEntitiesDataForTable();
        this.selectedRowsIds = [];
        this.$notify.success({
          title: "Success",
          message: this.getentitydataBulkDeleteStatus.message,
        });
        this.loading = false;
      } else {
        let message = "Error in deleting data";
        if (this.getEntitydataBulkDeleteErrors) {
          message = this.getEntitydataBulkDeleteErrors;
        }
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: message,
        });
      }
    },

    checkPerimission(access) {
      if (this.isApplicationUserSide) {
        if (access == "BULK DELETE") {
          return false;
        } else if (
          this.getEntityPermission &&
          this.currentMenu &&
          (this.currentMenu.entity_id ||
            this.currentMenu.relational_entity ||
            this.currentMenu.nested_relational_entity)
        ) {
          if (access == "UPDATE") {
            access = "EDIT";
          }
          if (this.currentMenu.entity_id) {
            let entityId = this.currentMenu.entity_id._id ?
              this.currentMenu.entity_id._id :
              this.currentMenu.entity_id;
            if (
              this.currentMenu.entity_type == "ENTITY" &&
              this.getEntityPermission.other_entities &&
              entityId &&
              this.getEntityPermission.other_entities[entityId] &&
              this.getEntityPermission.other_entities[entityId].permissions &&
              this.getEntityPermission.other_entities[
                entityId
              ].permissions.indexOf(access) != -1
            ) {
              if (
                this.entityFiltersData?.permissions?.access_set &&
                this.entityFiltersData?._id
              ) {
                if (access == "EDIT") {
                  access = "UPDATE";
                }
                return (
                  this.entityFiltersData.permissions.access_set.indexOf(
                    access
                  ) != -1
                );
              } else {
                return true;
              }
            }
          } else if (this.currentMenu.relational_entity) {
            let entityId = this.currentMenu.relational_entity._id ?
              this.currentMenu.relational_entity._id :
              this.currentMenu.relational_entity;

            if (
              entityId &&
              this.getEntityPermission.relational_entities[entityId] &&
              this.getEntityPermission.relational_entities[entityId]
                .permissions &&
              this.getEntityPermission.relational_entities[
                entityId
              ].permissions.indexOf(access) != -1
            ) {
              return true;
            }
          } else if (this.currentMenu?.nested_relational_entity) {
            if (
              this.getEntityPermission &&
              this.getEntityPermission.nested_relational_entities &&
              this.getEntityPermission.nested_relational_entities[
                this.currentMenu.nested_relational_entity
              ]?.permissions.indexOf(access) != -1
            ) {
              return true;
            }
          }
        }
        if (
          this.entityFiltersData?.permissions?.access_set &&
          this.entityFiltersData?._id
        ) {
          return (
            this.entityFiltersData.permissions.access_set.indexOf(access) != -1
          );
        }
        return false;
      } else {
        if (access == "BULK DELETE" || access == "BULK DOWNLOAD") {
          return true;
        } else if (access == "BULK UPDATE") {
          if (
            this.$route.query.isMenu == "true" &&
            this.getMenu &&
            this.getMenu.menu_list &&
            this.getMenu.menu_list.length
          ) {
            let menuItems = [];
            this.getMenu.menu_list.map((menu) => {
              if (!menu.children?.length) {
                menuItems.push(menu);
              } else {
                menu.children.map((sub) => {
                  if (!sub.children?.length) {
                    menuItems.push(sub);
                  } else {
                    menuItems = [...menuItems, ...sub.children];
                  }
                });
              }
            });
            let menuItem = menuItems.find(
              (e) =>
                e &&
                e.feature == "ENTITIES" &&
                e.isGroup == "ENTITIES" &&
                e.entity_id &&
                (e.entity_id._id ?
                  this.entity_id.toString() == e.entity_id._id.toString() :
                  this.entity_id.toString() == e.entity_id.toString())
            );
            if (
              menuItem &&
              menuItem.bulkUpdate &&
              menuItem.bulkUpdate == true
            ) {
              return true;
            }
            return false;
          }
          return true;
        } else if (access == "SEND NOTIFICATION") {
          return true;
        } else if (
          (this.entityFiltersData?.permissions?.access_set ||
            this.entityFiltersData?.permissions?.filter_permissions) &&
          this.entityFiltersData?._id
        ) {
          if (access == "EDIT") {
            access = "UPDATE";
          }
          return (
            this.entityFiltersData.permissions.access_set.indexOf(access) !=
            -1 ||
            this.entityFiltersData.permissions.filter_permissions.indexOf(
              access
            ) != -1
          );
        }
        return true;
      }
    },
    getSort() {
      if (this.sortBy) {
        return {
          prop: this.sortBy,
          order: this.sortOrder == "asc" ? "ascending" : "descending",
        };
      }
      return null;
    },
    async sortChange(a) {
      if (
        this.sortBy == a.prop &&
        this.sortOrder == (a.order == "ascending" ? "asc" : "desc")
      ) {
        return;
      }
      this.sortBy = a.prop;
      this.sortOrder = a.order == "ascending" ? "asc" : "desc";
      const query = this.$route.query;
      this.$router.push({
        query: { ...query, order_by: this.sortBy, order_type: this.sortOrder },
      });
      this.fetchEntitiesDataForTable(this.applied_quick_filters);
      if (a.order == null) {
        this.sortBy = null;
        this.sortOrder = null;
        this.fetchEntitiesDataForTable(this.applied_quick_filters);
      }
    },
    async downloadFile(data) {
      this.loading = true;
      this.loadingText = "Downloading File....";
      let fileName = data.name;
      fileName = await fileName.replace(/\//g, "-");
      fileName = await fileName.replace(/ /g, "_");
      fileName = await fileName.replace(/[()]/g, "");
      if (data && !data.path) {
        data.path = `template-data-documents/${fileName}`;
      }
      await this.$store.dispatch("templatesData/downloadTemplateDocument", {
        path: data.path,
      });
      await fetch(this.getDownloadUrl, {
        method: "GET",
        responseType: "blob",
      })
        .then((res) => res.blob())
        .then((response) => {
          var fileURL = window.URL.createObjectURL(response);
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      if (this.getDownloadUrl) {
        // window.open(this.getDownloadUrl, "_blank");
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getDownloadError || "Error while downloading",
        });
      }
      this.loading = false;
      this.loadingText = "Loading....";
    },
    quickFilterUpdated(data) {
      this.applied_quick_filters = [...data];
      let filterValuesObject = {
        [this.currentFilter]: {
          selectedFields: this.selectedFileds,
          filters: data,
        },
      };
      this.$store.commit(
        "entities/setQuickEntityFiltersObject",
        filterValuesObject, {
        root: true,
      }
      );
      this.currentPage = 1;
      this.fetchEntitiesDataForTable(data);
    },
    async openSettingsModal() {
      if (
        this.entityFiltersData &&
        this.entityFiltersData.columns_settings &&
        this.entityFiltersData.columns_settings.length
      ) {
        this.refresh = true;
        this.selectedTableFields = [];
        let allKeys = this.fieldsListData
          .flatMap((temp) => temp.children)
          .map((fd) => fd.template_id + "#" + fd.id);
        this.entityFiltersData.columns_settings =
          this.entityFiltersData.columns_settings.filter((fd) =>
            allKeys.includes(fd.template_id + "#" + fd.id)
          );
        this.selectedTableFields = this.getValidColumnSettings(
          this.entityFiltersData.columns_settings
        ).map((e) => e.id);
        this.treeCheckedNodes = this.getValidColumnSettings(
          this.entityFiltersData.columns_settings
        );
        setTimeout(() => {
          this.refresh = false;
        });
      }
      if (this.currentFilter && this.getCurrentFilter) {
        this.filterColumns = [];
        if (this.getCurrentFilter.tableFieldGroupByFields) {
          this.getCurrentFilter.tableFieldGroupByFields.forEach((e) => {
            if (e?.sub_group) {
              this.filterColumns.push("sub_group/" + e.key);
            } else {
              this.filterColumns.push(e.key);
            }
          });
        } else {
          this.filterColumns.push("count");
        }
        if (this.getCurrentFilter?.tableFieldGroupBy) {
          let subField = this.getField(this.getCurrentFilter.tableFieldGroupBy);
          if (this.getCurrentFilter?.filterEntityVariableColumns) {
            this.filterVariableColumns =
              this.getCurrentFilter.filterEntityVariableColumns;
          }
          if (subField?.input_type == "ENTITY") {
            this.loading = true;
            this.allOtherEntityFields = await this.fetchEntityDetails(
              subField.entity_id,
              true
            );
            this.allOtherEntityFields = this.allOtherEntityFields.filter(
              (e) => e.key
            );
            this.loading = false;
          }
        }
      }
      this.dialogTableVisible = true;
    },

    getValidColumnSettings(columns) {
      return columns.filter(
        (f) =>
          ((f.type == "ENTITY" && f.entity_id) || f.type != "ENTITY") &&
          f.type != "VIDEO"
      );
    },


    async updateFilterColumn() {
      this.refresh = true;
      this.modalLoading = true;
      this.loading = true;
      if (this.getCurrentFilter && this.getCurrentFilter.tableFieldGroupBy) {
        if (!this.filterColumns.length) {
          this.$message({
            message: "Atleast one column must be selected",
            type: "warning",
          });
          this.modalLoading = false;
          this.loading = false;
          this.refresh = false;
          return;
        }
        let columns = [];
        this.filterColumns = this.filterColumns.filter(
          (e) =>
            !e.includes("#") || (e.includes("#") && e.split("#").length == 2)
        );
        this.filterColumns.forEach((e) => {
          if (e) {
            if (e.includes("sub_group/")) {
              columns.push({
                key: e.replace("sub_group/", ""),
                name: this.getLabel(e.replace("sub_group/", "")),
                type: e.replace("sub_group/", "") == "count" ? "count" : "sum",
                sub_group: true,
              });
            } else {
              columns.push({
                key: e,
                name: this.getField(e) && this.getField(e).label ?
                  this.getField(e).label :
                  e,
                type: e == "count" ? "count" : "sum",
                sub_group: false,
              });
            }
          }
        });
        await this.$store.dispatch("filters/updateFilter", {
          id: this.currentFilter,
          params: {
            tableFieldGroupByFields: columns,
            filterEntityVariableColumns: this.filterVariableColumns,
          },
        });
      } else {
        // let checked = this.$refs.tree.getCheckedNodes();

        // let selectedEntity = await lodash.filter(checked, function (node) {
        //   return !node.children;
        // });
        let fields = this.treeCheckedNodes.filter((e) => !e.fields);
        this.selectedFieldKeys = fields.map((field) => field);
        if (this.selectedFieldKeys && !this.selectedFieldKeys.length) {
          this.$message({
            message: "Atleast one column must be selected",
            type: "warning",
          });
          this.modalLoading = false;
          this.loading = false;
          this.refresh = false;
          return;
        }
        await this.$store.dispatch("filters/updateFilter", {
          id: this.currentFilter,
          params: {
            columns_settings: this.selectedFieldKeys,
          },
        });
      }
      if (this.getUpdateFilterStatus) {
        if (this.getUpdateFilterData?.data) {
          await updateFilterData(
            this.currentFilter,
            this.getUpdateFilterData.data
          );
        }
        //this.loading = false;
        this.$notify({
          title: "Success",
          message: "Filters updated",
          type: "success",
        });
        this.modalLoading = false;
        this.dialogTableVisible = false;
        this.filterColumList = [];
        this.filterColumList = [...this.selectedFieldKeys];
        this.selectedTableFields = this.selectedFieldKeys.map((e) => e.id);
        await this.fetchAllEntityFilters(true);
        this.loading = false;
      } else {
        this.loading = false;
        this.$notify({
          title: "Error",
          message: "Failed to Save the filters",
          type: "error",
        });
      }
      this.refresh = false;
    },
    updateSelectedColumn() {
      this.refresh = true;
      // this.selectedTableFields = [];
      let selectedFilter = this.entityAllFilters.find(
        (e) => e && e._id == this.currentFilter
      );
      if (
        selectedFilter &&
        selectedFilter.columns_settings &&
        selectedFilter.columns_settings.length
      ) {
        this.selectedTableFields = [];
        this.selectedTableFields = selectedFilter.columns_settings.map(
          (e) => e.id
        );
      } else {
        this.selectedTableFields = [...this.entityFieldSettings];
      }
      setTimeout(() => {
        this.refresh = false;
      });
    },
    getTemplateFields(
      temp,
      include_data_tables = false,
      include_data_table_key = false
    ) {
      let fields = [];
      let data = JSON.parse(JSON.stringify(temp));
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });

      fields = fields.map((e) => {
        e.template_id = data._id;
        if (e.key && !e.key.includes("#")) {
          e.key = data._id + "#" + e.key;
        }
        e.template_name = data.name;
        e.data_type =
          e.validations && e.validations.type ? e.validations.type : "STRING";
        return e;
      });
      if (include_data_tables) {
        //have to check data table fields
        let dataTableFields = fields.filter(
          (e) => e?.inputType == "DATA_TABLE"
        );
        if (dataTableFields && dataTableFields.length) {
          dataTableFields.forEach((fl) => {
            if (fl?.data_table_columns) {
              fields = [
                ...fields,
                ...fl?.data_table_columns.map((e) => {
                  e.data_table_key = fl.key;
                  e.template_id = data._id;
                  e.label = fl.label + "-" + e.label;
                  e.template_name = data.name;
                  if (include_data_table_key) {
                    e.key = e.data_table_key + "." + e.key;
                  }
                  return e;
                }),
              ];
            }
          });
        }
        fields = fields.filter((e) => {
          return e.inputType != "DATA_TABLE";
        });
      }
      fields.forEach((f) => {
        if (
          f.inputType === "NUMBER" ||
          f.inputType === "CURRENCY" ||
          f.inputType === "AGGREGATE_FUNCTION" ||
          (f.inputType === "FORMULA" && f.result_type === "NUMBER")
        ) {
          const keyExists = this.number_fields.some(
            (item) => item.key === f.key
          );
          if (!keyExists) {
            this.number_fields.push(f);
          }
        }
      });
      return fields;
    },
    resetFiltersData() {
      // if (!this.currentFilter) {
      //   this.resetEntityFiltersData();
      // } else {
      let check = this.checkFilterDisabled;
      if (!this.entityFiltersData.name && this.entityFiltersData.isSaveFilter) {
        this.entityFiltersData.quick_filters = [];
      }
      this.entityFiltersData.filters = this.removeEmptyRows(
        this.entityFiltersData.filters || []
      );
      if (check) {
        let selectedFilter;
        if (this.$route.query.routeType) {
          selectedFilter = this.entityAllFilters.find(
            (e) => e && e._id == this.UsercurrentFilter
          );
        }
        selectedFilter = this.entityAllFilters.find(
          (e) => e && e._id == this.currentFilter
        );
        if (selectedFilter) {
          this.entityFiltersData = {
            ...selectedFilter,
            ...{ updateFilter: true },
          };
        }
      }
      // }
      if (
        this.entityFiltersData._id &&
        this.entityAllFilters &&
        this.entityAllFilters.length
      ) {
        let entityFiltersData;
        if (this.$route.query.routeType) {
          entityFiltersData = this.entityAllFilters.find(
            (e) => e._id == this.entityUserFiltersData._id
          );
        } else {
          entityFiltersData = this.entityAllFilters.find(
            (e) => e._id == this.entityFiltersData._id
          );
        }
        if (entityFiltersData?._id) {
          this.entityFiltersData = { ...entityFiltersData };
        }
      }
      this.dialogEdit = false;
    },

    confimDelete() {
      this.$confirm("Are you sure to delete?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.deleteFilter();
      });
    },
    async fetchAllEntityFilters(refreshTable) {
      //await this.$store.dispatch("filters/fetchAllEntityFilters", {
      //  entityId: this.entity_id,
      //});
      const getAllEntityFilters = await fetchAllEntityFiltersByEntity({
        entityId: this.entity_id,
      });
      this.entityAllFilters = [];
      if (getAllEntityFilters) {
        this.entityAllFilters = getAllEntityFilters;
        if (
          this.currentFilter &&
          !this.entityAllFilters.find((e) => e && e._id == this.currentFilter)
        ) {
          this.currentFilter = "";
        }
        if (this.isApplicationUserSide) {
          this.entityAllFilters = this.entityAllFilters.filter(
            (e) => e._id == this.$route.query.filter
          );
        }
        if (this.$route.query.filter) {
          let entityFiltersData = this.entityAllFilters.find(
            (e) => e._id == this.$route.query.filter
          );
          if (entityFiltersData) {
            this.entityFiltersData = { ...entityFiltersData };
            this.currentFilter = this.$route.query.filter;
          } else {
            //removing not existed filter from url
            delete this.$route.query.filter;
            return;
          }
        } else if (refreshTable && this.currentFilter) {
          this.entityFiltersData = this.entityAllFilters.find(
            (e) => e && e._id == this.currentFilter
          );
          await this.fetchEntitiesDataForTable();
        }
        await this.getRelationQuickFilters();
      }
    },
    isNumber(value) {
      return (
        (typeof value === "number" && !isNaN(value)) ||
        (typeof value === "string" && !isNaN(Number(value)))
      );
    },
    mapDateFilters(filters) {
      return filters.map((d) => {
        let e = JSON.parse(JSON.stringify(d));
        if (e.global_variable_id) {
          let variable = this.globalVariablesData.find(
            (fl) => fl._id == e.global_variable_id
          );
          if (variable?.value) {
            if (this.isNumber(variable.value)) {
              e.value = parseInt(variable.value);
            } else {
              e.value = variable.value;
            }
          }
        }
        if (
          e.operator == "real_time" &&
          e.data_type == "DATE" &&
          !e.every_year
        ) {
          let data = this.getDatePickerHelper1(e.selected_date_picker, e.value);
          e.value = data.value;
          e.today = data.today;
          e.operator = "between";
        } else if (
          e.data_source == "login_user" &&
          e.value_field &&
          this.getAuthenticatedUser[e.value_field]
        ) {
          e.value = this.getAuthenticatedUser[e.value_field];
        } else if (
          e.field == "created_by" &&
          e.data_source == "LOGIN_USER" &&
          this.getAuthenticatedUser
        ) {
          e.value = [this.getAuthenticatedUser._id];
        } else if (
          e.field == "updated_by" &&
          e.data_source == "LOGIN_USER" &&
          this.getAuthenticatedUser
        ) {
          e.value = [this.getAuthenticatedUser._id];
        } else if (
          e.data_type == "DATE_TIME" &&
          e.data_source == "REAL_TIME_PICKER" &&
          e.selected_date_picker &&
          e.operator == "dateinclude"
        ) {
          let data = this.getDatePickerHelper(e.selected_date_picker);
          e.value = data.value;
        } else if (e.data_type == "NUMBER" && e.operator == "between") {
          let data = [e.range1, e.range2];
          e.value = data;
        } else if (e.data_type == "CURRENCY" && e.operator == "between") {
          let data = [e.range1, e.range2];
          e.value = data;
        } else if (
          e.data_type == "DATE" &&
          e.value &&
          e.data_source == "CURRENT_DATE"
        ) {
          if (e.value == "today") {
            if (e.operator == ">") {
              e.value = moment().endOf("day").toISOString();
            } else if (e.operator == ">=") {
              e.value = moment().startOf("day").toISOString();
            } else if (e.operator == "<") {
              e.value = moment().startOf("day").toISOString();
            } else if (e.operator == "<=") {
              e.value = moment().endOf("day").toISOString();
            } else if (e.operator == "=") {
              let data = this.getDatePickerHelper1("today", moment());
              e.value = data.value;
              e.today = data.today;
              e.operator = "between";
            }
          }
        } else if (
          e.data_type == "DATE" &&
          e.value && ["before", "after", "weekdays"].indexOf(e.operator) == -1
        ) {
          if (e.operator == "=") {
            let data = this.getDatePickerHelper1("custom_date", e.value);
            e.value = data.value;
            e.today = data.today;
            e.operator = "between";
          } else if (
            ["notbetween", "between"].includes(e.operator) &&
            e.value
          ) {
            let data = this.getDatePickerHelper1("custom_date_range", e.value);
            e.value = data.value;
            e.today = data.today;
          } else if (
            e.operator == "real_time" &&
            e.data_type == "DATE" &&
            e.value &&
            !e.every_year
          ) {
            let data = this.getDatePickerHelper1(
              e.selected_date_picker,
              e.value
            );
            e.value = data.value;
            e.today = data.today;
            e.operator = "between";
          } else {
            if ([">=", ">"].includes(e.operator)) {
              e.value = moment(new Date(e.value)).startOf("day");
            } else {
              e.value = moment(new Date(e.value)).endOf("day");
            }
          }
        }
        e["current_date"] = this.getCurrentDate;
        return e;
      });
    },
    getField(field) {
      if (this.currentEntity?.templates && field && field.includes("#")) {
        let selectedEntityFields = [];
        this.currentEntity.templates.forEach((e) => {
          if (e && e.templateInfo && !e.is_repeatable) {
            selectedEntityFields = [
              ...selectedEntityFields,
              ...this.getTemplateFields(
                JSON.parse(JSON.stringify(e.templateInfo))
              ),
            ];
          }
        });
        let foundField = selectedEntityFields.find((e) => {
          let [tempId, key] = field.split("#");
          if (tempId == e.template_id && e.key) {
            let fieldKey = e.key.includes("#") ?
              e.key.split("#")[e.key.split("#").length - 1] :
              e.key;
            if (key == fieldKey) {
              return true;
            }
          }
        });
        return foundField ? foundField : null;
      }
      return null;
    },
    async fetchEntitiesDataForTable(
      filters = [],
      pageChanged = false,
      reloading = false,
      type = ""
    ) {
      if (type == "child") {
        this.childLoading = true;
        this.loadingText = "Fetching data...";
      } else if (!reloading) {
        this.loadingText = "Fetching data...";
        this.loading = true;
      }
      let nestedRelationalEntityDatas = [];
      if (
        this.currentMenu?.entity_type == "NESTED_RELATIONAL_ENTITY" &&
        this.isApplicationUserSide
      ) {
        nestedRelationalEntityDatas = await this.getNestedRelationshipDataIds();
      }
      if (!this.entityFiltersData && this.$route.query.filter) {
        this.resetEntityFiltersData();
      }
      filters = [...this.entityFiltersData.filters, ...filters];
      // this.exportFilters = filters;
      let checkDataTable = this.filterColumList.find(
        (e) => e.type == "DATA_TABLE"
      );
      if (this.entityFiltersData?.data_limit != 10 && !pageChanged) {
        this.pageSize = Number(this.entityFiltersData.data_limit);
      }
      let sortBy = this.sortBy;

      let sortOrder = this.sortOrder;

      if (this.entityFiltersData?.sortBy && this.entityFiltersData.sortOrder) {
        sortBy = this.entityFiltersData.sortBy;

        sortOrder = this.entityFiltersData.sortOrder;
      }
      if (
        this.currentMenu?.entity_type == "RELATIONAL_ENTITY" &&
        this.isApplicationUserSide
      ) {
        filters = [...filters, ...this.getSelfEntityFilters];
      }

      let params = {
        entity_id: this.entity_id,
        template_fields_data: (this.currentEntity &&
          this.currentEntity.templates ?
          this.currentEntity.templates :
          []
        ).map((template) => {
          return {
            template_id: template.template_id,
          };
        }),
        filters: this.mapDateFilters(
          this.addGlobalFilters(
            filters || [],
            this.entity_id,
            this.checkGlobalFilter
          )
        ),
        limit: this.activeEntityView == "CARDS" ? 12 : this.pageSize,
        page: parseInt(this.getCurrentPage) ?
          parseInt(this.getCurrentPage) :
          this.currentPage,
        sortBy: sortBy,
        sortOrder: sortOrder,
        search_string: this.search_string,
        data_table_field: checkDataTable,
      };
      if (this.$route?.query?.viewType == "HTML_CONTENT") {
        params.viewType = "HTML_CONTENT";
        params["email_template_id"] = this.getSelectedEmailTemplate();
      }
      if (
        this.currentMenu?.entity_type == "NESTED_RELATIONAL_ENTITY" &&
        this.isApplicationUserSide
      ) {
        params.entity_data_ids = nestedRelationalEntityDatas;
        this.exportDataIds = nestedRelationalEntityDatas;
        if (!nestedRelationalEntityDatas.length) {
          this.data = [];
          this.total = 0;
          this.totalData = [];
          this.tableLoading = false;
          return;
        }
      }
      this.applied_filters = [];
      this.applied_filters = this.mapDateFilters(filters || []);
      if (this.entityFiltersData?.tableFieldGroupBy) {
        let subField = this.getField(this.entityFiltersData.tableFieldGroupBy);
        if (subField?.inputType == "MULTI_SELECT") {
          params.unwind = true;
        }
        params.group_by = this.entityFiltersData.tableFieldGroupBy;
        params.group_by_fields = this.entityFiltersData.tableFieldGroupByFields;
        if (this.entityFiltersData?.tableFieldSubGroupBy) {
          params.sub_group_by = this.entityFiltersData?.tableFieldSubGroupBy;
        } else {
          params.entity_variable_columns =
            this.entityFiltersData.filterEntityVariableColumns;
          if (
            this.getField(this.entityFiltersData.tableFieldGroupBy)?.entity_id
          ) {
            params.filter_variable_entity = this.getField(
              this.entityFiltersData.tableFieldGroupBy
            ).entity_id;
          }
        }
      }
      // await this.$store.dispatch("entities/fetchEntityRecordsForTable", params);
      let response = await postAPICall(
        "POST",
        "/entities-data/entity/data",
        params
      );
      this.exportFilters = params.filters;
      if (!this.currentEntity || this.currentEntity?._id !== this.entity_id) {
        this.currentEntity = response.selectedEntity;
        this.prepareKeyLabelsObject();
        await this.$store.commit(
          "entities/setEntityDataById",
          this.currentEntity, {
          root: true,
        }
        );
      }
      if (!reloading) {
        this.data = [];
      }
      if (
        this.entityFiltersData &&
        this.entityFiltersData.columns_settings &&
        this.entityFiltersData.columns_settings.length
      ) {
        this.filterColumList = [];
        this.filterColumList = this.entityFiltersData.columns_settings;
        //map with latest field type
        this.filterColumList = this.mapTableFieldsType(
          this.currentEntity,
          this.filterColumList
        );
      }
      if (response) {
        this.hasMore = response.hasMore;
        if (reloading) {
          return {
            data: this.prepareEntityDataToShowInTable(
              JSON.parse(JSON.stringify(response.data)),
              response.selectedEntity
            ),
            total: response.total ? response.total : 0,
          };
        } else {
          this.data = this.prepareEntityDataToShowInTable(
            JSON.parse(JSON.stringify(response.data)),
            response.selectedEntity
          );
        }
        //this.entityUserInfoData = await this.mapEntityDataWithUserInfo(
        //  this.data
        //);
        this.templateData = response.selectedEntity.templates.map((e) => e);
        // this.mapEntityDataWithUserInfo(this.data).then((e) => {
        //   this.entityUserInfoData = { ...e };
        // });
        this.totalData = [...this.data];
        this.total = response.total ? response.total : 0;
        this.custom_columns = [];
        if (this.entityFiltersData?.tableFieldGroupBy) {
          if (this.entityFiltersData?.tableFieldSubGroupBy) {
            let custom_columns = [];
            this.groupedData = response.data.map((data) => {
              if (data?.properties) {
                let label = "",
                  total = 0;
                (data.properties || []).forEach((el) => {
                  if (!label) {
                    label = el.entity_label;
                  }
                  total = total + el.count;
                  let value = el.sub_entity_label ?
                    el.sub_entity_label :
                    el.label;
                  let index = custom_columns.findIndex(
                    (e) => e.value == el.label
                  );
                  if (index == -1 && value) {
                    custom_columns.push({
                      value: el.label,
                      label: value,
                    });
                  }
                  (Object.keys(el) || []).forEach((key) => {
                    if (
                      ["entity_label", "label", "sub_entity_label"].indexOf(
                        key
                      ) == -1
                    ) {
                      data[el.label + "?" + key] = el[key];
                      data[value + "?" + key] = el[key];
                    }
                  });
                });

                data.entity_label = label;
                data.count = total;
              }
              return data;
            });
            if (
              this.entityFiltersData?.table_field_group_by_selected_options &&
              this.entityFiltersData.table_field_group_by_selected_options
                .length
            ) {
              custom_columns = custom_columns.filter(
                (e) =>
                  this.entityFiltersData.table_field_group_by_selected_options.indexOf(
                    e.value
                  ) != -1
              );
            }
            this.entityFiltersData.tableFieldGroupByFields
              .filter((e) => e.sub_group)
              .forEach((field) => {
                (custom_columns || []).forEach((col) => {
                  this.custom_columns.push({
                    label: col.label + "-" + field.name,
                    value: col.value + "?" + field.key,
                  });
                });
              });
          } else {
            this.groupedData = response.data;
            this.groupedData = this.groupedData.map((e) => {
              if (e && e.name && e.name.includes("#")) {
                e.name = e.name.split("#")[0];
              }
              return e;
            });
          }
        }
        this.showContentView = false;
        if (type == "child") {
          this.childLoading = false;
        }
        this.loading = false;
        this.showContentView = true;
      } else {
        if (type == "child") {
          this.childLoading = false;
        }
        this.loading = false;
      }
    },
    prepareKeyLabelsObject() {
      if (!this.allFieldKeyLabels) {
        this.allFieldKeyLabels = {};
        (this.currentEntity?.templates || []).forEach((temp) => {
          let tempFields = {};
          (temp.templateInfo?.sections[0]?.fields || []).forEach((fd) => {
            let fieldKey = fd.key;
            let fieldLabel = fd.label;
            if (fieldKey && fieldKey.includes("#")) {
              let [, cleanedKey] = fieldKey.split("#");
              tempFields[cleanedKey] = fieldLabel;
            } else {
              tempFields[fieldKey] = fieldLabel;
            }
          });

          this.allFieldKeyLabels[temp.template_id] = tempFields;
        });
      }
      return this.allFieldKeyLabels;
    },
    getSubField(data, step) {
      let value = 0,
        key = step && step.includes("?") ? step.split("?")[1] : step;
      let field = this.getField(key);
      if (data && step && data[step]) {
        value = data[step];
      }
      if (field?.inputType == "CURRENCY") {
        let type = field.validations.currency ?
          field.validations.currency :
          "USD";
        let locale = field.validations.locale ?
          field.validations.locale :
          "en-US";
        value = new Intl.NumberFormat(locale, {
          style: "currency",
          currency: type,
        }).format(value);
      } else if (field?.inputType == "AGGREGATE_FUNCTION") {
        let isCurrencyField = (field.selected_fields || []).find((el) => {
          if (el?.inputType == "CURRENCY" || el?.result_type == "CURRENCY") {
            return true;
          }
        });
        if (isCurrencyField) {
          let type =
            isCurrencyField &&
              isCurrencyField.validations &&
              isCurrencyField.validations.currency ?
              isCurrencyField.validations.currency :
              "USD";
          let locale =
            isCurrencyField &&
              isCurrencyField.validations &&
              isCurrencyField.validations.locale ?
              isCurrencyField.validations.locale :
              "en-US";
          value = new Intl.NumberFormat(locale, {
            style: "currency",
            currency: type,
          }).format(value);
        } else {
          return value;
        }
      }
      return value;
    },
    async prepareRlationships() {
      let relationships = await fetchEntityRelationships({ entity_id: this.entity_id });
      relationships = relationships.filter(
        (e) => e.representation == "TAB" || e.owner_type == "CHILD"
      );

      this.relationshipsActions = relationships.map((r) => {
        return {
          title: "Add " + r.relationship_title,
          type: "ADD",
          child_relationship_id: r.relationship_id,
          relationship_details: r,
        };
      });
    },
    checkApplicationUserPermission(rowData, stepData) {
      if (this.isApplicationUserSide) {
        if (
          this.getEntityPermission &&
          this.getEntityPermission.other_entities &&
          this.getEntityPermission.other_entities[stepData.entity_id] &&
          this.getEntityPermission.other_entities[stepData.entity_id]
            .permissions &&
          this.getEntityPermission.other_entities[
            stepData.entity_id
          ].permissions.indexOf("VIEW") !== -1
        ) {
          this.showChildEntityDataDetails(rowData, stepData);
        } else {
          this.$message({
            message: "You don't have permission to view the data",
            type: "warning",
          });
        }
      } else {
        this.showChildEntityDataDetails(rowData, stepData);
      }
    },
    closeChildEntityDataDialog() {
      this.showChildEntityDataDialog = false;
    },
    async showChildEntityDataDetails(rowData, stepData) {
      let field = this.getField(stepData.template_id + "#" + stepData.id);
      this.showChildEntityDataDialog = false;
      this.selectedChildEntityDataIsMultple = false;

      this.selectedChildEntityDataId =
        rowData.entityData[stepData.template_id][stepData.id];

      this.selectedChildEntityId = stepData.entity_id;

      this.selectedChildEntityRefName = stepData.label;
      this.selectedChildEntityPrimaryDataValue = this.getFieldValue(
        rowData,
        stepData
      );
      //For multiple entity show as table
      if (
        field?.allow_multiple &&
        typeof this.selectedChildEntityDataId == "object" &&
        this.selectedChildEntityDataId.length > 1
      ) {
        this.selectedChildEntityDataIsMultple = true;
      }
      this.showChildEntityDataDialog = true;
    },

    async openAddNewRelationshipData(data, action) {
      this.showAddRelationshipDataDialog = false;
      action.relationship_details.parent_entity_data_id = data._id;
      this.selectedRelationshipDetails = { ...action.relationship_details };
      this.selectedRelationshipExistedIds = [];
      this.showAddRelationshipDataDialog = true;
    },
    async updateCurrentFilter() {
      this.quickFilterVisible = true;
      this.resetEntityFiltersData();
      let filter;
      if (this.$route.query.routeType) {
        filter = this.UsercurrentFilter;
      } else {
        filter = this.currentFilter;
      }
      if (filter) {
        let selectedFilter = this.entityAllFilters.find(
          (e) => e && e._id == filter
        );
        if (selectedFilter.displayType) {
          this.activeEntityView = selectedFilter.displayType;
          let query = { ...this.$route.query };
          query["viewType"] = selectedFilter.displayType;
          if (!this.$route.query.routeType) {
            query["filter"] = selectedFilter._id;
          }
          this.$router.push({
            name: this.$route.name,
            query: query,
          });
        }
        this.entityFiltersData = {
          ...selectedFilter,
          ...{ isSaveFilter: true },
        };
        await this.getRelationQuickFilters();
        this.treeCheckedNodes =
          selectedFilter.columns_settings &&
            selectedFilter.columns_settings.length ?
            selectedFilter.columns_settings :
            this.currentEntity?.settings && this.currentEntity.settings.length ?
              this.currentEntity.settings :
              this.defaultSettingFields;
        this.selectedTableFields = this.treeCheckedNodes.map((e) => e.id);
      } else {
        this.filterColumList = [];
        this.pageSize = 10;
        if (this.currentEntity.settings && this.currentEntity.settings.length) {
          this.selectedTableFields = [];
          this.filterColumList = this.getValidColumnSettings(
            this.currentEntity.settings
          );
          //map with latest field type
          // this.filterColumList = this.mapTableFieldsType(
          //   this.currentEntity,
          //   this.filterColumList
          // );
        } else {
          this.filterColumList = [
            ...this.filterColumList,
            ...this.defaultSettingFields,
          ];
        }
        this.treeCheckedNodes = this.filterColumList;
        this.selectedTableFields = this.treeCheckedNodes.map((e) => e.id);
        let query = { ...this.$route.query };
        if (!this.$route.query.routeType) {
          delete query["viewType"];
          delete query["filter"];
        }
        this.$router.push({
          name: this.$route.name,
          query: query,
        });
      }
      this.quickFilterVisible = false;
      if (this.$route.query.routeType) {
        if (this.$route?.query?.filter) {
          let filter = this.entityAllFilters.find(
            (e) => e._id == this.$route.query.filter
          );
          this.fetchEntitiesDataForTable(filter?.filters || []);
        }
      } else {
        this.fetchEntitiesDataForTable();
      }
    },
    async getRelationQuickFilters() {
      this.quickFilterVisible = true;
      const relationFilters = this.entityFiltersData.filters.filter(
        (e) => e.data_type == "RELATION"
      );
      if (relationFilters.length) {
        // await this.$store.dispatch(
        //   "filters/getFilterById",
        //   relationFilters[0].value
        // );
        this.getSingleFilterData = await fetchFilterById(
          this.$route.query.filter
        );
        if (
          this.getSingleFilterData &&
          this.getSingleFilterData.quick_filters.length
        ) {
          this.relationQuickFilters =
            this.getSingleFilterData.quick_filters.map(
              (e) => e + "#" + relationFilters[0].relation_entity_id
            );
        }
      }
      this.quickFilterVisible = false;
    },
    resetEntityFiltersData() {
      const resetData = {
        filter_name: "",
        isSaveFilter: false,
        query_type: "AND",
        quick_filters: [],
        columns_settings: [],
        data_limit: 10,
      };
      if (this.$route.query.routeType) {
        this.entityUserFiltersData = {
          ...resetData,
          filters: [],
        };
        if (this.$route.query.filter) {
          this.entityFiltersData = {
            ...resetData,
            filters: this.entityFiltersData.filters.filter(
              (filter) => !filter.isUserFilter
            ),
          };
        } else {
          this.entityFiltersData = {
            ...{
              filters: [],
              filter_name: "",
              isSaveFilter: false,
              query_type: "AND",
              quick_filters: [],
              columns_settings: [],
              data_limit: 10,
            },
          };
        }
      } else {
        this.entityFiltersData = {
          ...{
            filters: [],
            filter_name: "",
            isSaveFilter: false,
            query_type: "AND",
            quick_filters: [],
            columns_settings: [],
            data_limit: 10,
          },
        };
      }

      this.relationQuickFilters = [];
    },
    async deleteFilter() {
      let id = this.entityFiltersData._id ? this.entityFiltersData._id : "";
      if (!id) {
        this.$notify({
          title: "Error",
          message: "Filter not selected",
          type: "error",
        });
        return;
      }
      this.loading = true;
      await this.$store.dispatch("filters/deleteFilter", id);
      if (this.getDeleteFilterStatus) {
        await deleteFilter(id);
        this.dialogEdit = false;
        this.resetEntityFiltersData();
        if (this.$route.query.routeType) {
          this.UsercurrentFilter = "";
        }
        this.currentFilter = "";
        this.loading = false;
        this.$notify({
          title: "Success",
          message: "Filters deleted",
          type: "success",
        });
        this.loading = false;
        this.fetchAllEntityFilters();
        this.fetchEntitiesDataForTable();
      } else {
        this.loading = false;
        let msg = "Failed to delete the filters";
        if (this.getDeleteFilterErros?.message) {
          msg = this.getDeleteFilterErros.message;
        }
        this.$notify({
          title: "Error",
          message: msg,
          type: "error",
        });
      }
    },
    async updateFilter() {
      let params = this.prepareFilterParams();
      if (!params.filter_name) {
        this.$notify({
          title: "Error",
          message: "Please enter Filter name",
          type: "error",
        });
        return;
      }
      let id = this.entityFiltersData._id ? this.entityFiltersData._id : "";
      if (!id) {
        await this.saveFilter();
      }
      await this.$store.dispatch("filters/updateFilter", {
        id: id,
        params: params,
      });
      if (this.getUpdateFilterStatus) {
        if (this.getUpdateFilterData?.data) {
          await updateFilterData(id, this.getUpdateFilterData.data);
        }
        // this.loading = false;
        this.$notify({
          title: "Success",
          message: "Filters updated",
          type: "success",
        });
        this.loading = true;
        await this.fetchAllEntityFilters();
        this.loading = false;
      } else {
        this.loading = false;
        let msg = "Failed to Save the filters";
        if (this.getUpdateFilterError && this.getUpdateFilterError.message) {
          msg = this.getUpdateFilterError.message;
        }
        this.$notify({
          title: "Error",
          message: msg,
          type: "error",
        });
      }
    },
    prepareFilterParams() {
      return {
        filter_name: this.entityFiltersData.filter_name.trim(),
        filters: this.entityFiltersData.filters,
        sortBy: this.entityFiltersData.sortBy,
        sortOrder: this.entityFiltersData.sortOrder,
        entity_id: this.entity_id,
        quick_filters: this.entityFiltersData.quick_filters,
        permissions: this.entityFiltersData.permissions,
        tableFieldGroupBy: this.entityFiltersData.tableFieldGroupBy || "",
        tableFieldSubGroupBy: this.entityFiltersData.tableFieldSubGroupBy || "",
        tableFieldGroupByFields: this.entityFiltersData.tableFieldGroupByFields || [],
        filterEntityVariableColumns: this.entityFiltersData.filterEntityVariableColumns,
        displayType: this.entityFiltersData.displayType,
        data_limit: this.entityFiltersData.data_limit,
        email_template_id: this.getSelectedEmailTemplate(),
        ...(this.$route.query.routeType && {
          filter_name: this.entityUserFiltersData.filter_name.trim(),
          parent_filter_id: this.entityUserFiltersData.parentFilterId,
          role_id: this.getAuthenticatedUser.activeRole.role_id,
          // UserId:this.getAuthenticatedUser._id
        }),
      };
    },
    async saveFilter() {
      let params = this.prepareFilterParams();
      if (!params.filter_name) {
        this.$notify({
          title: "Error",
          message: "Please enter Filter name",
          type: "error",
        });
        return;
      }
      await this.$store.dispatch("filters/fetchCreateNewFilter", params);
      if (this.getCreateNewFilter) {
        this.showFieldsParent.selectedFilter = [this.getCreateNewFilter._id];
        if (this.$route.query.routeType) {
          this.UsercurrentFilter = this.getCreateNewFilter._id;
        }
        this.currentFilter = this.getCreateNewFilter._id;
        await addSingleFilterToLocal(this.getCreateNewFilter);
        // this.loading = false;
        this.$notify({
          title: "Success",
          message: "Filters saved",
          type: "success",
        });
        this.loading = true;
        await this.fetchAllEntityFilters();
        this.loading = false;
      } else {
        this.loading = false;
        let msg = "Filter name already exists";
        if (
          this.getCreateNewFilterError &&
          this.getCreateNewFilterError.message
        ) {
          msg = this.getCreateNewFilterError.message;
        }
        this.$notify({
          title: "Error",
          message: msg,
          type: "error",
        });
      }
    },

    async applyFilter() {
      this.loading = true;
      this.dialogEdit = false;
      if (this.entityFiltersData) {
        if (this.entityUserFiltersData.filters.length) {
          const existingFilters = this.entityFiltersData.filters;

          const taggedFilters = this.entityUserFiltersData.filters.map(
            (filter) => ({
              ...filter,
              _isUserFilter: true,
            })
          );

          taggedFilters.forEach((newFilter) => {
            const exists = existingFilters.some(
              (existingFilter) =>
                JSON.stringify(existingFilter) === JSON.stringify(newFilter)
            );

            if (!exists) {
              this.entityFiltersData.filters.push(newFilter);
            }
          });
        }
        this.entityFiltersData.filters = this.entityFiltersData.filters.map(
          (e) => {
            e.query_type = this.entityFiltersData.query_type ?
              this.entityFiltersData.query_type :
              "AND";
            return e;
          }
        );
        if (this.entityUserFiltersData.filters.length) {
          this.entityUserFiltersData.filters =
            this.entityUserFiltersData.filters.map((e) => {
              e.query_type = this.entityUserFiltersData.query_type ?
                this.entityUserFiltersData.query_type :
                "AND";
              return e;
            });
        }
        if (
          this.entityUserFiltersData._id &&
          this.entityUserFiltersData.updateFilter
        ) {
          this.updateFilter();
        } else if (!this.entityUserFiltersData._id &&
          this.entityUserFiltersData.isSaveFilter
        ) {
          this.entityUserFiltersData.parentFilterId = this.$route.query.filter;
          this.saveFilter();
        }
        if (this.entityFiltersData._id && this.entityFiltersData.updateFilter) {
          this.updateFilter();
        } else if (!this.entityFiltersData._id &&
          this.entityFiltersData.isSaveFilter
        ) {
          this.saveFilter();
        }
      }
      if (this.entityFiltersData?.displayType && this.entityFiltersData?._id) {
        this.activeEntityView = this.entityFiltersData.displayType;
        let query = { ...this.$route.query };
        query["viewType"] = this.entityFiltersData.displayType;
        if (this.entityFiltersData?._id) {
          query["filter"] = this.entityFiltersData._id;
        }
        this.$router.push({
          name: this.$route.name,
          query: query,
        });
      }
      this.loading = false;
      this.fetchEntitiesDataForTable(this.applied_quick_filters || []);
    },


    removeEmptyRows(data) {
      if (data && data.length) {
        return data.filter(
          (fl) =>
            fl &&
            fl.field &&
            fl.operator &&
            (fl.data_source ||
              (fl.operator != "exists" && fl.operator != "notexists")) &&
            ((fl.data_source == "GLOBAL_VARIABLE" && fl.global_variable_id) ||
              (fl.operator != "exists" && fl.operator != "notexists")) &&
            ((fl.data_source == "self_field" && fl.value_field) ||
              (fl.operator != "exists" && fl.operator != "notexists")) &&
            (fl.value ||
              (fl.data_source != "self_field" &&
                fl.operator != "exists" &&
                fl.operator != "notexists" &&
                fl.data_source != "GLOBAL_VARIABLE"))
        );
      }
      return [];
    },
    resetDialogModal() {
      if (!this.currentFilter) {
        this.treeCheckedNodes = this.currentEntity.settings;
      } else {
        this.treeCheckedNodes = this.filterColumList;
      }
      this.dialogTableVisible = false;
    },
    async addEntityData() {
      let query = this.getNavigationQuery(this.$route.query);
      let viewType = "STANDARD";
      if (this.currentEntity?.entity_type == "INDIVIDUAL") {
        viewType = "PROFILE";
      }
      if (this.isApplicationUserSide) {
        this.$router.push({
          path: `/ap/entity-execute/${this.entity_id}`,
          query: {
            ...query,
            ...{
              menu_id: this.$route.params.menuId,
              layout: viewType,
              type: "s7aLF3NnAQg=",
            },
          },
        });
      } else {
        let UrlPathAdd = "";
        if (this.getIframeUrlInfo != null) {
          UrlPathAdd = `/if/${this.getIframeUrlInfo._id}/entity/edit/${this.entity_id}`;
        } else {
          UrlPathAdd = `/entity/edit/${this.entity_id}`;
        }
        this.$router.push({
          path: UrlPathAdd,
          query: { ...query, ...{ layout: viewType } },
        });
      }
      // this.$router.push({
      //   path: `/entity-execute/${this.entity_id}`,
      //   query: query,
      // });
    },

    async update() {
      this.refresh = true;
      this.modalLoading = true;
      this.loading = true;
      // let checked = this.$refs.tree.getCheckedNodes();

      // let selectedEntity = await lodash.filter(checked, function (node) {
      //   return !node.children;
      // });
      let fields = this.treeCheckedNodes.filter((e) => !e.fields);
      this.selectedFieldKeys = fields.map((field) => field);
      if (this.selectedFieldKeys && !this.selectedFieldKeys.length) {
        this.$message({
          message: "Atleast one column must be selected",
          type: "warning",
        });
        this.modalLoading = false;
        this.loading = false;
        this.refresh = false;
        return;
      }
      let params = this.prepareParams();
      await this.$store.dispatch("entities/updateEntityById", params);

      if (this.getEntityDataUpdateStatus) {
        this.entityFieldSettings = this.treeCheckedNodes.map((e) => e.id);
        this.modalLoading = false;
        this.dialogTableVisible = false;
        this.filterColumList = [];
        this.filterColumList = [...this.treeCheckedNodes];
        this.selectedTableFields = this.treeCheckedNodes.map((e) => e.id);
        this.currentEntity.settings = this.treeCheckedNodes;
        this.$notify({
          title: "Success",
          message: "Settings saved",
          type: "success",
        });
        this.loading = false;
      } else {
        this.modalLoading = false;
        this.dialogTableVisible = true;
        this.$notify({
          title: "Error",
          message: "Failed to Save the Settings",
          type: "error",
        });
        this.loading = false;
      }
      this.refresh = false;
    },
    async getNecessaryInfo() {
      this.loading = true;
      this.setRouteConfiguration();
      // let id = this.entity_id;
      this.search_string = "";
      this.quickUpdateIndex = -1;
      // await this.fetchAllEntityFilters();
      let [globalVariables] = await Promise.all([
        fetchGlobalVariables({
          get_all: true,
        }),
        this.fetchAllEntityFilters(),
        //this.$store.dispatch("entities/fetchEntityById", {
        //  entity_id: this.entity_id,
        //}),
        //this.$store.dispatch("entityRelationships/fetchEntityRelationships", {
        //  entity_id: this.entity_id,
        //}),
        // this.$store.dispatch("globalVariables/fetchGlobalVariables", {
        //   get_all: true,
        // }),
        //this.fetchEntitiesDataForTable(),
      ]);
      let promises = [];
      let filters = [];
      if (
        this.$route.query.filter &&
        this.getQuickEntityFiltersObject?.[this.$route.query.filter]?.filters &&
        this.getQuickEntityFiltersObject?.[this.$route.query.filter]?.selectedFields
      ) {
        this.selectedFileds =
          this.getQuickEntityFiltersObject?.[
            this.$route.query.filter
          ].selectedFields;
        filters =
          this.getQuickEntityFiltersObject?.[this.$route.query.filter].filters;
      } else {
        this.$store.commit("entities/setQuickEntityFiltersObject", null, {
          root: true,
        });
      }
      if (this.customization_id !== "") {
        promises.push(fetchAllEntityCustomizationsById(this.customization_id));
      } else {
        promises.push(fetchActiveEntityCustomizationsByEntity(this.entity_id));
      }
      if (
        this.isApplicationUserSide &&
        this.getActiveContactType?.account_data_id
      ) {
        await this.$store.dispatch("entities/fetchEntityPrimaryDataById", {
          id: this.getActiveContactType.account_data_id,
        });
      }
      promises = [
        ...promises,
        ...[
          fetchEntityRelationships({ entity_id: this.entity_id }),
          this.fetchEntitiesDataForTable(filters),
          this.fetchEmailTemplates(),
        ],
      ];
      this.currentEntity = await fetchEntityById(this.entity_id);
      this.originalCurrentEntity = JSON.parse(JSON.stringify(this.currentEntity))
      const [customization] = await Promise.all(promises);
      if (customization) {
        this.customization = { ...customization };
      }
      if (globalVariables?.data?.length) {
        this.globalVariablesData = [...globalVariables.data];
      }
      this.relationshipsData = [...(this.getEntityRelationships || [])];
      this.relationshipsData = this.relationshipsData.map((e) => {
        if (e.parent_entity_id == this.currentEntity._id) {
          e.owner_type = "PARENT";
          e.relationship_title = e.child_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.child_entity_id;
        } else {
          e.owner_type = "CHILD";
          e.relationship_title = e.parent_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.parent_entity_id;
        }
        return e;
      });
      if (
        this.currentEntity ||
        (this.$route.params.entity_id && this.$route.params.menuId)
      ) {
        this.fieldsListData = [];
        this.filterColumList = [];
        this.selectedTableFields = [];
        this.entityFieldSettings = [];
        let allFields = [];
        if (this.currentEntity?.export_printing_document) {
          this.pdfExport = true;
        }
        let systemColumns = [{
          label: this.customization?.buttons?.find(
            (bt) => bt.buttonName === "Created at"
          ) ?
            this.customization.buttons.find(
              (bt) => bt.buttonName === "Created at"
            ).value :
            "Created at",
          id: "created_at",
          template_id: "created_at",
          type: "DATE",
          currency_code: null,
          entity_id: "",
        },
        {
          label: this.customization?.buttons?.find(
            (bt) => bt.buttonName === "Updated at"
          ) ?
            this.customization.buttons.find(
              (bt) => bt.buttonName === "Updated at"
            ).value :
            "Updated at",
          id: "updated_at",
          template_id: "updated_at",
          type: "DATE",
          currency_code: null,
          entity_id: "",
        },
        {
          label: this.customization?.buttons?.find(
            (bt) => bt.buttonName === "Created by"
          ) ?
            this.customization.buttons.find(
              (bt) => bt.buttonName === "Created by"
            ).value :
            "Created by",
          id: "created_by",
          template_id: "created_by",
          type: "DATE",
          currency_code: null,
          entity_id: "",
        },
        {
          label: this.customization?.buttons?.find(
            (bt) => bt.buttonName === "Updated by"
          ) ?
            this.customization.buttons.find(
              (bt) => bt.buttonName === "Updated by"
            ).value :
            "Updated by",
          id: "updated_by",
          template_id: "updated_by",
          type: "DATE",
          currency_code: null,
          entity_id: "",
        },
        ];
        if (this.currentEntity.entity_type === "INDIVIDUAL") {
          systemColumns.push({
            label: "Name",
            id: "profile_picture_with_name",
            template_id: "profile_picture_with_name",
            type: "IMAGE",
            currency_code: null,
            entity_id: "",
          });
        } else {
          systemColumns.push({
            label: "Status",
            id: "status",
            template_id: "status",
            type: "STATUS",
            currency_code: null,
            entity_id: "",
          });
        }
        await this.fieldsListData.push({
          label: "Other settings",
          id: "record_deatils",
          template_id: "record_deatils",
          children: systemColumns,
        });
        await this.currentEntity?.templates.map(async (data) => {
          let templateFields = [];
          if (
            data.templateInfo &&
            data.templateInfo.sections &&
            !data.is_repeatable &&
            data.templateInfo.sections.length
          ) {
            await data.templateInfo.sections[0].fields.map(async (field) => {
              // if (field.type != "CONTENT") {
              if (
                (field.type != "CONTENT" ||
                  field.input_type == "ACTION_BUTTON" ||
                  field.input_type == "CURRENCY_TYPE" ||
                  field.input_type === "IMAGE") &&
                field.inputType != "DATA_TABLE" &&
                field.input_type != "ENTITY_TABLE" &&
                field.inputType != "VIDEO"
              ) {
                await templateFields.push({
                  label: field.label,
                  id: field.key,
                  template_id: data.template_id,
                  type: field.inputType,
                  currency_code: field.validations,
                  default_value: field.default_value,
                  entity_id: field.entity_id,
                  key: field.key,
                  input_type: field.input_type,
                });
              }
            });
          }
          if (!data.is_repeatable) {
            templateFields = this.applyCustomizationOnColumns(
              templateFields,
              data?.customization,
              data
            );
            allFields.push(...templateFields);
            this.fieldsListData.push({
              label: data.templateInfo.name,
              id: data.templateInfo.slug + "ABC",
              template_id: data.template_id,
              children: [...templateFields],
            });
          }
        });
        let allFieldsKeys = allFields.flatMap((e) => {
          if (e?.id && e.id.includes("#")) {
            e.id = e.id.split("#")[1];
          }
          return e.id;
        });
        if (
          this.currentEntity &&
          this.currentEntity?.settings &&
          this.currentEntity?.settings.length
        ) {
          this.selectedTableFields = [];
          this.filterColumList = this.getValidColumnSettings(
            this.currentEntity.settings
          );
          //map with latest field type
          this.filterColumList = this.mapTableFieldsType(
            this.currentEntity,
            this.filterColumList
          );
          this.selectedTableFields = this.filterColumList.map((e) => e.id);
        } else {
          //let result = this._.filter(allFields, function (o) {
          //  return o.id === data.key;
          //});
          let result = allFields.filter(
            (o) =>
              o.template_id === this.currentEntity?.templates?.[0].template_id
          );
          if (result && result.length) {
            this.filterColumList = [...this.filterColumList, ...result];
            result.map((data) => {
              this.selectedTableFields.push(data.key);
            });
          }
          this.defaultSettingFields = result;
        }
        this.filterColumList = this.filterColumList.map((e) => {
          if (e?.id && e.id.includes("#")) {
            e.id = e.id.split("#")[1];
          }
          return e;
        });
        this.filterColumList = this.filterColumList.filter(
          (e) => allFieldsKeys.indexOf(e.id) !== -1 || this.isSystemColumn(e)
        );
        if (
          this.getCurrentFilter &&
          this.getCurrentFilter.columns_settings &&
          this.getCurrentFilter.columns_settings.length
        ) {
          this.filterColumList = this.getValidColumnSettings(
            this.getCurrentFilter.columns_settings
          );
        }
        this.filterColumList = this.applyCustomizationOnColumnSettings(
          this.currentEntity,
          this.filterColumList
        );
        this.filterColumList = this.filterColumList.map((e) => {
          let field = this.getField(
            e.id && e.id.includes("#") ? e.id : e.template_id + "#" + e.id
          );
          if (
            field?.inputType == "DATE" && ["month", "year"].includes(field.date_view_type)
          ) {
            e.date_view_type = field.date_view_type;
          }
          return e;
        });
        this.treeCheckedNodes = this.filterColumList;
        this.entityFieldSettings = [...this.selectedTableFields];
        if (
          this.isApplicationUserSide &&
          this.getEntityShowFields &&
          this.getEntityShowFields[this.entity_id]
        ) {
          this.showFieldsParent = {
            ...this.getEntityShowFields[this.entity_id],
          };
        } else if (this.currentEntity?.views_configuration) {
          this.showFieldsParent = { ...this.currentEntity.views_configuration };
          this.showFieldsParent.selected_email_template =
            this.getSelectedEmailTemplate();
          if (!this.showFieldsParent?.columnsPerRow) {
            this.showFieldsParent.columnsPerRow = 2;
          }
        } else {
          this.generateShowFields(JSON.parse(JSON.stringify(this.currentEntity)));
        }
        this.normalizeFieldKeys();
        if (
          typeof this.showFieldsParent.fields == "string" ||
          this.showFieldsParent.fields == "" ||
          !this.showFieldsParent.fields
        ) {
          this.showFieldsParent.fields = [];
        }
        this.currentEntity.templates.forEach((e) => {
          if (e && e.templateInfo) {
            this.current_entity_tempates.push(
              JSON.parse(JSON.stringify(e.templateInfo))
            );
          }
        });

        this.selectedEntityFields = [];
        this.getTotalFields = [];
        if (this.currentEntity && this.currentEntity.templates) {
          this.currentEntity.templates.forEach((e) => {
            if (e && e.templateInfo && !e.is_repeatable) {
              this.getTotalFields = [
                ...this.getTotalFields,
                ...this.getTemplateFieldsByType(e.templateInfo, [
                  "NUMBER",
                  "CURRENCY",
                  "AGGREGATE_FUNCTION",
                ]),
              ];
              this.selectedEntityFields = [
                ...this.selectedEntityFields,
                ...this.applyCustomizationOnFields(
                  this.getTemplateFields(JSON.parse(JSON.stringify(e.templateInfo)), true, true),
                  e.customization,
                  e
                ).map((f) => {
                  if (f?.key && !f.key.includes("#")) {
                    f.key = f.template_id + "#" + f.key;
                  }
                  return f;
                }),
              ];
            }
          });
        }
        this.setRelationshipFields();

        await this.fetchOtherTemplates(this.selectedEntityFields);

        if (this.getAllGlobalVariables && this.getAllGlobalVariables.data) {
          this.globalVariablesData = [...this.getAllGlobalVariables.data];
        }
        this.selectedEntityFields = this.setGlobalVariables(
          this.selectedEntityFields,
          this.globalVariablesData,
          this.getTemplateDataTempVariable
        );
        this.allEntityFields = await this.fetchEntityDetails(
          this.currentEntity._id,
          false,
          false,
          false,
          true
        );
        if (this.currentEntity?.qr_code_import_field) {
          this.qrCodeField = this.allEntityFields.find(
            (f) => f.template_key == this.currentEntity.qr_code_import_field
          );
          this.hasQrcode = true;
        }
        this.hasAttachment = this.allEntityFields.find(
          (e) => e?.input_type == "FILE"
        ) ?
          true :
          false;
        // this.fetchEntitiesDataForTable();
      } else {
        this.loading = false;
        this.forbiddenAccess = true;
        this.showErrorMessage =
          this.getEntityDataByIdError || "Forbidden access";
      }
      this.menuTitleLoading = false;

      this.selectedLabels = this.allCurrentEntityFields.map(
        (field) => field.key
      );
      this.selectAllFields();
      await this.fetchEntityFields();
      this.loading = false;
    },

    //selectedEntityFields

    async fetchAllEmployee() {
      this.loading = true;
      this.data = [];
      // let id = this.$route.params.entity_id;
      let params = {
        limit: this.pageSize,
        page: parseInt(this.getCurrentPage) ?
          parseInt(this.getCurrentPage) :
          this.currentPage,
      };
      await this.$store.dispatch("entities/fetchEntityDataById", {
        id: this.entity_id,
        params,
      });
      this.loading = false;
      if (this.getEntityDataByEntityId) {
        this.currentPage = parseInt(this.getEntityDataByEntityId.page) || 1;
        this.total = parseInt(this.getEntityDataByEntityId.total) || 0;
        this.getEntityDataByEntityId.data &&
          this.getEntityDataByEntityId.data.length ?
          (this.data = this.getEntityDataByEntityId.data) :
          (this.data = []);
      }
    },
    async restoreEntityData(entityDataIds) {
      if (entityDataIds?.length) {
        if (this.currentEntity?.entity_type == "INDIVIDUAL") {
          this.$message.info("Can't restore Individual data");
          return;
        }
        try {
          this.loading = true;
          this.loadingText = "Restoring data...";
          await postAPICall("POST", "/entities-data/archived/restore", {
            entityDataIds: entityDataIds,
            entityId: this.currentEntity?._id,
          });
          this.loading = false;
          this.loadingText = "Loading...";
          this.fetchEntitiesDataForTable();
        } catch (e) {
          console.log("restoreEntityData", e);
          this.$message.error(
            e?.response?.message || "Oops, something went wrong. Try again."
          );
        }
      }
    },
    setTempEntityData(row) {
      row.templates_data = (row?.templates_data || []).map((td) => {
        if (td.template_data_id) {
          td.template_data_id = {
            created_at: row.created_at,
            created_by: row.created_by,
            updated_at: row.updated_at,
            updated_by: row.updated_by,
            template_data: row?.entityData?.[td.template_id] || {},
            template_id: td.template_id,
            status: row.status,
          };
        }
        return td;
      });
      // row.contact_id = this.entityUserInfoData[row.contact_id];
      this.$store.commit("entitiesData/setTempEntityData", row, { root: true });
    },


  },
  beforeDestroy() {
    this.removeKeyListener();
    this.resetStore();
  },

};
</script>
